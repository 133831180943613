import "./App.css";
// importing from library

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
//  images
import AddIcon from "./assets/add.png";
import Search from "./assets/searchIcon.png";
import WatchListIcon from "./assets/watchlist_icon.png";
import AccountIcon from "./assets/account_icon.png";
import TradesIcon from "./assets/trades_icon.png";
import PortfolioIcon from "./assets/portfolio_icon.png";
import TradeArrowActive from "./assets/trade_arrow_Active.png";
import AccountIconActive from "./assets/account_icon_Active.png";
import WatchListIconActive from "./assets/watchlist_icon_Active.png";
import PortfolioIconActive from "./assets/portfolio_icon_Active.png";
import Logo from "./assets/logo.png";
import Rupess from "./assets/rupees.png";
import Printer from "./assets/printer.png";
import UserAcc from "./assets/user_acc.png";
import Headphone from "./assets/headphone.png";
import Bell from "./assets/bell.png";
import Lock from "./assets/lock.png";
import Out from "./assets/out.png";
// importing from files
import {
  GET_MARKET_TIME_RESET,
  CHECK_BEFORE_TRADE_MARKET_RESET,
  CHECK_BEFORE_TRADE_LIMIT_RESET,
  SAVE_EXCHANGE_API_LIMIT_RESET,
  SAVE_EXCHANGE_API_MARKET_RESET,
  DELETE_PENDING_ORDERS_RESET,
} from "./redux/constants";
import {
  getTradeData,
  getLedgerBalance,
  getNotificationData,
  getUserLedgerBalance,
  getUserBillData,
  getProfileData,
  getConsolidatedTrade,
  getUserNetPLData,
  getUserClosedOrders,
  getMarketTimeData,
  getMcxTableData,
  getNseTableData,
  getOptTableData,
  getMcxPlusData,
  getNsePlusData,
  getOtpPlusData,
  getRefreshData,
  saveExchangeOrderLimitModal,
  saveExchangeOrderMarketModal,
  getCheckBeforeTradeLimitData,
  getCheckBeforeTradeMarketData,
} from "./redux/actions";
// components modal
import Login from "./components/Login/Login";
import GlobalModal from "./components/Modal/Watchlist/GlobalModal";
import InsufficientMarginModal from "./components/Modal/Watchlist/InsufficientMarginModal";
import MarketCloseModal from "./components/Modal/MarketCloseModal";
import TradeModalError from "./components/Modal/Watchlist/TradeModalError";
import TradeSaveModal from "./components/Modal/Watchlist/TradeSaveModal";
import CloseTradeModal from "./components/Modal/Trades/CloseTradeModal";
import TradeCloseModal from "./components/Modal/Portfolio/TradeCloseModal";
import FundsModal from "./components/Modal/Account/FundsModal";
import BillModal from "./components/Modal/Account/BillModal";
import ProfileModal from "./components/Modal/Account/ProfileModal";
import NotificationModal from "./components/Modal/Account/NotificationModal";
import ChangePasswordModal from "./components/Modal/Account/ChangePasswordModal";
import CloseOrderModal from "./components/Modal/Trades/CloseOrderModal";
import RaiseCompliantModal from "./components/Modal/Account/RaiseCompliantModal";
import DeleteConfirmModal from "./components/Modal/Trades/DeleteConfirmModal";
import TradeExtraModal from "./components/Modal/Watchlist/TradeExtraModal";
import PasswordModal from "./components/Modal/Account/PasswordModal";
import ConfirmModal from "./components/Modal/Trades/ConfirmModal";
import TradeCloseFromAcitveTabModal from "./components/Modal/Trades/TradeCloseFromAcitveTabModal";

function App() {
  const dispatch = useDispatch();

  // for expiry date
  const targetDate = new Date("2024-12-31"); // 25th August 2023
  const [isExpired, setIsExpired] = useState(false);

  // for flash skin when app is opened
  const [isShown, setIsShown] = useState(true);

  // tabs
  const [globalActiveTab, setGlobalActiveTab] = useState("watchlist");
  const [activeTab, setActiveTab] = useState("mcx");

  // user
  const item = localStorage.getItem("userData");
  const itemParse = JSON.parse(item);
  const [user, setUser] = useState(itemParse);

  // market time variable
  const [marketModalOpen, setMarketModalOpen] = useState(false);
  const [marketClose, setMarketClose] = useState(false);

  // for watchlist page since
  const [closeTradeState, setCloseTradeState] = useState(false);
  const [mcxSocketData, setMcxSocketData] = useState([]);
  const [nseSocketData, setNseSocketData] = useState([]);
  const [optSocketData, setOptSocketData] = useState([]);
  const [updateTable, setUpdateTable] = useState(null);
  const [checkBeforeTradeState, setCheckBeforeTradeState] = useState(false);

  // // for opening to add and remove token modal
  const [globalModalVisible, setGlobalModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  // for modal on watchlist to place order
  const [isLimit, setIsLimit] = useState(false);
  const [lotsCount, setLotsCount] = useState(1);
  const [mrgAvl, setmrgAvl] = useState("");
  const [tradeExtraModalOpen, setTradeExtraModalOpen] = useState(false);
  const [orderLotsCount, setOrderLotsCount] = useState("");
  const [redirectFromPending, setRedirectFromPending] = useState(false);
  const [modalId, setModalId] = useState(null);
  const [insufficientMarginModalOpen, setinsufficientMarginModalOpen] =
    useState(false);
  const [saveData, setSaveData] = useState({});
  const [modalData, setModalData] = useState(null);
  const [checkBeforeTradeMessage, setCheckBeforeTradeMessage] = useState("");
  const [saveModalState, setSaveModalState] = useState(false);

  // for trades page
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [exchangeWord, setExchangeWord] = useState("");
  const [tradeModalOpen, setTradeModalOpen] = useState(false);
  const [activeTradeCloseDataState, setActiveTradeCloseDataState] =
    useState(null);
  const [activeTradeCloseModalOpen, setActiveTradeCloseModalOpen] =
    useState(false);
  const [deleteTradeModalOpen, setDeleteTradeModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [passwordNotCorrectModalOpen, setPasswordNotCorrectModalOpen] =
    useState(false);

  // for portfolio page
  const [pendingData, setPendingData] = useState(null);
  const [closeTradeData, setCloseTradeData] = useState({
    userid: "",
    ordercat: "",
    tokenno: "",
    cmpval: "",
  });
  // account page
  const [fundsModalState, setFundsModalState] = useState(false);
  const [billInvoiceState, setBillInvoiceState] = useState(false);
  const [profileState, setProfileState] = useState(false);
  const [notificationstState, setNotificationstState] = useState(false);
  const [changePasswordState, setChangePasswordState] = useState(false);
  const [raiseComplaintState, setRaiseComplaintState] = useState(false);

  const [tradeCloseState, setTradeCloseState] = useState(false);

  const [closeOrderState, setCloseOrderState] = useState(false);
  const [passwordModalOpen, setPasswordModalOpen] = useState(false);

  // api data from redux store
  const { tradeData } = useSelector((state) => state.trades);
  const {
    consolidatedOrderData,
    ledgerBalance,
    consolidatedTradeData,
    refreshData,
    marketTimeData,
    checkbeforeTradeData,
    saveExchangeOrderData,
    closeTradeFromPortfolioData,
    netplData,
  } = useSelector((state) => state.store);
  const { mcxTableData } = useSelector((state) => state.mcxData);
  const { nseTableData } = useSelector((state) => state.nseData);
  const { optTableData } = useSelector((state) => state.optData);
  const { mcxModalData } = useSelector((state) => state.mcxModal);
  const { nseModalData } = useSelector((state) => state.nseModal);
  const { optModalData } = useSelector((state) => state.optModal);
  const { isTokenUpdated } = useSelector((state) => state.delete);
  const { data, activeData, allActiveData } = useSelector(
    (state) => state.tradeExtra
  );
  const [isReadOnlyCondition, setIsReadOnlyCondition] = useState(false);
  const [mcxnseActiveTradeData, setMcxNseActiveTradeData] = useState(null);
  const [mcxnseActiveTradePostData, setMcxNseActiveTradePostData] =
    useState(null);
  useEffect(() => {
    if (data !== null) {
      setTradeExtraModalOpen(true);
    }
  }, [data]);
  useEffect(() => {
    if (allActiveData !== null) {
      setTradeExtraModalOpen(true);
    }
  }, [allActiveData]);

  

  useEffect(() => {
    if (activeData) {
      setActiveTradeCloseModalOpen(true);
    }
  }, [activeData]);

  useEffect(() => {
    let marginUsed = 0;
    let marginBalance = 0;
    consolidatedTradeData.forEach((item) => {
      marginUsed += parseInt(item.MarginUsed);
    });

    //  let m2m = document.getElementById("m2mEquity");
    // if (m2m) {
    //   marginBalance = m2m.innerHTML - marginUsed;
    //   m2m =ledgerBalance - marginUsed;
    // }

    // const marginAvailable = document.getElementById("marginBalance");
    // if (marginAvailable) {
    //   marginBalance =m2m-marginUsed;
    //   marginAvailable.innerHTML =  Math.round(marginBalance);
    // }

    // const modal_marginBalance = document.getElementById("modal_marginBalance");
    // if (modal_marginBalance) {
    //   modal_marginBalance.innerHTML = marginBalance;

    // }
    let m2m = ledgerBalance - marginUsed;
    const modal_m2mEquity = document.getElementById("modal_m2mEquity");
    if (modal_m2mEquity) {
      modal_m2mEquity.innerHTML = m2m.innerHTML;
    }
  }, [consolidatedTradeData, ledgerBalance]);

  // websocket instance
  useEffect(() => {
    // Create a WebSocket instance
    let mcxData = mcxModalData ? [...mcxModalData] : [];
    let nseData = nseModalData ? [...nseModalData] : [];
    let optData = optModalData ? [...optModalData] : [];
    let activePL = 0;
    let m2m = 0;
    let dataTwo = [];
    const socket = new WebSocket("wss://ws.tradewingss.com/api/webapiwebsoc");
    // Event listener for WebSocket open event
    socket.addEventListener("open", () => {
      // console.log("WebSocket connection established");
    });

    // Event listener for WebSocket message event
    socket.addEventListener("message", (event) => {
      const message = event.data;
      // Check if the message is not empty
      if (message) {
        try {
          const newData = JSON.parse(message);
          const currentDate = new Date();
          const dayOfWeek = currentDate.getDay();
         
          // if (
          //   modalData !== null ||
          //   undefined ||
          //   newData.instrument_token !== ""
          // ) {
          //   for (let item of modalData) {
          //     if (newData.instrument_token === parseInt(item.TokenNo)) {
          //       if (
          //         newData.ask === 0 ||
          //         newData.bid === 0 
          //         // || ((dayOfWeek === 5 || dayOfWeek === 6) && marketTimeData)
          //       ) {
          //         setMarketClose(true);
          //       } else {
          //         if (marketTimeData) {
          //           setMarketClose(false);
          //         }
          //       }
          //     }
          //   }
          // }
          for (let item of tradeData) {
            if (newData.instrument_token === parseInt(item.TokenNo)) {
              item.cmp = newData.ask;
            }
          }

          setMcxNseActiveTradeData(tradeData);
          const modal_volume = document.getElementById(
            "Modal_volume" + newData.instrument_token?.toString()
          );
          if (modal_volume) {
            modal_volume.innerHTML = newData.volume;
          }
          const modal_change = document.getElementById(
            "Modal_change" + newData.instrument_token?.toString()
          );
          if (modal_change) {
            modal_change.innerHTML = newData.change;
          }
          const modal_Last_Price = document.getElementById(
            "Modal_last_price" + newData.instrument_token?.toString()
          );
          if (modal_Last_Price) {
            modal_Last_Price.innerHTML = newData.last_price;
          }
          const modal_Close = document.getElementById(
            "Modal_close" + newData.instrument_token?.toString()
          );
          if (modal_Close) {
            modal_Close.innerHTML = newData.close_;
          }
          const modal_Low = document.getElementById(
            "Modal_low" + newData.instrument_token?.toString()
          );
          if (modal_Low) {
            modal_Low.innerHTML = newData.low_;
          }
          const modal_High = document.getElementById(
            "Modal_high" + newData.instrument_token?.toString()
          );
          if (modal_High) {
            modal_High.innerHTML = newData.high_;
          }
          const modal_Open = document.getElementById(
            "Modal_open" + newData.instrument_token?.toString()
          );
          if (modal_Open) {
            modal_Open.innerHTML = newData.open_;
          }
          const modal_Bid = document.getElementById(
            "Modal_bid" + newData.instrument_token?.toString()
          );
          if (modal_Bid && newData.bid !== 0) {
            modal_Bid.innerHTML = `@ ${newData.bid}`;
          }
          const modal_Ask = document.getElementById(
            "Modal_ask" + newData.instrument_token?.toString()
          );
          if (modal_Ask && newData.ask !== 0) {
            modal_Ask.innerHTML = `@ ${newData.ask}`;
          }

          // mcx  table data
          for (let item of mcxData) {
            if (
              item.instrument_token === newData.instrument_token?.toString()
            ) {
              const mobile_High = document.getElementById(
                "high_mobile" + item.instrument_token
              );
              if (mobile_High) {
                mobile_High.innerHTML = newData.high_;
              }
              const mobile_Low = document.getElementById(
                "low_mobile" + item.instrument_token
              );
              if (mobile_Low) {
                mobile_Low.innerHTML = newData.low_;
              }
              const mobile_LastPrice = document.getElementById(
                "last_price_mobile" + item.instrument_token
              );
              if (mobile_LastPrice) {
                mobile_LastPrice.innerHTML = newData.last_price;
              }
              const mobile_Change = document.getElementById(
                "change_mobile" + item.instrument_token
              );
              if (mobile_Change) {
                mobile_Change.innerHTML = newData.change;
              }
              const mobile_Bid = document.getElementById(
                "bid_mobile" + item.instrument_token
              );
              const mobile_ask = document.getElementById(
                "ask_mobile" + item.instrument_token
              );
              if (
                mobile_Bid &&
                newData.bid !== 0 &&
                mobile_Bid.innerHTML !== newData.bid
              ) {
                if (newData.bid === mobile_Bid.innerHTML) {
                } else if (newData.bid > mobile_Bid.innerHTML) {
                  mobile_Bid.style.color = "green";
                } else if (newData.bid < mobile_Bid.innerHTML) {
                  mobile_Bid.style.color = "red";
                }
                document.getElementById(
                  "bid_mobile" + item.instrument_token
                ).innerHTML = newData.bid;
              }

              if (mobile_ask && newData.ask !== 0) {
                if (newData.ask === mobile_ask.innerHTML) {
                } else if (newData.ask > mobile_ask.innerHTML) {
                  mobile_ask.style.color = "green";
                } else if (newData.ask < mobile_ask.innerHTML) {
                  mobile_ask.style.color = "red";
                }
                document.getElementById(
                  "ask_mobile" + item.instrument_token
                ).innerHTML = newData.ask;
              }

              const global_Mobile_Bid = document.getElementById(
                "global_Modal_bid" + item.instrument_token
              );
              const global_Mobile_ask = document.getElementById(
                "global_Modal_ask" + item.instrument_token
              );
              if (
                global_Mobile_Bid &&
                newData.bid !== 0 &&
                global_Mobile_Bid.innerHTML !== newData.bid
              ) {
                if (newData.bid === global_Mobile_Bid.innerHTML) {
                } else if (newData.bid > global_Mobile_Bid.innerHTML) {
                  global_Mobile_Bid.style.color = "green";
                } else if (newData.bid < global_Mobile_Bid.innerHTML) {
                  global_Mobile_Bid.style.color = "red";
                }
                global_Mobile_Bid.innerHTML = newData.bid;
              }

              if (global_Mobile_ask && newData.ask !== 0) {
                if (newData.ask === global_Mobile_ask.innerHTML) {
                } else if (newData.ask > global_Mobile_ask.innerHTML) {
                  global_Mobile_ask.style.color = "green";
                } else if (newData.ask < global_Mobile_ask.innerHTML) {
                  global_Mobile_ask.style.color = "red";
                }
                global_Mobile_ask.innerHTML = newData.ask;
              }
            }
          }

          // nse  table data
          for (let item of nseData) {
            if (
              item.instrument_token === newData.instrument_token?.toString()
            ) {
              const mobile_High = document.getElementById(
                "high_mobile" + item.instrument_token
              );
              if (mobile_High) {
                mobile_High.innerHTML = newData.high_;
              }
              const mobile_Low = document.getElementById(
                "low_mobile" + item.instrument_token
              );
              if (mobile_Low) {
                mobile_Low.innerHTML = newData.low_;
              }
              const mobile_LastPrice = document.getElementById(
                "last_price_mobile" + item.instrument_token
              );
              if (mobile_LastPrice) {
                mobile_LastPrice.innerHTML = newData.last_price;
              }
              const mobile_Change = document.getElementById(
                "change_mobile" + item.instrument_token
              );
              if (mobile_Change) {
                mobile_Change.innerHTML = newData.change;
              }
              const mobile_Bid = document.getElementById(
                "bid_mobile" + item.instrument_token
              );
              const mobile_ask = document.getElementById(
                "ask_mobile" + item.instrument_token
              );
              if (mobile_Bid && newData.bid !== 0) {
                if (newData.bid === mobile_Bid.innerHTML) {
                } else if (newData.bid > mobile_Bid.innerHTML) {
                  mobile_Bid.style.color = "green";
                } else if (newData.bid < mobile_Bid.innerHTML) {
                  mobile_Bid.style.color = "red";
                }
                document.getElementById(
                  "bid_mobile" + item.instrument_token
                ).innerHTML = newData.bid;
              }
              if (mobile_ask && newData.ask !== 0) {
                if (newData.ask === mobile_ask.innerHTML) {
                } else if (newData.ask > mobile_ask.innerHTML) {
                  mobile_ask.style.color = "green";
                } else if (newData.ask < mobile_ask.innerHTML) {
                  mobile_ask.style.color = "red";
                }
                document.getElementById(
                  "ask_mobile" + item.instrument_token
                ).innerHTML = newData.ask;
              }

              const global_Mobile_Bid = document.getElementById(
                "global_Modal_bid" + item.instrument_token
              );
              const global_Mobile_ask = document.getElementById(
                "global_Modal_ask" + item.instrument_token
              );
              if (
                global_Mobile_Bid &&
                newData.bid !== 0 &&
                global_Mobile_Bid.innerHTML !== newData.bid
              ) {
                if (newData.bid === global_Mobile_Bid.innerHTML) {
                } else if (newData.bid > global_Mobile_Bid.innerHTML) {
                  global_Mobile_Bid.style.color = "green";
                } else if (newData.bid < global_Mobile_Bid.innerHTML) {
                  global_Mobile_Bid.style.color = "red";
                }
                global_Mobile_Bid.innerHTML = newData.bid;
              }

              if (global_Mobile_ask && newData.ask !== 0) {
                if (newData.ask === global_Mobile_ask.innerHTML) {
                } else if (newData.ask > global_Mobile_ask.innerHTML) {
                  global_Mobile_ask.style.color = "green";
                } else if (newData.ask < global_Mobile_ask.innerHTML) {
                  global_Mobile_ask.style.color = "red";
                }
                global_Mobile_ask.innerHTML = newData.ask;
              }
            }
          }

          // opt  table data
          for (let item of optData) {
            if (
              item.instrument_token === newData.instrument_token?.toString()
            ) {
              const mobile_High = document.getElementById(
                "high_mobile" + item.instrument_token
              );
              if (mobile_High) {
                mobile_High.innerHTML = newData.high_;
              }
              const mobile_Low = document.getElementById(
                "low_mobile" + item.instrument_token
              );
              if (mobile_Low) {
                mobile_Low.innerHTML = newData.low_;
              }
              const mobile_LastPrice = document.getElementById(
                "last_price_mobile" + item.instrument_token
              );
              if (mobile_LastPrice) {
                mobile_LastPrice.innerHTML = newData.last_price;
              }
              const mobile_Change = document.getElementById(
                "change_mobile" + item.instrument_token
              );
              if (mobile_Change) {
                mobile_Change.innerHTML = newData.change;
              }
              const mobile_Bid = document.getElementById(
                "bid_mobile" + item.instrument_token
              );
              if (mobile_Bid && newData.bid !== 0) {
                if (newData.bid === mobile_Bid.innerHTML) {
                } else if (newData.bid > mobile_Bid.innerHTML) {
                  mobile_Bid.style.color = "green";
                } else if (newData.bid < mobile_Bid.innerHTML) {
                  mobile_Bid.style.color = "red";
                }
                document.getElementById(
                  "bid_mobile" + item.instrument_token
                ).innerHTML = newData.bid;
              }
              const mobile_ask = document.getElementById(
                "ask_mobile" + item.instrument_token
              );
              if (mobile_ask && newData.ask !== 0) {
                if (newData.ask === mobile_ask.innerHTML) {
                } else if (newData.ask > mobile_ask.innerHTML) {
                  mobile_ask.style.color = "green";
                } else if (newData.ask < mobile_ask.innerHTML) {
                  mobile_ask.style.color = "red";
                }
                document.getElementById(
                  "ask_mobile" + item.instrument_token
                ).innerHTML = newData.ask;
              }

              const global_Mobile_Bid = document.getElementById(
                "global_Modal_bid" + item.instrument_token
              );
              const global_Mobile_ask = document.getElementById(
                "global_Modal_ask" + item.instrument_token
              );
              if (
                global_Mobile_Bid &&
                newData.bid !== 0 &&
                global_Mobile_Bid.innerHTML !== newData.bid
              ) {
                if (newData.bid === global_Mobile_Bid.innerHTML) {
                } else if (newData.bid > global_Mobile_Bid.innerHTML) {
                  global_Mobile_Bid.style.color = "green";
                } else if (newData.bid < global_Mobile_Bid.innerHTML) {
                  global_Mobile_Bid.style.color = "red";
                }
                global_Mobile_Bid.innerHTML = newData.bid;
              }

              if (
                global_Mobile_ask &&
                newData.ask !== 0 &&
                global_Mobile_ask.innerHTML !== newData.ask
              ) {
                if (newData.ask === global_Mobile_ask.innerHTML) {
                } else if (newData.ask > global_Mobile_ask.innerHTML) {
                  global_Mobile_ask.style.color = "green";
                } else if (newData.ask < global_Mobile_ask.innerHTML) {
                  global_Mobile_ask.style.color = "red";
                }
                global_Mobile_ask.innerHTML = newData.ask;
              }
            }
          }

          consolidatedTradeData.forEach((item) => {
            activePL = 0;
            let marginBalance = 0;
            let obj = {};
            if (item.TokenNo === newData.instrument_token?.toString()) {
              if (
                item.OrderCategory === "SELL" &&
                item.TokenNo === newData.instrument_token?.toString()
              ) {
                activePL =
                  (item.OrderPrice - newData.ask) *
                  item.Lot *
                  item.selectedlotsize;
                obj = {
                  id: newData.instrument_token?.toString(),
                  value: activePL,
                };
                let cmp_Account = document.getElementById("cmp" + item.TokenNo);
                if (cmp_Account) {
                  cmp_Account.innerHTML = newData.ask;
                }
                let activePL_Account = document.getElementById(
                  "IndividualActivePl" + item.TokenNo
                );
                if (activePL_Account) {
                  activePL_Account.innerHTML = Math.round(activePL);
                  if (activePL >= 0) {
                    activePL_Account.style.color = "green";
                  } else {
                    activePL_Account.style.color = "red";
                  }
                }
              } else if (
                item.OrderCategory === "BUY" &&
                item.TokenNo === newData.instrument_token?.toString()
              ) {
                activePL =
                  (newData.bid - item.OrderPrice) *
                  item.Lot *
                  item.selectedlotsize;
                obj = {
                  id: newData.instrument_token?.toString(),
                  value: activePL,
                };
                let cmp_Account = document.getElementById("cmp" + item.TokenNo);
                if (cmp_Account) {
                  cmp_Account.innerHTML = newData.bid;
                }
                let activePL_Account = document.getElementById(
                  "IndividualActivePl" + item.TokenNo
                );
                if (activePL_Account) {
                  activePL_Account.innerHTML = Math.round(activePL);
                  if (activePL >= 0) {
                    activePL_Account.style.color = "green";
                  } else {
                    activePL_Account.style.color = "red";
                  }
                }
              }
              let index = dataTwo.findIndex(
                (each) => each.id === newData.instrument_token?.toString()
              );
              if (dataTwo.length === 0) {
                dataTwo.push(obj);
              } else if (index === -1) {
                dataTwo.push(obj);
              } else {
                dataTwo[index] = obj;
              }
              let totalActivePl = 0;
              dataTwo.forEach((each) => {
                totalActivePl += each.value;
              });
              let MarginUsedData = 0;
              consolidatedTradeData.forEach((each) => {
                MarginUsedData += parseInt(each.MarginUsed);
              });
              var active = totalActivePl;
              m2m = Math.round(parseInt(ledgerBalance) + active);
              marginBalance = m2m - MarginUsedData;
              const mobile_totalActivePL = document.getElementById("activePL");
              if (mobile_totalActivePL) {
                mobile_totalActivePL.innerHTML = Math.round(totalActivePl);
              }
              const Modal_M2mEquity = document.getElementById("m2mEquity");
              if (Modal_M2mEquity) {
                Modal_M2mEquity.innerHTML = parseInt(m2m);
              }
              const marginAvailable = document.getElementById("marginBalance");
              if (marginAvailable) {
                // marginBalance = m2m-item.MarginUsed;
                marginAvailable.innerHTML = Math.round(marginBalance);
              }

              const Modal_ActivePL = document.getElementById("modal_ActivePL");
              if (Modal_ActivePL) {
                Modal_ActivePL.innerHTML = Math.round(totalActivePl);
              }

              const modal_marginBalance = document.getElementById(
                "modal_marginBalance"
              );
              if (modal_marginBalance) {
                // marginBalance = m2m-item.MarginUsed;
                modal_marginBalance.innerHTML = Math.round(marginBalance);
              }
              const modal_m2mEquity =
                document.getElementById("modal_m2mEquity");
              if (modal_m2mEquity) {
                Modal_M2mEquity.innerHTML = Math.round(m2m);
                // modal_m2mEquity.innerHTML = m2m.innerHTML;
              }
            }
          });
        } catch (error) {
          console.warn("Error parsing JSON:", error);
        }
      }
    });

    // Event listener for WebSocket close event
    socket.addEventListener("close", (event) => {
      // console.log("WebSocket connection closed", event.code, event.reason);
    });

    // Event listener for WebSocket error event
    socket.addEventListener("error", (error) => {
      // console.warn("WebSocket error:", error);
    });

    // Clean up the WebSocket connection on component unmount
    return () => {
      socket.close();
    };
  }, [
    mcxModalData,
    nseModalData,
    optModalData,
    consolidatedTradeData,
    ledgerBalance,
    tradeData,
    mcxnseActiveTradeData,
    modalData,
  ]);
  // for limting the api after certain dates
  useEffect(() => {
    const currentDate = new Date();
    if (currentDate > targetDate) {
      setIsExpired(true);
    }
  }, [isExpired]);

  // main api caller
  // calling different api through redux when chaning tabs like watchlist, portfolio, trades
  useEffect(() => {
    if (globalActiveTab === "watchlist") {
      dispatch(getMarketTimeData(activeTab.toUpperCase(), user?.refId));
    } else if (globalActiveTab === "trades") {
      dispatch(getTradeData(activeTab, user?.userId));
    } else if (globalActiveTab === "portfolio") {
      dispatch(getUserNetPLData(user?.userId));
      dispatch(getUserClosedOrders(user?.userId));
      dispatch(getConsolidatedTrade(user?.userId));
    }
  }, [globalActiveTab, activeTab, user]);

  // first time running i.e when reloading or login
  useEffect(() => {
    if (user) {
      dispatch(getMcxTableData(user.userId, "mcx"));
      dispatch(getOptTableData(user.userId, "opt"));
      dispatch(getNseTableData(user.userId, "nse"));
      dispatch(getLedgerBalance(user?.userId));
      dispatch(getConsolidatedTrade(user?.userId));
      dispatch(getRefreshData(user?.userId));
      dispatch(getMcxPlusData("MCX", null, user?.userId));
      dispatch(getNsePlusData("NSE", null, user?.userId));
      dispatch(getOtpPlusData("OPT", null, user?.userId));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (globalActiveTab === "trades") {
      if (isTokenUpdated === "success" || saveExchangeOrderData === "Success") {
        dispatch(getTradeData(activeTab, user?.userId));
        dispatch({ type: DELETE_PENDING_ORDERS_RESET, data: "" });
      }
    }
  }, [
    dispatch,
    globalActiveTab,
    isTokenUpdated,
    activeTab,
    user,
    saveExchangeOrderData,
  ]);

  // fetching ledger balance each time when tab is switched
  useEffect(() => {
    dispatch(getLedgerBalance(user?.userId));
  }, [activeTab]);

  function settingGlobalTab(paramsTab) {
    setGlobalActiveTab(paramsTab);
  }

  function settinguser(data) {
    setUser(data);
  }

  function closeModal() {
    setGlobalModalVisible(false);
  }

  function setUpdateTableFun(params) {
    setUpdateTable(params);
  }

  function tradeModalFun(params) {
    setTradeModalOpen(params);
  }

  function marketModalFun(params) {
    setMarketModalOpen(params);
    setMarketModalOpen(false);
  }

  function setSaveModalDataFun(params) {
    setSaveData(params);
  }

  function setCheckBeforeTradeFun(params) {
    setCheckBeforeTradeState(params);
  }

  function saveModalStateFun(params) {
    setSaveModalState(params);
  }

  function closeTradeStateFun(params) {
    setCloseTradeState(params);
  }

  function tradeCloseFun(params) {
    setTradeCloseState(params);
  }

  function setRedirectFromPendingFun(params) {
    setRedirectFromPending(params);
  }

  function setModalDataFun(params) {
    setModalId(params);
  }

  function setPendingDataFun(params) {
    setPendingData(params);
  }

  function closeOrderStateFun(params) {
    setCloseOrderState(params);
  }

  function deleteTradeModalOpenFun(params) {
    setDeleteTradeModalOpen(params);
  }

  function setDeleteIDFun(params) {
    setDeleteId(params);
  }

  function tradeExtraModalOpenFun(params) {
    setTradeExtraModalOpen(params);
  }

  function passwordModalOpenFun(params) {
    setPasswordModalOpen(false);
  }

  useEffect(() => {
    if (user) {
      if (consolidatedTradeData && consolidatedTradeData.length === 0) {
        document.getElementById("activePL").innerHTML = 0;
      }
    }
  }, [consolidatedTradeData, user]);
  // mcx table setting in local variable when first data is fetched
  useEffect(() => {
    let table = mcxTableData ? [...mcxTableData] : [];
    let globalModal = mcxModalData ? [...mcxModalData] : [];
    table?.forEach((item) => {
      globalModal?.map((each) => {
        if (each?.instrument_token === item?.SymbolToken) {
          item.change = parseFloat(each.change)?.toFixed(2);
          item.close_ = each.close_;
          item.high_ = each.high_;
          item.last_price = each.last_price;
          item.low_ = each.low_;
          item.oi = each.oi;
          item.oi_day_high = each.oi_day_high;
          item.oi_day_low = each.oi_day_low;
          item.open_ = each.open_;
          item.volume = each.volume;
          item.bid = item.sell;
          item.ask = item.buy;
        }
      });
    });
    setMcxSocketData(table);
  }, [mcxTableData, mcxModalData]);

  // nse table setting in local variable when first data is fetched
  useEffect(() => {
    let table = nseTableData ? [...nseTableData] : [];
    let globalModal = nseModalData ? [...nseModalData] : [];
    table?.forEach((item) => {
      globalModal?.map((each) => {
        if (each?.instrument_token === item?.SymbolToken) {
          item.change = parseFloat(each.change)?.toFixed(2);
          item.close_ = each.close_;
          item.high_ = each.high_;
          item.last_price = each.last_price;
          item.low_ = each.low_;
          item.oi = each.oi;
          item.oi_day_high = each.oi_day_high;
          item.oi_day_low = each.oi_day_low;
          item.open_ = each.open_;
          item.volume = each.volume;
          item.bid = item.sell;
          item.ask = item.buy;
        }
      });
    });
    setNseSocketData(table);
  }, [nseTableData, nseModalData]);

  // opt table setting in local variable when first data is fetched
  useEffect(() => {
    let table = optTableData ? [...optTableData] : [];
    let globalModal = optModalData ? [...optModalData] : [];
    table?.forEach((item) => {
      globalModal?.map((each) => {
        if (each?.instrument_token === item?.SymbolToken) {
          item.change = parseFloat(each.change)?.toFixed(2);
          item.close_ = each.close_;
          item.high_ = each.high_;
          item.last_price = each.last_price;
          item.low_ = each.low_;
          item.oi = each.oi;
          item.oi_day_high = each.oi_day_high;
          item.oi_day_low = each.oi_day_low;
          item.open_ = each.open_;
          item.volume = each.volume;
          item.bid = item.sell;
          item.ask = item.buy;
        }
      });
    });
    setOptSocketData(table);
  }, [optTableData, optModalData]);

  // for updating token when adding or removing from all tokens to table in watchlist
  useEffect(() => {
    if (updateTable !== null) {
      let table;
      let updateModalData;
      if (activeTab === "mcx") {
        table = [...mcxSocketData];
        updateModalData = [...mcxModalData];
      } else if (activeTab === "nse") {
        table = [...nseSocketData];
        updateModalData = [...nseModalData];
      } else if (activeTab === "opt") {
        table = [...optSocketData];
        updateModalData = [...optModalData];
      }

      if (updateTable?.method === "Add") {
        updateModalData.forEach((item) => {
          if (item.instrument_token === updateTable.id) {
            const obj = {
              ExchangeType: item.Exchange,
              Lotsize: item.lotsize,
              SymbolName: item.symbolname,
              SymbolToken: item.instrument_token,
              buy: item.buy,
              sell: item.sell,
              change: 0,
              close_: 0,
              high_: 0,
              last_price: 0,
              low_: 0,
              oi: 0,
              oi_day_high: 0,
              oi_day_low: 0,
              open_: 0,
              volume: 0,
              bid: 0,
              ask: 0,
            };
            table.push(obj);
          }
        });
      } else if (updateTable?.method === "Remove") {
        table = table.filter((item) => item.SymbolToken !== updateTable.id); // Check for the correct property name
      }
      if (activeTab === "mcx") {
        setMcxSocketData(table);
      } else if (activeTab === "nse") {
        setNseSocketData(table);
      } else if (activeTab === "opt") {
        setOptSocketData(table);
      }
      setUpdateTable(null);
    }
  }, [
    updateTable,
    activeTab,
    mcxModalData,
    nseModalData,
    optModalData,
    mcxSocketData,
    nseSocketData,
    optSocketData,
  ]);

  // function for checking date and time and
  useEffect(() => {
    const currentDate = new Date();
    const dayOfWeek = currentDate.getDay();
    if ((dayOfWeek === 0 || dayOfWeek === 6) && marketTimeData) {
      if (globalActiveTab === "watchlist" && marketTimeData) {
        setMarketClose(true);
      } else if (
        (globalActiveTab === "portfolio" || globalActiveTab === "trades") &&
        marketTimeData
      ) {
        setPendingData(null);
        setMarketModalOpen(true);
      }
    } else {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      const currentMinute = currentTime.getMinutes();

      // Assuming you have the time range as a string
      const timeRange = marketTimeData ? marketTimeData : "00:00|00:00";

      // Split the time range string into start time and end time
      const [startTime, endTime] = timeRange.split("|");

      // Extract the hour and minute components from the start time and end time strings
      const [startHour, startMinute] = startTime.split(":");
      const [endHour, endMinute] = endTime.split(":");

      // Convert the components to numbers
      const startHourNum = parseInt(startHour, 10);
      const startMinuteNum = parseInt(startMinute, 10);
      const endHourNum = parseInt(endHour, 10);
      const endMinuteNum = parseInt(endMinute, 10);

      // Compare the current time with the start and end times
      if (
        (currentHour > startHourNum ||
          (currentHour === startHourNum && currentMinute >= startMinuteNum)) &&
        (currentHour < endHourNum ||
          (currentHour === endHourNum && currentMinute <= endMinuteNum))
      ) {
        // if time is matched, different tabs different conditions render
        if (globalActiveTab === "watchlist" && marketTimeData) {
          setMarketClose(false);
        } else if (globalActiveTab === "portfolio" && marketTimeData) {
          setCloseTradeState(true);
        } else if (globalActiveTab === "trades" && marketTimeData) {
          if (activeTab === "Pending") {
            if (exchangeWord === "MCX" || exchangeWord === "NSE") {
              setPasswordModalOpen(true);
            } else {
              setTradeModalOpen(true);
            }
          } else if (activeTab === "Active") {
            if (exchangeWord === "MCX" || exchangeWord === "NSE") {
              setPasswordModalOpen(true);
            } else {
              setCloseOrderState(true);
            }
          }
        }
      } else {
        // if time unmatched, then triggering `MarketCloseModal.js` in components/Modal
        if (globalActiveTab === "watchlist" && marketTimeData) {
          setMarketClose(true);
        } else if (
          (globalActiveTab === "portfolio" || globalActiveTab === "trades") &&
          marketTimeData
        ) {
          setPendingData(null);
          setRedirectFromPending(false);
          setCloseTradeState(false);
          setMarketModalOpen(true);
        }

        // if (globalActiveTab === "watchlist" && marketTimeData) {
        //   setMarketClose(false);
        // } else if (globalActiveTab === "portfolio" && marketTimeData) {
        //   setCloseTradeState(true);
        // } else if (globalActiveTab === "trades" && marketTimeData) {
        //   if (activeTab === "Pending") {
        //     if (exchangeWord === "MCX" || exchangeWord === "NSE") {
        //       setPasswordModalOpen(true);
        //     } else {
        //       setTradeModalOpen(true);
        //     }
        //   } else if (activeTab === "Active") {
        //     if (exchangeWord === "MCX" || exchangeWord === "NSE") {
        //       setPasswordModalOpen(true);
        //     } else {
        //       setCloseOrderState(true);
        //     }
        //   }
        // }
      }
    }
    // emptying marketTimeData variable from redux store so it does not trigger everytime
    dispatch({ type: GET_MARKET_TIME_RESET, data: "" });
  }, [
    marketTimeData,
    globalActiveTab,
    redirectFromPending,
    activeTab,
    exchangeWord,
  ]);

  // function running after check before trade api fetch the response
  useEffect(() => {
    if (checkbeforeTradeData && checkbeforeTradeData === "true") {
      // {
      //    responseCode : 200,
      //    response : message
      //  }
      setCheckBeforeTradeMessage("");
      if (saveData.OrderType === "Market") {
        dispatch(saveExchangeOrderMarketModal(saveData));
        dispatch({ type: CHECK_BEFORE_TRADE_MARKET_RESET });
      } else {
        dispatch(saveExchangeOrderLimitModal(saveData));
        dispatch({ type: CHECK_BEFORE_TRADE_LIMIT_RESET });
      }
    } else if (checkbeforeTradeData && checkbeforeTradeData !== "true") {
      setCheckBeforeTradeMessage(checkbeforeTradeData);
      setTradeModalOpen(false);
      setCheckBeforeTradeState(true);
    }
  }, [dispatch, checkbeforeTradeData, saveData]);

  useEffect(() => {
    if (saveExchangeOrderData === "Success") {
      setTradeModalOpen(false);
      setSaveModalState(true);
      dispatch({ type: SAVE_EXCHANGE_API_MARKET_RESET });
      dispatch({ type: SAVE_EXCHANGE_API_LIMIT_RESET });
      setRedirectFromPendingFun(false);
    } else {
      // order not saves
    }
  }, [dispatch, saveExchangeOrderData]);

  useEffect(() => {
    if (closeTradeFromPortfolioData?.ResponseCode === "200") {
      setTradeCloseState(true);
      dispatch({ type: GET_MARKET_TIME_RESET, data: "" });
      dispatch(getConsolidatedTrade(user?.userId));
      dispatch(getLedgerBalance(user?.userId));
    }
  }, [dispatch, closeTradeFromPortfolioData, user]);

  // app opening screen for 2 second
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsShown(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  // formatting date
  function formatData(date) {
    const dateObject = new Date(date);

    const day = dateObject.getDate();
    const month = dateObject.toLocaleString("default", { month: "short" });

    const formattedDate = `${day} ${month}`;

    return formattedDate;
  }

  // for splitting name at `_`
  function splitforExchangeName(inputString) {
    const parts = inputString.split("_");
    return parts[0];
  }

  // for splitting date at `_`
  function splitforDate(inputString) {
    const parts = inputString.split("_");
    return parts[1];
  }

  function checkUserPassword(input) {
    if (refreshData?.Password_ === input) {
      setPasswordModalOpen(false);
      setConfirmModalOpen(true);
    } else {
      setPasswordNotCorrectModalOpen(true);
    }
  }

  function passwordNotCorrectModalFun(params) {
    setPasswordNotCorrectModalOpen(params);
  }

  function confirmModalFun(params) {
    setConfirmModalOpen(params);
  }

  function setDefaultExchangeWord() {
    setExchangeWord("");
  }

  function closeActiveTradeModal(params) {
    setActiveTradeCloseModalOpen(params);
  }

  function activeTradeCloseData(name, lot, pl) {
    const obj = {
      name: name,
      lot: lot,
      pl: pl,
    };
    setActiveTradeCloseDataState(obj);
  }

  function makeActiveTradeCloseDataState(params) {
    setActiveTradeCloseDataState(params);
  }
  useEffect(() => {
    let data;
    if (activeTab === "mcx" || pendingData?.symbolType === "MCX") {
      data = [...mcxSocketData];
    } else if (activeTab === "nse" || pendingData?.symbolType === "NSE") {
      data = [...nseSocketData];
    } else if (activeTab === "opt" || pendingData?.symbolType === "OPT") {
      data = [...optSocketData];
    }
    setModalData(data);
    if (pendingData) {
      setOrderLotsCount(pendingData?.price);
      setLotsCount(pendingData?.lots);
    } else {
      setOrderLotsCount("");
      setLotsCount(1);
    }
  }, [activeTab, mcxSocketData, nseSocketData, optSocketData, pendingData]);

  // to calculate margin avaiable
  function calculateLots(e, method, tokenName, totalLots, tokenId, exchange) {
    // const enteredValue = (e.target.value === undefined || e.target.value === "0" || e.target.value === "" ) ? (lotsCount === "" ? "1" : lotsCount): e.target.value;
    // // Remove any non-numeric characters from the input
    // const numericValue = enteredValue.replace(/[^0-9]/g, "");
    // const sanitizedValue = numericValue === "0" || "" || undefined ? "1" : numericValue;
    // setLotsCount(sanitizedValue);
    // let lotsEntered = sanitizedValue;
    let lotsEntered =
      e.target.value === undefined ||
      e.target.value === "0" ||
      e.target.value === ""
        ? lotsCount === ""
          ? "1"
          : lotsCount
        : e.target.value;
    if (e.target.value > 0 || lotsCount > 0 || lotsEntered > 0) {
      if (method === "minus") {
        if (lotsCount > 1) {
          setLotsCount((lotCount) => lotCount - 1);
          lotsEntered -= 1;
        }
      } else if (method === "plus") {
        setLotsCount((lotCount) => Number(lotCount) + 1);
        lotsEntered += 1;
      } else if (method === "input") {
        setLotsCount(e.target.value);
      }
      const scriptname = tokenName;
      const selectedlotsize = totalLots;
      const orderprice = document.getElementById(
        `bid_mobile${tokenId}`
      ).innerHTML;
      const enterdlot = lotsEntered;
      let finallotsize = parseInt(enterdlot) * parseInt(selectedlotsize);
      let marginvalue = 0;
      let holdmarginvalue = 0;
      const extype = exchange;
      let Intraday_Exposure_Margin_MCX;
      let Holding_Exposure_Margin_MCX;
      let Intraday_Exposure_Margin_Equity;
      let Holding_Exposure_Margin_Equity;
      let Intraday_Exposure_Margin_CDS;
      let Holding_Exposure_Margin_CDS;
      let CDS_Exposure_Type;
      if (extype === "MCX") {
        Intraday_Exposure_Margin_MCX =
          refreshData?.Intraday_Exposure_Margin_MCX;
        Holding_Exposure_Margin_MCX = refreshData?.Holding_Exposure_Margin_MCX;
        if (refreshData?.Mcx_Exposure_Type === "per_lot") {
          let symbolname = scriptname; //GOLD22AUGFUT
          let symarr = symbolname.split("_");
          let similersym = setsymbol(symarr[0] + "".toString().trim());
          let Intraday_Exposure = `${refreshData}?.MCX_Exposure_Lot_wise_${similersym}_Intraday`;
          let Intraday_hold_Exposure = `${refreshData}?.MCX_Exposure_Lot_wise_${similersym}_Holding`;
          marginvalue = parseInt(enterdlot) * parseInt(Intraday_Exposure);
          holdmarginvalue =
            parseInt(enterdlot) * parseInt(Intraday_hold_Exposure);
        } else {
          finallotsize = parseInt(enterdlot) * parseInt(selectedlotsize);
          marginvalue =
            (parseInt(orderprice) * finallotsize) /
            parseInt(Intraday_Exposure_Margin_MCX);
          holdmarginvalue =
            (parseInt(orderprice) * finallotsize) /
            parseInt(Holding_Exposure_Margin_MCX);
        }
      } else if (extype === "NSE") {
        Intraday_Exposure_Margin_Equity =
          refreshData?.Intraday_Exposure_Margin_EQUITY;
        Holding_Exposure_Margin_Equity =
          refreshData?.Holding_Exposure_Margin_Equity;
        var NSE_Exposure_Type = refreshData?.NSE_Exposure_Type;
        if (NSE_Exposure_Type === "per_lot") {
          marginvalue =
            parseInt(enterdlot) * parseInt(Intraday_Exposure_Margin_Equity);
          holdmarginvalue =
            parseInt(enterdlot) * parseInt(Holding_Exposure_Margin_Equity);
        } else {
          finallotsize = parseInt(enterdlot) * parseInt(selectedlotsize);
          marginvalue =
            (parseInt(orderprice) * finallotsize) /
            parseInt(Intraday_Exposure_Margin_Equity);
          holdmarginvalue =
            (parseInt(orderprice) * finallotsize) /
            parseInt(Holding_Exposure_Margin_Equity);
        }
      } else {
        Intraday_Exposure_Margin_CDS =
          refreshData?.Intraday_Exposure_Margin_CDS;
        Holding_Exposure_Margin_CDS = refreshData?.Holding_Exposure_Margin_CDS;
        CDS_Exposure_Type = refreshData?.CDS_Exposure_Type;
        if (CDS_Exposure_Type === "per_lot") {
          marginvalue =
            parseInt(enterdlot) * parseInt(Intraday_Exposure_Margin_CDS);
          holdmarginvalue =
            parseInt(enterdlot) * parseInt(Holding_Exposure_Margin_CDS);
        } else {
          finallotsize = parseInt(enterdlot) * parseInt(selectedlotsize);
          marginvalue =
            (parseInt(orderprice) * finallotsize) /
            parseInt(Intraday_Exposure_Margin_CDS);
          holdmarginvalue =
            (parseInt(orderprice) * finallotsize) /
            parseInt(Holding_Exposure_Margin_CDS);
        }
      }

      let mrgval = document.getElementById(`mrgval${tokenId}`);
      if (mrgval && !isLimit) {
        mrgval.innerHTML = `Margin Value : ${Math.round(marginvalue, 1)}`;
      }
      setmrgAvl(Math.round(marginvalue));
      setIsReadOnlyCondition(false);
    }
  }

  useEffect(() => {
    if (redirectFromPending) {
      setIsLimit(true);
    }
  }, [redirectFromPending]);

  // for checking values from refreshData, there short name
  function setsymbol(val) {
    if (val === "ALUMINI") {
      return "ALUMINIUM";
    } else if (val === "ALUMINIUM") {
      return "ALUMINIUM";
    }
    if (val === "COPPER") {
      return "COPPER";
    } else if (val === "CRUDEOIL") {
      return "CRUDEOIL";
    } else if (val === "CRUDEOILM") {
      return "CRUDEOIL";
    } else if (val === "GOLD") {
      return "GOLD";
    } else if (val === "GOLDM") {
      return "GOLDM";
    } else if (val === "GOLDMINI") {
      return "GOLD";
    } else if (val === "LEAD") {
      return "LEAD";
    } else if (val === "LEADMINI") {
      return "LEAD";
    } else if (val === "MENTHAOIL") {
      return "MENTHAOIL";
    } else if (val === "NATURALGAS") {
      return "NATURALGAS";
    } else if (val === "NATGASMINI") {
      return "NATURALGAS";
    } else if (val === "NICKEL") {
      return "NICKEL";
    } else if (val === "SILVER") {
      return "SILVER";
    } else if (val === "SILVERM") {
      return "SILVERM";
    } else if (val === "SILVERMINI") {
      return "SILVER";
    } else if (val === "SILVERMIC") {
      return "SILVERMIC";
    } else if (val === "ZINC") {
      return "ZINC";
    } else if (val === "ZINCMINI") {
      return "ZINC";
    }

    return val;
  }

  // function for placing orders
  function handleCalculations(exchange, type, orderLotsCount) {
    setIsLimit(false);
    let marginBalance = 0;
    let MarginUsedData = 0;
    consolidatedTradeData.forEach((each) => {
      MarginUsedData += parseInt(each.MarginUsed);
    });

    let m2m = document.getElementById("m2mEquity");
    if (m2m) {
      marginBalance = m2m.innerHTML - MarginUsedData;
    }
    const margin_dataAvailable = document.getElementById("marginBalance");
    if (margin_dataAvailable) {
      margin_dataAvailable.innerHTML = marginBalance;
    }
    if (type === "SELL") {
      if (marginBalance >= mrgAvl) {
        const bidval = document.getElementById(
          `bid_mobile${exchange.SymbolToken}`
        ).innerHTML;
        const askval = document.getElementById(
          `ask_mobile${exchange.SymbolToken}`
        ).innerHTML;
        const lowval = document.getElementById(
          `low_mobile${exchange.SymbolToken}`
        ).innerHTML;
        const highval = document.getElementById(
          `high_mobile${exchange.SymbolToken}`
        ).innerHTML;
        const orderprice = orderLotsCount;
        let finallotsize = 0;
        let marginvalue = 0;
        let holdmarginvalue = 0;
        if (isLimit) {
          if (
            refreshData?.AllowOrdersCurrentBid &&
            refreshData?.AllowFreshEntryHighAndBelow &&
            refreshData?.AllowOrdersHighLow
          ) {
          }
          //all false
          if (
            (!refreshData?.AllowOrdersCurrentBid &&
              !refreshData?.AllowFreshEntryHighAndBelow &&
              !refreshData?.AllowOrdersHighLow) ||
            (refreshData?.AllowOrdersCurrentBid &&
              !refreshData?.AllowFreshEntryHighAndBelow &&
              !refreshData?.AllowOrdersHighLow)
          ) {
            alert("Error", "You are not authorized for place orders");
            return;
          }

          //1=true, 2=true, 3=false  D. 1=true, 2=true, 3=false  = Order above high & below low only

          if (
            refreshData?.AllowOrdersCurrentBid &&
            refreshData?.AllowFreshEntryHighAndBelow &&
            !refreshData?.AllowOrdersHighLow
          ) {
            if (
              parseFloat(orderprice) > parseFloat(lowval) &&
              parseFloat(orderprice) < parseFloat(highval)
            ) {
              alert(
                "Error",
                "Order Placed greator than " +
                  highval +
                  " and less " +
                  lowval +
                  "."
              );
              return;
            }
          }

          //E. 1=true, 2=false, 3=true = Allow Orders between and equal High / will place on all price (high/low and bid/ask)

          if (
            refreshData?.AllowOrdersCurrentBid &&
            !refreshData?.AllowFreshEntryHighAndBelow &&
            refreshData?.AllowOrdersHighLow
          ) {
            if (
              parseFloat(orderprice) <= parseFloat(lowval) ||
              parseFloat(orderprice) >= parseFloat(highval)
            ) {
              alert(
                "Error",
                "Order Placed between " + lowval + " and " + highval + "."
              );
              return;
            }
          }

          // F. 1 = false, 2 = false, 3 = true = Allow Orders between and equal High(high / low but not should in between bid / ask)

          if (
            !refreshData?.AllowOrdersCurrentBid &&
            !refreshData?.AllowFreshEntryHighAndBelow &&
            refreshData?.AllowOrdersHighLow
          ) {
            if (
              parseFloat(orderprice) <= parseFloat(lowval) ||
              parseFloat(orderprice) >= parseFloat(highval) ||
              (parseFloat(orderprice) >= parseFloat(bidval) &&
                parseFloat(orderprice) <= parseFloat(askval))
            ) {
              alert(
                "Error",
                "Order Placed between " +
                  askval +
                  " and " +
                  highval +
                  " OR " +
                  lowval +
                  " and " +
                  bidval +
                  "."
              );
              return;
            }
          }

          //G. 1=false,2=true. 3=true = TRADE WORKING AT ALL CONDITION but will not take b/w (bid/ask)

          if (
            !refreshData?.AllowOrdersCurrentBid &&
            refreshData?.AllowFreshEntryHighAndBelow &&
            refreshData?.AllowOrdersHighLow
          ) {
            if (
              parseFloat(orderprice) >= parseFloat(bidval) &&
              parseFloat(orderprice) <= parseFloat(askval)
            ) {
              alert(
                "Error",
                "Order Placed between " + bidval + " and " + askval + "."
              );
              return;
            }
          }
        }
        const enterdlot = lotsCount;
        const selectedlotsize = exchange.Lotsize;
        if (exchange.ExchangeType === "MCX") {
          const Intraday_Exposure_Margin_MCX =
            refreshData?.Intraday_Exposure_Margin_MCX;
          const Holding_Exposure_Margin_MCX =
            refreshData?.Holding_Exposure_Margin_MCX;
          const MCX_Exposure_Type = refreshData?.Mcx_Exposure_Type;
          if (MCX_Exposure_Type === "per_lot") {
            const symbolname = exchange.SymbolName; //GOLD22AUGFUT
            const symarr = symbolname.split("_");
            const similersym = setsymbol(symarr[0] + "".toString().trim());

            const Intraday_Exposure = `${refreshData}?.MCX_Exposure_Lot_wise_${similersym}_Intraday`;
            const Intraday_hold_Exposure = `${refreshData}?.MCX_Exposure_Lot_wise_${similersym}_Holding`;
            marginvalue = parseInt(enterdlot) * parseInt(Intraday_Exposure);
            holdmarginvalue =
              parseInt(enterdlot) * parseInt(Intraday_hold_Exposure);
          } else {
            finallotsize = parseInt(enterdlot) * parseInt(selectedlotsize);
            marginvalue =
              (parseInt(orderprice) * finallotsize) /
              parseInt(Intraday_Exposure_Margin_MCX);
            holdmarginvalue =
              (parseInt(orderprice) * finallotsize) /
              parseInt(Holding_Exposure_Margin_MCX);
          }
        } else if (exchange.ExchangeType === "NSE") {
          const Intraday_Exposure_Margin_Equity =
            refreshData?.Intraday_Exposure_Margin_EQUITY;
          const Holding_Exposure_Margin_Equity =
            refreshData?.Holding_Exposure_Margin_EQUITY;
          const NSE_Exposure_Type = refreshData?.NSE_Exposure_Type;
          if (NSE_Exposure_Type === "per_lot") {
            marginvalue =
              parseInt(enterdlot) * parseInt(Intraday_Exposure_Margin_Equity);
            holdmarginvalue =
              parseInt(enterdlot) * parseInt(Holding_Exposure_Margin_Equity);
          } else {
            finallotsize = parseInt(enterdlot) * parseInt(selectedlotsize);
            marginvalue =
              (parseInt(orderprice) * finallotsize) /
              parseInt(Intraday_Exposure_Margin_Equity);
            holdmarginvalue =
              (parseInt(orderprice) * finallotsize) /
              parseInt(Holding_Exposure_Margin_Equity);
          }
        } else {
          const Intraday_Exposure_Margin_CDS =
            refreshData?.Intraday_Exposure_Margin_CDS;
          const Holding_Exposure_Margin_CDS =
            refreshData?.Holding_Exposure_Margin_CDS;
          const CDS_Exposure_Type = refreshData?.CDS_Exposure_Type;
          if (CDS_Exposure_Type === "per_lot") {
            marginvalue =
              parseInt(enterdlot) * parseInt(Intraday_Exposure_Margin_CDS);
            holdmarginvalue =
              parseInt(enterdlot) * parseInt(Holding_Exposure_Margin_CDS);
          } else {
            finallotsize = parseInt(enterdlot) * parseInt(selectedlotsize);
            marginvalue =
              (parseInt(orderprice) * finallotsize) /
              parseInt(Intraday_Exposure_Margin_CDS);
            holdmarginvalue =
              (parseInt(orderprice) * finallotsize) /
              parseInt(Holding_Exposure_Margin_CDS);
          }
        }

        let obj = {};
        if (!isLimit) {
          obj = {
            Id: pendingData !== null ? pendingData?.id : "",
            OrderDate: "",
            OrderTime: "",
            actualLot: exchange.Lotsize,
            selectedlotsize: exchange.Lotsize,
            OrderNo: "",
            UserId: user?.userId,
            UserName: user?.username,
            OrderCategory: type,
            OrderType: "Market",
            ScriptName: exchange.SymbolName,
            TokenNo: exchange.SymbolToken,
            ActionType: type === "BUY" ? "Bought By Trader" : "Sold By Trader",
            OrderPrice: type === "BUY" ? askval : bidval,
            Lot: lotsCount,
            MarginUsed: marginvalue,
            HoldingMarginReq: holdmarginvalue,
            OrderStatus: "Active",
            SymbolType: exchange.ExchangeType,
          };
        } else {
          obj = {
            Id: pendingData !== null ? pendingData?.id : "",
            OrderDate: "",
            OrderTime: "",
            actualLot: exchange.Lotsize,
            selectedlotsize: exchange.Lotsize,
            OrderNo: "",
            UserId: user?.userId,
            UserName: user?.username,
            OrderCategory: type,
            OrderType: "Limit",
            isstoplossorder: type === "SELL" && bidval > orderLotsCount? true : false,
            ScriptName: exchange.SymbolName,
            TokenNo: exchange.SymbolToken,
            ActionType: "Order Placed @",
            OrderPrice: orderLotsCount,
            Lot: lotsCount,
            MarginUsed: marginvalue,
            HoldingMarginReq: holdmarginvalue,
            OrderStatus: "Pending",
            SymbolType: exchange.ExchangeType,
            isedit: redirectFromPending ? true : false,
          };
        }
        setSaveModalDataFun(obj);
        if (obj.OrderType === "Market") {
          dispatch(getCheckBeforeTradeMarketData(obj));
        } else {
          dispatch(getCheckBeforeTradeLimitData(obj));
        }
      } else {
        setTradeModalOpen(false);
        setinsufficientMarginModalOpen(true);
      }
    }
    if (type === "BUY") {
      // (marginBalance >= orderLotsCount)
      if (marginBalance >= mrgAvl) {
        const bidval = document.getElementById(
          `bid_mobile${exchange.SymbolToken}`
        ).innerHTML;
        const askval = document.getElementById(
          `ask_mobile${exchange.SymbolToken}`
        ).innerHTML;
        const lowval = document.getElementById(
          `low_mobile${exchange.SymbolToken}`
        ).innerHTML;
        const highval = document.getElementById(
          `high_mobile${exchange.SymbolToken}`
        ).innerHTML;
        const orderprice = orderLotsCount;
        let finallotsize = 0;
        let marginvalue = 0;
        let holdmarginvalue = 0;
        if (isLimit) {
          if (
            refreshData?.AllowOrdersCurrentBid &&
            refreshData?.AllowFreshEntryHighAndBelow &&
            refreshData?.AllowOrdersHighLow
          ) {
          }
          //all false
          if (
            (!refreshData?.AllowOrdersCurrentBid &&
              !refreshData?.AllowFreshEntryHighAndBelow &&
              !refreshData?.AllowOrdersHighLow) ||
            (refreshData?.AllowOrdersCurrentBid &&
              !refreshData?.AllowFreshEntryHighAndBelow &&
              !refreshData?.AllowOrdersHighLow)
          ) {
            alert("Error", "You are not authorized for place orders");
            return;
          }

          //1=true, 2=true, 3=false  D. 1=true, 2=true, 3=false  = Order above high & below low only

          if (
            refreshData?.AllowOrdersCurrentBid &&
            refreshData?.AllowFreshEntryHighAndBelow &&
            !refreshData?.AllowOrdersHighLow
          ) {
            if (
              parseFloat(orderprice) > parseFloat(lowval) &&
              parseFloat(orderprice) < parseFloat(highval)
            ) {
              alert(
                "Error",
                "Order Placed greator than " +
                  highval +
                  " and less " +
                  lowval +
                  "."
              );
              return;
            }
          }

          //E. 1=true, 2=false, 3=true = Allow Orders between and equal High / will place on all price (high/low and bid/ask)

          if (
            refreshData?.AllowOrdersCurrentBid &&
            !refreshData?.AllowFreshEntryHighAndBelow &&
            refreshData?.AllowOrdersHighLow
          ) {
            if (
              parseFloat(orderprice) <= parseFloat(lowval) ||
              parseFloat(orderprice) >= parseFloat(highval)
            ) {
              alert(
                "Error",
                "Order Placed between " + lowval + " and " + highval + "."
              );
              return;
            }
          }

          // F. 1 = false, 2 = false, 3 = true = Allow Orders between and equal High(high / low but not should in between bid / ask)

          if (
            !refreshData?.AllowOrdersCurrentBid &&
            !refreshData?.AllowFreshEntryHighAndBelow &&
            refreshData?.AllowOrdersHighLow
          ) {
            if (
              parseFloat(orderprice) <= parseFloat(lowval) ||
              parseFloat(orderprice) >= parseFloat(highval) ||
              (parseFloat(orderprice) >= parseFloat(bidval) &&
                parseFloat(orderprice) <= parseFloat(askval))
            ) {
              alert(
                "Error",
                "Order Placed between " +
                  askval +
                  " and " +
                  highval +
                  " OR " +
                  lowval +
                  " and " +
                  bidval +
                  "."
              );
              return;
            }
          }

          //G. 1=false,2=true. 3=true = TRADE WORKING AT ALL CONDITION but will not take b/w (bid/ask)

          if (
            !refreshData?.AllowOrdersCurrentBid &&
            refreshData?.AllowFreshEntryHighAndBelow &&
            refreshData?.AllowOrdersHighLow
          ) {
            if (
              parseFloat(orderprice) >= parseFloat(bidval) &&
              parseFloat(orderprice) <= parseFloat(askval)
            ) {
              alert(
                "Error",
                "Order Placed between " + bidval + " and " + askval + "."
              );
              return;
            }
          }
        }
        const enterdlot = lotsCount;
        const selectedlotsize = exchange.Lotsize;
        if (exchange.ExchangeType === "MCX") {
          const Intraday_Exposure_Margin_MCX =
            refreshData?.Intraday_Exposure_Margin_MCX;
          const Holding_Exposure_Margin_MCX =
            refreshData?.Holding_Exposure_Margin_MCX;
          const MCX_Exposure_Type = refreshData?.Mcx_Exposure_Type;
          if (MCX_Exposure_Type === "per_lot") {
            const symbolname = exchange.SymbolName; //GOLD22AUGFUT
            const symarr = symbolname.split("_");
            const similersym = setsymbol(symarr[0] + "".toString().trim());

            const Intraday_Exposure = `${refreshData}?.MCX_Exposure_Lot_wise_${similersym}_Intraday`;
            const Intraday_hold_Exposure = `${refreshData}?.MCX_Exposure_Lot_wise_${similersym}_Holding`;
            marginvalue = parseInt(enterdlot) * parseInt(Intraday_Exposure);
            holdmarginvalue =
              parseInt(enterdlot) * parseInt(Intraday_hold_Exposure);
          } else {
            finallotsize = parseInt(enterdlot) * parseInt(selectedlotsize);
            marginvalue =
              (parseInt(orderprice) * finallotsize) /
              parseInt(Intraday_Exposure_Margin_MCX);
            holdmarginvalue =
              (parseInt(orderprice) * finallotsize) /
              parseInt(Holding_Exposure_Margin_MCX);
          }
        } else if (exchange.ExchangeType === "NSE") {
          const Intraday_Exposure_Margin_Equity =
            refreshData?.Intraday_Exposure_Margin_EQUITY;
          const Holding_Exposure_Margin_Equity =
            refreshData?.Holding_Exposure_Margin_EQUITY;
          const NSE_Exposure_Type = refreshData?.NSE_Exposure_Type;
          if (NSE_Exposure_Type === "per_lot") {
            marginvalue =
              parseInt(enterdlot) * parseInt(Intraday_Exposure_Margin_Equity);
            holdmarginvalue =
              parseInt(enterdlot) * parseInt(Holding_Exposure_Margin_Equity);
          } else {
            finallotsize = parseInt(enterdlot) * parseInt(selectedlotsize);
            marginvalue =
              (parseInt(orderprice) * finallotsize) /
              parseInt(Intraday_Exposure_Margin_Equity);
            holdmarginvalue =
              (parseInt(orderprice) * finallotsize) /
              parseInt(Holding_Exposure_Margin_Equity);
          }
        } else {
          const Intraday_Exposure_Margin_CDS =
            refreshData?.Intraday_Exposure_Margin_CDS;
          const Holding_Exposure_Margin_CDS =
            refreshData?.Holding_Exposure_Margin_CDS;
          const CDS_Exposure_Type = refreshData?.CDS_Exposure_Type;
          if (CDS_Exposure_Type === "per_lot") {
            marginvalue =
              parseInt(enterdlot) * parseInt(Intraday_Exposure_Margin_CDS);
            holdmarginvalue =
              parseInt(enterdlot) * parseInt(Holding_Exposure_Margin_CDS);
          } else {
            finallotsize = parseInt(enterdlot) * parseInt(selectedlotsize);
            marginvalue =
              (parseInt(orderprice) * finallotsize) /
              parseInt(Intraday_Exposure_Margin_CDS);
            holdmarginvalue =
              (parseInt(orderprice) * finallotsize) /
              parseInt(Holding_Exposure_Margin_CDS);
          }
        }

        let obj = {};
        if (!isLimit) {
          obj = {
            Id: pendingData !== null ? pendingData?.id : "",
            OrderDate: "",
            OrderTime: "",
            actualLot: exchange.Lotsize,
            selectedlotsize: exchange.Lotsize,
            OrderNo: "",
            UserId: user?.userId,
            UserName: user?.username,
            OrderCategory: type,
            OrderType: "Market",
            ScriptName: exchange.SymbolName,
            TokenNo: exchange.SymbolToken,
            ActionType: type === "BUY" ? "Bought By Trader" : "Sold By Trader",
            OrderPrice: type === "BUY" ? askval : bidval,
            Lot: lotsCount,
            MarginUsed: marginvalue,
            HoldingMarginReq: holdmarginvalue,
            OrderStatus: "Active",
            SymbolType: exchange.ExchangeType,
          };
        } else {
          obj = {
            Id: pendingData !== null ? pendingData?.id : "",
            OrderDate: "",
            OrderTime: "",
            actualLot: exchange.Lotsize,
            selectedlotsize: exchange.Lotsize,
            OrderNo: "",
            UserId: user?.userId,
            UserName: user?.username,
            OrderCategory: type,
            OrderType: "Limit",
            isstoplossorder: type === "BUY" && askval < orderLotsCount? true : false,
            ScriptName: exchange.SymbolName,
            TokenNo: exchange.SymbolToken,
            ActionType: "Order Placed @",
            OrderPrice: orderLotsCount,
            Lot: lotsCount,
            MarginUsed: marginvalue,
            HoldingMarginReq: holdmarginvalue,
            OrderStatus: "Pending",
            SymbolType: exchange.ExchangeType,
            isedit: redirectFromPending ? true : false,
          };
        }
        setSaveModalDataFun(obj);
        if (!isReadOnlyCondition) {
          setIsReadOnlyCondition(true);
          if (obj.OrderType === "Market") {
            dispatch(getCheckBeforeTradeMarketData(obj));
          } else {
            dispatch(getCheckBeforeTradeLimitData(obj));
          }
        }
      } else {
        setTradeModalOpen(false);
        setinsufficientMarginModalOpen(true);
      }
    }
    setIsReadOnlyCondition(true);
  }

  const closeinsufficientMarginModalOpen = () => {
    setinsufficientMarginModalOpen(false);
  };

  // -------------  account page functions --------------------------

  // closing fund modal fun
  function fundsModalCloseFun(params) {
    setFundsModalState(params);
  }

  // closing bill and invoice modal fun
  function billInvoiceStateFun(params) {
    setBillInvoiceState(params);
  }

  // closing profile modal fun
  function profileStateFun(params) {
    setProfileState(params);
  }

  // closing notification modal fun
  function notificationstStateFun(params) {
    setNotificationstState(params);
  }

  // closing raise complaint modal fun
  function raiseComplaintStateFun(params) {
    setRaiseComplaintState(params);
  }

  // closing change password modal fun
  function changePasswordStateFun(params) {
    setChangePasswordState(params);
  }

  // clearing localstorage when logout
  function userLogout() {
    localStorage.clear();
    setUser(null);
  }

  const handleKeyPress = (e) => {
    if (e.key === "-" || e.key === "+") {
      e.preventDefault(); // Prevent entering minus sign or plus sign
    }
    setIsReadOnlyCondition(false);
  };

  function handleInputChange(e) {
    const enteredValue = e.target.value;
    // Remove any non-numeric characters from the input
    const numericValue = enteredValue.replace(/[^0-9]/g, "");
    const sanitizedValue =
      numericValue === "0" || "" || undefined ? "1" : numericValue;
    setOrderLotsCount(sanitizedValue);
  }

  function calculateIdCMPData(activeTabDataDelete) {
    // Filter the data based on tabSelect (SymbolType)
    if (mcxnseActiveTradeData.length > 0 || mcxnseActiveTradeData != null) {
      const idcmp = mcxnseActiveTradeData.filter(
        (item) => item.SymbolType === activeTabDataDelete
      );

      // Reduce the filtered data into the desired format
      const result = idcmp.map((item) => ({
        orderid: parseInt(item.Id),
        cmp: parseInt(item.cmp),
      }));
      // Set the filtered data (result) into your state
      setMcxNseActiveTradePostData(result);
    } else {
      const idcmp = tradeData.filter(
        (item) => item.SymbolType === activeTabDataDelete
      );

      // Reduce the filtered data into the desired format
      const result = idcmp.map((item) => ({
        orderid: parseInt(item.Id),
        cmp: 123.34,
      }));
      // Set the filtered data (result) into your state
      setMcxNseActiveTradePostData(result);
    }
  }

  function marketopenClose(e, item) {

    const socket = new WebSocket("wss://ws.tradewingss.com/api/webapiwebsoc");
    // Event listener for WebSocket open event
    socket.addEventListener("open", () => {
      // console.log("WebSocket connection established");
    });

    // Event listener for WebSocket message event
    socket.addEventListener("message", (event) => {
      const message = event.data;
      // Check if the message is not empty
      if (message) {
        try {
          const newData = JSON.parse(message);
          const currentDate = new Date();
          const dayOfWeek = currentDate.getDay();

          if (
            modalData !== null ||
            undefined ||
            newData.instrument_token !== ""
          ) {
            
            if (newData.instrument_token === parseInt(item.SymbolToken)) {
              
              if (newData.ask === 0 || newData.bid === 0) {
                setMarketClose(true);
              } else {
                if (marketTimeData) {
                  setMarketClose(false);
                }
              }
            }
          }
        } catch (error) {
          console.warn("Error parsing JSON:", error);
        }
      }
    });

    // Event listener for WebSocket close event
    socket.addEventListener("close", (event) => {
      // console.log("WebSocket connection closed", event.code, event.reason);
    });

    // Event listener for WebSocket error event
    socket.addEventListener("error", (error) => {
      // console.warn("WebSocket error:", error);
    });

    // Clean up the WebSocket connection on component unmount
    return () => {
      socket.close();
    };
  }
  const handleFlag = (activeTabDataDelete) => {
    setExchangeWord(activeTabDataDelete);
    calculateIdCMPData(activeTabDataDelete);
  };

  return (
    <>
      {isShown && (
        <div
          className={`flex justify-center items-center w-screen h-screen absolute top-0 bg-[#fff] z-[1000] `}
        >
          <img src={Logo} alt="logo" />
        </div>
      )}
      {user === null ? (
        <Login settingGlobalTab={settingGlobalTab} settinguser={settinguser} />
      ) : (
        <>
          {/* watchlist */}
          <div
            className={` ${
              globalActiveTab === "watchlist" ? "flex" : "hidden"
            } flex-col h-[100vh] fixed top-0 h-full w-[100%] bg-[#fff]`}
          >
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(1, 28, 58, 0.8) 0%, #011C3A 41.38%)",
              }}
              className="flex justify-around pt-[6px] pb-[2px]"
            >
              <div
                className={`w-[33%] text-[16px pl-[10px] pb-[5px] ${
                  activeTab === "mcx" ? "text-[#fff]" : "text-[#707e89]"
                } md:text-[20px] flex items-center  flex-col font-[500] cursor-pointer`}
                onClick={() => {
                  setActiveTab("mcx");
                  setSearchValue("");
                }}
              >
                MCX
                <span>Futures</span>
                <hr
                  className={`md:w-[100px] w-[70px] h-[3px] bg-[#fff] ${
                    activeTab === "mcx" ? "show" : "hidden"
                  }`}
                />
              </div>
              <div
                className={`w-[34%] text-[16px] pl-[10px] pb-[5px] ${
                  activeTab === "nse" ? "text-[#fff]" : "text-[#707e89]"
                }  md:text-[20px] flex items-center  flex-col font-[500] cursor-pointer`}
                onClick={() => {
                  setActiveTab("nse");
                  setSearchValue("");
                }}
              >
                NSE
                <span>Futures</span>
                <hr
                  className={`md:w-[100px] w-[70px] h-[3px] bg-[#fff] ${
                    activeTab === "nse" ? "show" : "hidden"
                  }`}
                />
              </div>
              <div
                className={`w-[33%] text-left text-[16px] justify-center h-[50px] ${
                  activeTab === "opt" ? "text-[#fff]" : "text-[#707e89]"
                } md:text-[20px] flex flex-col items-center font-[500] cursor-pointer`}
                onClick={() => {
                  setActiveTab("opt");
                  setSearchValue("");
                }}
              >
                OPT
                <hr
                  className={`md:w-[100px] w-[70px] justify-end h-[3px] bg-[#fff] ${
                    activeTab === "opt" ? "show" : "hidden"
                  }`}
                />
              </div>
            </div>
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(1, 28, 58, 0.8) 0%, #011C3A 41.38%)",
              }}
              className="flex py-2 pl-[5px] pr-[5px] items-center"
            >
              <div className="w-[90%] ml-[10px] h-[30px] flex items-center bg-[#fff] rounded-[10px]">
                <img
                  src={Search}
                  alt="add-button"
                  className="h-[24px] w-[24px]  ml-[7px] cursor-pointer"
                />
                <input
                  className="w-[100%] h-[28px] px-2 rounded-[10px] outline-none"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              </div>
              <img
                src={AddIcon}
                alt="add-button"
                className="h-[24px] w-[24px]  mr-[14px] ml-[15px] cursor-pointer"
                onClick={() => setGlobalModalVisible(true)}
              />
            </div>
            {/* mcx Table */}
            <div
              className={`${
                activeTab === "mcx" ? "block" : "hidden"
              } overflow-auto h-full mb-[65px]`}
            >
              {mcxSocketData
                ?.filter((item) =>
                  item?.SymbolName?.toLowerCase()?.includes(
                    searchValue.toLowerCase()
                  )
                )
                .map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div
                        className="px-4 h-[100px] pt-2 py-2 flex flex-col justify-between"
                        onClick={(e) => {
                          setModalId(item.SymbolToken);
                          marketopenClose(e, item);
                          dispatch(getMarketTimeData("MCX", user?.refId));
                          setIsLimit(false);
                          if (marketClose) {
                            setMarketModalOpen(true);
                          } else {
                            setLotsCount(1);
                            setTradeModalOpen(true);
                            setTimeout(() => {
                              calculateLots(
                                e,
                                "",
                                item.SymbolName,
                                item.Lotsize,
                                item.SymbolToken,
                                item.ExchangeType
                              );
                            }, 1000);
                          }
                          setModalId(item.SymbolToken);
                        }}
                      >
                        <div className="flex justify-between items-center h-[45px]">
                          <div className="flex flex-col h-[45px] w-[56%]">
                            <span
                              className="font-[600] text-[16px] "
                              style={{ wordBreak: "break-all" }}
                            >
                              {splitforExchangeName(item.SymbolName)}
                            </span>
                            <span className="font-[500] text-[14px]">
                              {splitforDate(item.SymbolName)}
                            </span>
                          </div>
                          <div className="flex flex-col h-[45px] w-[22%]">
                            <span
                              className="font-[650] text-[17px] text-[#006400]"
                              style={{ textAlign: "left" }}
                              id={"bid_mobile" + item.SymbolToken}
                            >
                              {item.bid !== 0 ? item.bid : 0}
                            </span>
                          </div>
                          <div className="flex flex-col h-[45px] w-[22%]">
                            <span
                              className="font-[650] text-[17px] text-[#006400]"
                              style={{ textAlign: "left" }}
                              id={"ask_mobile" + item.SymbolToken}
                            >
                              {item.ask !== 0 ? item.ask : 0}
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between items-center h-[30px]">
                          <span className="font-[500]">
                            ch:{" "}
                            <span
                              id={"change_mobile" + item.SymbolToken}
                              className="text-[#f00]"
                            >
                              {item.change}
                            </span>
                          </span>
                          <span className="font-[500]">
                            H:{" "}
                            <span id={"high_mobile" + item.SymbolToken}>
                              {item.high_}
                            </span>
                          </span>
                          <span className="font-[500]">
                            L:{" "}
                            <span id={"low_mobile" + item.SymbolToken}>
                              {item.low_}
                            </span>
                          </span>
                          <span className="font-[500] text-[#000099]">
                            Ltp:{" "}
                            <span id={"last_price_mobile" + item.SymbolToken}>
                              {item.last_price}
                            </span>
                          </span>
                        </div>
                      </div>
                      <hr className="bg-[#d5ddd3] mx-4 h-[2px]" />
                    </React.Fragment>
                  );
                })}
            </div>

            {/* nse table */}
            <div
              className={`${
                activeTab === "nse" ? "block" : "hidden"
              } overflow-auto h-full mb-[65px]`}
            >
              {nseSocketData
                ?.filter((item) =>
                  item?.SymbolName?.toLowerCase()?.includes(
                    searchValue.toLowerCase()
                  )
                )
                .map((item) => {
                  return (
                    <React.Fragment key={item.SymbolName}>
                      <div
                        className="px-4 h-[100px] pt-2 py-2 flex flex-col justify-between"
                        onClick={(e) => {
                          dispatch(getMarketTimeData("NSE", user?.refId));
                          setIsLimit(false);
                          if (marketClose) {
                            setMarketModalOpen(true);
                          } else {
                            setLotsCount(1);
                            setTradeModalOpen(true);
                            setTimeout(() => {
                              calculateLots(
                                e,
                                "",
                                item.SymbolName,
                                item.Lotsize,
                                item.SymbolToken,
                                item.ExchangeType
                              );
                            }, 1000);
                          }

                          setModalId(item.SymbolToken);
                        }}
                      >
                        <div className="flex justify-between items-center h-[45px]">
                          <div className="flex flex-col h-[45px] w-[48%]">
                            <span
                              className="font-[600] text-[16px] "
                              style={{ wordBreak: "break-all" }}
                            >
                              {splitforExchangeName(item.SymbolName)}
                            </span>
                            <span className="font-[500] text-[14px]">
                              {splitforDate(item.SymbolName)}
                            </span>
                          </div>
                          <div className="flex flex-col h-[45px] w-[26%]">
                            <span
                              className="font-[650] text-[17px] text-[#006400]"
                              style={{ textAlign: "left" }}
                              id={"bid_mobile" + item.SymbolToken}
                            >
                              {item.bid !== 0 ? item.bid : 0}
                            </span>
                          </div>
                          <div className="flex flex-col h-[45px] w-[26%]">
                            <span
                              className="font-[650] text-[17px] text-[#006400]"
                              style={{ textAlign: "left" }}
                              id={"ask_mobile" + item.SymbolToken}
                            >
                              {item.ask !== 0 ? item.ask : 0}
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between items-center h-[30px]">
                          <span className="font-[500]">
                            ch:{" "}
                            <span
                              id={"change_mobile" + item.SymbolToken}
                              className="text-[#f00]"
                            >
                              {item.change}
                            </span>
                          </span>
                          <span className="font-[500]">
                            H:{" "}
                            <span id={"high_mobile" + item.SymbolToken}>
                              {item.high_}
                            </span>
                          </span>
                          <span className="font-[500]">
                            L:{" "}
                            <span id={"low_mobile" + item.SymbolToken}>
                              {item.low_}
                            </span>
                          </span>
                          <span className="font-[500] text-[#000099]">
                            Ltp:{" "}
                            <span id={"last_price_mobile" + item.SymbolToken}>
                              {item.last_price}
                            </span>
                          </span>
                        </div>
                      </div>
                      <hr className="bg-[#d5ddd3] mx-4 h-[2px]" />
                    </React.Fragment>
                  );
                })}
            </div>

            {/* opt table */}
            <div
              className={`${
                activeTab === "opt" ? "block" : "hidden"
              } overflow-auto h-full mb-[65px]`}
            >
              {optSocketData
                ?.filter((item) =>
                  item?.SymbolName?.toLowerCase()?.includes(
                    searchValue.toLowerCase()
                  )
                )
                .map((item) => {
                  return (
                    <React.Fragment key={item.SymbolName}>
                      <div
                        className="px-4 h-[100px] pt-2 py-2 flex flex-col justify-between"
                        onClick={(e) => {
                          dispatch(getMarketTimeData("OPT", user?.refId));
                          setIsLimit(false);
                          if (marketClose) {
                            setMarketModalOpen(true);
                          } else {
                            setLotsCount(1);
                            setTradeModalOpen(true);
                            setTimeout(() => {
                              calculateLots(
                                e,
                                "",
                                item.SymbolName,
                                item.Lotsize,
                                item.SymbolToken,
                                item.ExchangeType
                              );
                            }, 1000);
                          }
                          setModalId(item.SymbolToken);
                        }}
                      >
                        <div className="flex justify-between items-center h-[45px]">
                          <div className="flex flex-col h-[45px] w-[52%]">
                            <span
                              className="font-[600] text-[16px] "
                              style={{ wordBreak: "break-all" }}
                            >
                              {splitforExchangeName(item.SymbolName)}
                            </span>
                            <span className="font-[500] text-[14px]">
                              {splitforDate(item.SymbolName)}
                            </span>
                          </div>
                          <div className="flex flex-col h-[45px] w-[24%]">
                            <span
                              className="font-[650] text-[17px] text-[#006400]"
                              style={{ textAlign: "left" }}
                              id={"bid_mobile" + item.SymbolToken}
                            >
                              {item.bid !== 0 ? item.bid : 0}
                            </span>
                          </div>
                          <div className="flex flex-col h-[45px] w-[24%]">
                            <span
                              className="font-[650] text-[17px] text-[#006400]"
                              style={{ textAlign: "left" }}
                              id={"ask_mobile" + item.SymbolToken}
                            >
                              {item.ask !== 0 ? item.ask : 0}
                            </span>
                          </div>
                        </div>
                        <div className="flex justify-between items-center h-[30px]">
                          <span className="font-[500]">
                            ch:{" "}
                            <span
                              id={"change_mobile" + item.SymbolToken}
                              className="text-[#f00]"
                            >
                              {item.change}
                            </span>
                          </span>
                          <span className="font-[500]">
                            H:{" "}
                            <span id={"high_mobile" + item.SymbolToken}>
                              {item.high_}
                            </span>
                          </span>
                          <span className="font-[500]">
                            L:{" "}
                            <span id={"low_mobile" + item.SymbolToken}>
                              {item.low_}
                            </span>
                          </span>
                          <span className="font-[500] text-[#000099]">
                            Ltp:{" "}
                            <span id={"last_price_mobile" + item.SymbolToken}>
                              {item.last_price}
                            </span>
                          </span>
                        </div>
                      </div>
                      <hr className="bg-[#d5ddd3] mx-4 h-[2px]" />
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
          <TradeSaveModal
            saveModalState={saveModalState}
            saveModalStateFun={saveModalStateFun}
            saveData={saveData}
            user={user}
          />
          <TradeModalError
            checkBeforeTradeState={checkBeforeTradeState}
            setCheckBeforeTradeFun={setCheckBeforeTradeFun}
            checkBeforeTradeMessage={checkBeforeTradeMessage}
          />
          <GlobalModal
            globalModalVisible={globalModalVisible}
            closeModal={closeModal}
            activeTab={activeTab}
            mcxSocketData={mcxSocketData}
            nseSocketData={nseSocketData}
            optSocketData={optSocketData}
            user={user}
            setUpdateTableFun={setUpdateTableFun}
          />
          <TradeExtraModal
            tradeExtraModalOpen={tradeExtraModalOpen}
            data={data}
            allActiveData={allActiveData}
            tradeExtraModalOpenFun={tradeExtraModalOpenFun}
            user={user}
            activeTab={activeTab}
          />
          {/* Watchlist Page end */}

          <CloseTradeModal
            closeTradeState={closeTradeState}
            closeTradeStateFun={closeTradeStateFun}
            closeTradeData={closeTradeData}
          />
          <TradeCloseModal
            tradeCloseState={tradeCloseState}
            tradeCloseFun={tradeCloseFun}
            closeTradeFromPortfolioData={closeTradeFromPortfolioData}
          />
          <RaiseCompliantModal
            raiseComplaintState={raiseComplaintState}
            raiseComplaintStateFun={raiseComplaintStateFun}
          />
          <DeleteConfirmModal
            deleteTradeModalOpen={deleteTradeModalOpen}
            deleteTradeModalOpenFun={deleteTradeModalOpenFun}
            id={deleteId}
            setDeleteIDFun={setDeleteIDFun}
          />
          <TradeExtraModal
            tradeExtraModalOpen={tradeExtraModalOpen}
            data={data}
            tradeExtraModalOpenFun={tradeExtraModalOpenFun}
            user={user}
            activeTab={activeTab}
          />
          <InsufficientMarginModal
            onClose={closeinsufficientMarginModalOpen}
            insufficientMarginModalOpen={insufficientMarginModalOpen}
          />

          {/* table modal style={{ display: "none" }} */}
          <div
            className={`fixed w-screen h-screen z-[1000]  justify-between items-center ${
              tradeModalOpen ? "flex" : "hidden"
            }`}
          >
            <div className="relative w-screen h-screen z-[800] bg-[#121212] opacity-[0.5]"></div>
            <div className="fixed h-auto transform -translate-x-1/2 -translate-y-1/2 top-[50%] left-[50%] bg-[#ECF5FF] w-[92%] h-[96%] overflow-y-scroll z-[900] mx-auto">
              <div className="flex justify-between items-center  px-4 border border-[] py-2 ">
                <span className="text-[#011C3A] text-[14px] font-[500]">
                  Place Order
                </span>
                <span
                  onClick={() => {
                    tradeModalFun(false);
                    setIsLimit(false);
                    setRedirectFromPendingFun(false);
                    setModalDataFun("");
                    setLotsCount(1);
                    setIsReadOnlyCondition(false);
                    setPendingDataFun(null);
                    setOrderLotsCount("");
                  }}
                  className="cursor-pointer text-[#707E89] text-[14px]"
                >
                  ✕
                </span>
              </div>
              <div className=" px-2">
                {modalData?.map((item) => {
                  // if (activeTab === "Pending") {
                  //   return (
                  //     item.SymbolToken === modalId && (
                  //       <React.Fragment key={item.SymbolToken}>
                  //         <div className="flex justify-around mt-[8px]">
                  //           <div className="flex flex-col w-[50%] justify-center pt-[3px] pb-[3px] border border-gray-300 border-t-1.5 border-solid items-center">
                  //             <span className="text-[#383838] text-[11px] font-[700]">
                  //               Ledger Balance
                  //             </span>
                  //             <span className="text-[#383838] text-[12px] font-[700]">
                  //               {parseInt(ledgerBalance)}
                  //             </span>
                  //           </div>
                  //           <div className="flex flex-col   w-[50%] justify-center pt-[3px] pb-[3px] border border-gray-300 border-t-1.5 border-solid justify-center items-center text-center">
                  //             <span className="text-[#383838] text-[11px] font-[700]">
                  //               Margin Available
                  //             </span>
                  //             <span
                  //               className="text-[#383838] text-[12px] font-[700]"
                  //               id="modal_marginBalance"
                  //             >
                  //               {parseInt(ledgerBalance)}
                  //             </span>
                  //           </div>
                  //         </div>
                  //         <div className="flex justify-around ">
                  //           <div className="flex flex-col w-[50%] justify-center pt-[3px] pb-[3px] border border-gray-300 border-t-1.5 border-solid items-center">
                  //             <span className="text-[#383838] text-[11px] font-[700]">
                  //               Active P/L
                  //             </span>
                  //             <span
                  //               className="text-[#383838] text-[12px] font-[700]"
                  //               id="modal_ActivePL"
                  //             >
                  //               0
                  //             </span>
                  //           </div>
                  //           <div className="flex flex-col w-[50%] justify-center pt-[3px] pb-[3px] border border-gray-300 border-t-1.5 border-solid justify-center items-center text-center">
                  //             <span className="text-[#383838] text-[11px] font-[700]">
                  //               M2M
                  //             </span>
                  //             <span
                  //               className="text-[#383838] text-[12px] font-[700]"
                  //               id="m2mEquity"
                  //             >
                  //               {parseInt(ledgerBalance)}
                  //             </span>
                  //           </div>
                  //         </div>
                  //         <div className="flex justify-around mr-[31px] ml-[31px] pt-[3px] pb-[3px] mt-[3px] mb-[3px]  cursor-pointer rounded-[10px]">
                  //           <span className="text-[#383838] text-[14px] font-[700]">
                  //             {item.SymbolName}
                  //           </span>
                  //         </div>
                  //         <div className="flex justify-around bg-[#EEEEEE]  pt-[3px] pb-[3px] mb-[3px] cursor-pointer rounded-[10px]">
                  //           {!redirectFromPending && (
                  //             <div
                  //               className={`tracking-[0.04em] text-[#707E89] text-[11px] font-[700] ${
                  //                 isLimit ? "" : "text-[#ff0000]"
                  //               }`}
                  //               onClick={() => setIsLimit(false)}
                  //             >
                  //               Market
                  //             </div>
                  //           )}
                  //           <div
                  //             className={`tracking-[0.04em] text-[#707E89] text-[11px] font-[700] ${
                  //               isLimit ? "text-[#ff0000]" : ""
                  //             }`}
                  //             onClick={() => setIsLimit(true)}
                  //           >
                  //             Limit
                  //           </div>
                  //         </div>
                  //         <div className=" flex justify-between h-[40px] mt-[4px]  mb-[4px] items-center">
                  //           <span className="text-[#707E89] text-[16px] font-[500]">
                  //             Lots
                  //           </span>
                  //           <div className="flex justify-between  items-center w-[160px] bg-[#EEEEEE] pl-[12px] pr-[12px]  rounded-[10px]">
                  //             <span
                  //               className={`text-[#011C3A] text-[12px] font-[700] cursor-pointer ${
                  //                 lotsCount === 1 ? "" : ""
                  //               }`}
                  //               onClick={(e) => {
                  //                 calculateLots(
                  //                   e,
                  //                   "minus",
                  //                   item.SymbolName,
                  //                   item.Lotsize,
                  //                   item.SymbolToken,
                  //                   item.ExchangeType
                  //                 );
                  //               }}
                  //             >
                  //               -
                  //             </span>
                  //             <span className="text-[#011C3A] text-[12px] font-[700] w-[65px] text-center">
                  //               {lotsCount}
                  //             </span>
                  //             <span
                  //               className="text-[#011C3A] text-[12px] font-[700] cursor-pointer"
                  //               onClick={(e) =>
                  //                 calculateLots(
                  //                   e,
                  //                   "plus",
                  //                   item.SymbolName,
                  //                   item.Lotsize,
                  //                   item.SymbolToken,
                  //                   item.ExchangeType
                  //                 )
                  //               }
                  //             >
                  //               +
                  //             </span>
                  //           </div>
                  //         </div>
                  //         <input
                  //           className="mt-[3px] rounded-[5px] border border-solid border-1 border-[#011C3A] mb-[3px] w-[100%] pt-[3px] pb-[3px] pl-[12px] text-[#383838] text-[12px] font-[500]"
                  //           value={lotsCount}
                  //           onChange={(e) =>
                  //             calculateLots(
                  //               e,
                  //               "input",
                  //               item.SymbolName,
                  //               item.Lotsize,
                  //               item.SymbolToken,
                  //               item.ExchangeType
                  //             )
                  //           }
                  //           maxLength={5}
                  //           type="number"
                  //         />
                  //         {!isLimit && (
                  //           <div className="text-center w-full mb-[5px]">
                  //             <span
                  //               className="text-[11px]"
                  //               id={`mrgval${item.SymbolToken}`}
                  //             ></span>
                  //           </div>
                  //         )}
                  //         {isLimit && (
                  //           <>
                  //             <div className=" flex justify-between mt-[3px] mb-[3px] items-center">
                  //               <span className="text-[#707E89] text-[11px] font-[500]">
                  //                 Prices
                  //               </span>
                  //             </div>
                  //             <input
                  //               className="mt-[3px]  appearance-none rounded-[5px] border border-solid border-1 border-[#011C3A] mb-[3px] w-[100%]  pt-[3px] pb-[3px] pl-[12px] text-[#383838] text-[12px] font-[500]"
                  //               onKeyPress={handleKeyPress}
                  //               onChange={(e) => {
                  //                 handleInputChange(e);
                  //               }}
                  //               value={orderLotsCount}
                  //               type="number"
                  //               placeholder="0"
                  //               maxLength={5}
                  //             />
                  //           </>
                  //         )}

                  //         <hr className="h-[2px] w-[95%] mx-auto bg-[#CCCCCC]" />
                  //         {redirectFromPending ? (
                  //           <div
                  //             className={`flex justify-around mt-[3px] pt-[3px] pb-[3px] cursor-pointer ${
                  //               isLimit ? "mb-[3px]" : "mb-[3px]"
                  //             }`}
                  //           >
                  //             <div
                  //               className={`flex justify-center items-center tracking-[0.04em] text-[#fff] pt-[3px] pb-[3px] ${
                  //                 pendingData?.method === "BUY"
                  //                   ? "bg-[#119832]"
                  //                   : "bg-[#EE2D26]"
                  //               } bg-[#119832] w-full h-full text-center text-[11px] font-[700] rounded-[10px]`}
                  //               onClick={() => {
                  //                 if (!isReadOnlyCondition) {
                  //                   handleCalculations(
                  //                     item,
                  //                     pendingData?.method === "BUY"
                  //                       ? "BUY"
                  //                       : "SELL",
                  //                     orderLotsCount
                  //                   );
                  //                 }
                  //               }}
                  //             >
                  //               {pendingData?.method}
                  //             </div>
                  //           </div>
                  //         ) : (
                  //           <div
                  //             className={`flex justify-around mt-[3px] pt-[3px] pb-[3px] cursor-pointer ${
                  //               isLimit ? "mb-[3px]" : "mb-[3px]"
                  //             }`}
                  //           >
                  //             <div
                  //               className="flex justify-center items-center tracking-[0.04em] text-[#fff] pt-[3px] pb-[3px] text-[12px] w-full h-full text-center font-[700] bg-[#EE2D26] rounded-l-[10px]"
                  //               onClick={() => {
                  //                 if (!isReadOnlyCondition) {
                  //                   handleCalculations(
                  //                     item,
                  //                     "SELL",
                  //                     orderLotsCount
                  //                   );
                  //                 }
                  //               }}
                  //             >
                  //               SELL
                  //               {!isLimit && (
                  //                 <span
                  //                   id={"Modal_bid" + item.SymbolToken}
                  //                   className="text-[12px]"
                  //                 >
                  //                   {" "}
                  //                   @ {item.sell}
                  //                 </span>
                  //               )}
                  //             </div>

                  //             <div
                  //               className="flex justify-center items-center tracking-[0.04em] text-[#fff] pt-[3px] pb-[3px] bg-[#119832] w-full h-full text-center text-[12px] font-[700] rounded-r-[10px]"
                  //               onClick={() => {
                  //                 if (!isReadOnlyCondition) {
                  //                   handleCalculations(
                  //                     item,
                  //                     "BUY",
                  //                     orderLotsCount
                  //                   );
                  //                 }
                  //               }}
                  //             >
                  //               BUY
                  //               {!isLimit && (
                  //                 <span
                  //                   id={"Modal_ask" + item.SymbolToken}
                  //                   className="text-[12px]"
                  //                 >
                  //                   {" "}
                  //                   @ {item.buy}
                  //                 </span>
                  //               )}
                  //             </div>
                  //           </div>
                  //         )}

                  //         {isLimit && (
                  //           <div className="flex justify-between mt-[5px]">
                  //             <div className="bg-[#EEEEEE] rounded-[5px] flex justify-center items-center w-[48%] h-[30px]">
                  //               <span className="text-[#000] text-[11px] font-[500] tracking-[0.04em]">
                  //                 Bid
                  //               </span>
                  //               <span
                  //                 className="text-[#000] text-[12px] font-[500] tracking-[0.04em]"
                  //                 id={"Modal_bid" + item.SymbolToken}
                  //               >
                  //                 {" "}
                  //                 @ {item.sell}
                  //               </span>
                  //             </div>
                  //             <div className="bg-[#EEEEEE] rounded-[5px] flex justify-center items-center w-[48%] h-[30px]">
                  //               <span className="text-[#000] text-[11px] font-[500] tracking-[0.04em]">
                  //                 Ask
                  //               </span>
                  //               <span
                  //                 className="text-[#000] text-[12px] font-[500] tracking-[0.04em]"
                  //                 id={"Modal_ask" + item.SymbolToken}
                  //               >
                  //                 {" "}
                  //                 @ {item.buy}
                  //               </span>
                  //             </div>
                  //           </div>
                  //         )}
                  //         <div className="flex justify-between mt-[5px] ">
                  //           <div className="bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[24%] h-[50px]">
                  //             <span className="text-[#000] text-[11px] font-[500] tracking-[0.04em]">
                  //               Open
                  //             </span>
                  //             <span
                  //               id={"Modal_open" + item.SymbolToken}
                  //               className="text-[#000] text-[12px] font-[500] tracking-[0.04em]"
                  //             >
                  //               {item.open_}
                  //             </span>
                  //           </div>

                  //           <div className="bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[24%] h-[50px]">
                  //             <span className="text-[#000] text-[11px]  font-[500] tracking-[0.04em]">
                  //               Close
                  //             </span>
                  //             <span
                  //               id={"Modal_close" + item.SymbolToken}
                  //               className="text-[#000] text-[12px] font-[500] tracking-[0.04em]"
                  //             >
                  //               {item.close_}
                  //             </span>
                  //           </div>

                  //           <div className="bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[24%] h-[50px]">
                  //             <span className="text-[#000] text-[11px] font-[500] tracking-[0.04em]">
                  //               High
                  //             </span>
                  //             <span
                  //               id={"Modal_high" + item.SymbolToken}
                  //               className="text-[#000] text-[12px]  font-[500] tracking-[0.04em]"
                  //             >
                  //               {item.high_}
                  //             </span>
                  //           </div>

                  //           <div className="bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[24%] h-[50px]">
                  //             <span className="text-[#000] text-[11px] font-[500] tracking-[0.04em]">
                  //               Low
                  //             </span>
                  //             <span
                  //               id={"Modal_low" + item.SymbolToken}
                  //               className="text-[#000] text-[12px]  font-[500] tracking-[0.04em]"
                  //             >
                  //               {item.low_}
                  //             </span>
                  //           </div>
                  //         </div>

                  //         <div className="flex justify-between mt-[3px] pb-[5px]">
                  //           <div className="bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[24%] h-[50px]">
                  //             <span className="text-[#000] text-[11px] font-[500] tracking-[0.04em]">
                  //               LTP
                  //             </span>
                  //             <span
                  //               id={"Modal_last_price" + item.SymbolToken}
                  //               className="text-[#000] text-[12px] font-[500] tracking-[0.04em]"
                  //             >
                  //               {item.last_price}
                  //             </span>
                  //           </div>

                  //           <div className="bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[24%] h-[50px]">
                  //             <span className="text-[#000] text-[11px]  font-[500] tracking-[0.04em]">
                  //               Change
                  //             </span>
                  //             <span
                  //               id={"Modal_change" + item.SymbolToken}
                  //               className="text-[#000] text-[12px] font-[500] tracking-[0.04em]"
                  //             >
                  //               {item.change}
                  //             </span>
                  //           </div>

                  //           <div className="bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[22%]   h-[50px]">
                  //             <span className="text-[#000] text-[11px] font-[500] tracking-[0.04em]">
                  //               Volume
                  //             </span>
                  //             <span
                  //               id={"Modal_volume" + item.SymbolToken}
                  //               className="text-[#000] text-[12px] font-[500] tracking-[0.04em]"
                  //             >
                  //               {item.volume}
                  //             </span>
                  //           </div>

                  //           <div className="bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[24%] h-[50px]">
                  //             <span className="text-[#000] text-[11px] font-[500] tracking-[0.04em]">
                  //               Lot Size
                  //             </span>
                  //             <span className="text-[#000] text-[12px] font-[500] tracking-[0.04em]">
                  //               {item.Lotsize}
                  //             </span>
                  //           </div>
                  //         </div>
                  //       </React.Fragment>
                  //     )
                  //   );
                  // }
                  // else {
                    return (
                      item.SymbolToken === modalId && (
                        <React.Fragment key={item.SymbolToken}>
                          <div className="flex justify-around mt-[10px]">
                            <div className="flex flex-col w-[50%] justify-center pt-[3px] pb-[3px] border border-gray-300 border-t-1.5 border-solid items-center">
                              <span className="text-[#383838] text-[12px] font-[700]">
                                Ledger Balance
                              </span>
                              <span className="text-[#383838] text-[13px] font-[700]">
                                {parseInt(ledgerBalance)}
                              </span>
                            </div>
                            <div className="flex flex-col   w-[50%] justify-center pt-[3px] pb-[3px] border border-gray-300 border-t-1.5 border-solid justify-center items-center text-center">
                              <span className="text-[#383838] text-[12px] font-[700]">
                                Margin Available
                              </span>
                              <span
                                className="text-[#383838] text-[13px] font-[700]"
                                id="modal_marginBalance"
                              >
                                {parseInt(ledgerBalance)}
                              </span>
                            </div>
                          </div>
                          <div className="flex justify-around ">
                            <div className="flex flex-col w-[50%] justify-center pt-[3px] pb-[3px] border border-gray-300 border-t-1.5 border-solid items-center">
                              <span className="text-[#383838] text-[12px] font-[700]">
                                Active P/L
                              </span>
                              <span
                                className="text-[#383838] text-[13px] font-[700]"
                                id="modal_ActivePL"
                              >
                                0
                              </span>
                            </div>
                            <div className="flex flex-col w-[50%] justify-center pt-[3px] pb-[3px] border border-gray-300 border-t-1.5 border-solid justify-center items-center text-center">
                              <span className="text-[#383838] text-[12px] font-[700]">
                                M2M
                              </span>
                              <span
                                className="text-[#383838] text-[13px] font-[700]"
                                id="m2mEquity"
                              >
                                {parseInt(ledgerBalance)}
                              </span>
                            </div>
                          </div>
                          <div className="flex justify-around mr-[31px] ml-[31px] pt-[3px] pb-[3px] mt-[5px] mb-[5px]  cursor-pointer rounded-[10px]">
                            <span className="text-[#383838] text-[12px] font-[700]">
                              {item.SymbolName}
                            </span>
                          </div>
                          <div className="flex justify-around bg-[#EEEEEE]  pt-[3px] pb-[3px] mb-[5px] cursor-pointer rounded-[10px]">
                            {!redirectFromPending && (
                              <div
                                className={`tracking-[0.04em] text-[#707E89] text-[12px] font-[700] ${
                                  isLimit ? "" : "text-[#ff0000]"
                                }`}
                                onClick={() => setIsLimit(false)}
                              >
                                Market
                              </div>
                            )}
                            <div
                              className={`tracking-[0.04em] text-[#707E89] text-[12px] font-[700] ${
                                isLimit ? "text-[#ff0000]" : ""
                              }`}
                              onClick={() => setIsLimit(true)}
                            >
                              Limit
                            </div>
                          </div>
                          <div className=" flex justify-between h-[35px] mt-[3px]  mb-[3px] items-center">
                            <span className="text-[#707E89] text-[11px] font-[500]">
                              Lots
                            </span>
                            <div className="flex justify-between  items-center w-[160px] bg-[#EEEEEE] pl-[12px] pr-[12px]  rounded-[10px]">
                              <span
                                className={`text-[#011C3A] text-[12px] font-[700] cursor-pointer ${
                                  lotsCount === 1 ? "" : ""
                                }`}
                                onClick={(e) => {
                                  calculateLots(
                                    e,
                                    "minus",
                                    item.SymbolName,
                                    item.Lotsize,
                                    item.SymbolToken,
                                    item.ExchangeType
                                  );
                                }}
                              >
                                -
                              </span>
                              <span className="text-[#011C3A] text-[12px] font-[700] w-[65px] text-center">
                                {lotsCount}
                              </span>
                              <span
                                className="text-[#011C3A] text-[14px] font-[700] cursor-pointer"
                                onClick={(e) =>
                                  calculateLots(
                                    e,
                                    "plus",
                                    item.SymbolName,
                                    item.Lotsize,
                                    item.SymbolToken,
                                    item.ExchangeType
                                  )
                                }
                              >
                                +
                              </span>
                            </div>
                          </div>
                          <input
                            className="mt-[3px] rounded-[5px] border border-solid border-1 border-[#011C3A] mb-[3px] w-[100%] pt-[3px] pb-[3px] pl-[12px] text-[#383838] text-[11px] font-[500]"
                            value={lotsCount}
                            maxLength={5}
                            onChange={(e) => {
                              calculateLots(
                                e,
                                "input",
                                item.SymbolName,
                                item.Lotsize,
                                item.SymbolToken,
                                item.ExchangeType
                              );
                            }}
                          />
                          {!isLimit && (
                            <div className="text-center w-full mb-[3px]">
                              <span id={`mrgval${item.SymbolToken}`}></span>
                            </div>
                          )}
                          {isLimit && (
                            <>
                              <div className=" flex justify-between mt-[3px] mb-[3px] items-center">
                                <span className="text-[#707E89] text-[11px] font-[500]">
                                  Prices
                                </span>
                              </div>
                              <input
                                className="mt-[3px]  appearance-none rounded-[5px] border border-solid border-1 border-[#011C3A] mb-[3px] w-[100%]  pt-[3px] pb-[3px] pl-[12px] text-[#383838] text-[11px] font-[500]"
                                onKeyPress={handleKeyPress}
                                onChange={(e) => {
                                  handleInputChange(e);
                                }}
                                value={orderLotsCount}
                                placeholder="0"
                                type="number"
                                maxLength={5}
                              />
                            </>
                          )}

                          <hr className="h-[2px] w-[95%] mx-auto bg-[#CCCCCC]" />
                          {redirectFromPending ? (
                            <div
                              className={`flex justify-around mt-[3px] pt-[3px] pb-[3px] cursor-pointer ${
                                isLimit ? "mb-[3px]" : "mb-[3px]"
                              }`}
                            >
                              <div
                                className={`flex justify-center items-center tracking-[0.04em] text-[#fff] pt-[3px] pb-[3px] ${
                                  pendingData?.method === "BUY"
                                    ? "bg-[#119832]"
                                    : "bg-[#EE2D26]"
                                } bg-[#119832] w-full h-full text-center text-[11px] font-[700] rounded-[10px]`}
                                onClick={() => {
                                  if (!isReadOnlyCondition) {
                                    handleCalculations(
                                      item,
                                      pendingData?.method === "BUY"
                                        ? "BUY"
                                        : "SELL",
                                      orderLotsCount
                                    );
                                  }
                                }}
                              >
                                {pendingData?.method}
                              </div>
                            </div>
                          ) : (
                            <div
                              className={`flex justify-around mt-[3px] pt-[3px] pb-[3px] cursor-pointer ${
                                isLimit ? "mb-[3px]" : "mb-[3px]"
                              }`}
                            >
                              <div
                                className="flex justify-center items-center tracking-[0.04em] text-[#fff] pt-[3px] pb-[3px] text-[12px] w-full h-full text-center font-[700] bg-[#EE2D26] rounded-l-[10px]"
                                onClick={() => {
                                  if (!isReadOnlyCondition) {
                                    handleCalculations(
                                      item,
                                      "SELL",
                                      orderLotsCount
                                    );
                                  }
                                }}
                              >
                                SELL
                                {!isLimit && (
                                  <span
                                    id={"Modal_bid" + item.SymbolToken}
                                    className="text-[12px]"
                                  >
                                    {" "}
                                    @ {item.sell}
                                  </span>
                                )}
                              </div>

                              <div
                                className="flex justify-center items-center tracking-[0.04em] text-[#fff] pt-[3px] pb-[3px] bg-[#119832] w-full h-full text-center text-[12px] font-[700] rounded-r-[10px]"
                                onClick={() => {
                                  if (!isReadOnlyCondition) {
                                    handleCalculations(
                                      item,
                                      "BUY",
                                      orderLotsCount
                                    );
                                  }
                                }}
                              >
                                BUY
                                {!isLimit && (
                                  <span
                                    id={"Modal_ask" + item.SymbolToken}
                                    className="text-[12px]"
                                  >
                                    {" "}
                                    @ {item.buy}
                                  </span>
                                )}
                              </div>
                            </div>
                          )}

                          {isLimit && (
                            <div className="flex justify-between mt-[3px]">
                              <div className="bg-[#EEEEEE] rounded-[5px] flex justify-center items-center w-[48%] h-[30px]">
                                <span className="text-[#000] text-[12px] font-[500] tracking-[0.04em]">
                                  Bid
                                </span>
                                <span
                                  className="text-[#000] text-[12px] font-[500] tracking-[0.04em]"
                                  id={"Modal_bid" + item.SymbolToken}
                                >
                                  {" "}
                                  @ {item.sell}
                                </span>
                              </div>
                              <div className="bg-[#EEEEEE] rounded-[5px] flex justify-center items-center w-[48%] h-[30px]">
                                <span className="text-[#000] text-[12px] font-[500] tracking-[0.04em]">
                                  Ask
                                </span>
                                <span
                                  className="text-[#000] text-[12px] font-[500] tracking-[0.04em]"
                                  id={"Modal_ask" + item.SymbolToken}
                                >
                                  {" "}
                                  @ {item.buy}
                                </span>
                              </div>
                            </div>
                          )}
                          <div className="flex justify-between mt-[5px] ">
                            <div className="bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[24%] h-[50px]">
                              <span className="text-[#000] text-[11px] font-[500] tracking-[0.04em]">
                                Open
                              </span>
                              <span
                                id={"Modal_open" + item.SymbolToken}
                                className="text-[#000] text-[12px] font-[500] tracking-[0.04em]"
                              >
                                {item.open_}
                              </span>
                            </div>

                            <div className="bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[24%] h-[50px]">
                              <span className="text-[#000] text-[11px]  font-[500] tracking-[0.04em]">
                                Close
                              </span>
                              <span
                                id={"Modal_close" + item.SymbolToken}
                                className="text-[#000] text-[12px] font-[500] tracking-[0.04em]"
                              >
                                {item.close_}
                              </span>
                            </div>

                            <div className="bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[24%] h-[50px]">
                              <span className="text-[#000] text-[11px] font-[500] tracking-[0.04em]">
                                High
                              </span>
                              <span
                                id={"Modal_high" + item.SymbolToken}
                                className="text-[#000] text-[12px]  font-[500] tracking-[0.04em]"
                              >
                                {item.high_}
                              </span>
                            </div>

                            <div className="bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[24%] h-[50px]">
                              <span className="text-[#000] text-[11px] font-[500] tracking-[0.04em]">
                                Low
                              </span>
                              <span
                                id={"Modal_low" + item.SymbolToken}
                                className="text-[#000] text-[12px]  font-[500] tracking-[0.04em]"
                              >
                                {item.low_}
                              </span>
                            </div>
                          </div>

                          <div className="flex justify-between mt-[5px] pb-[5px]">
                            <div className="bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[24%] h-[50px]">
                              <span className="text-[#000] text-[11px] font-[500] tracking-[0.04em]">
                                LTP
                              </span>
                              <span
                                id={"Modal_last_price" + item.SymbolToken}
                                className="text-[#000] text-[12px] font-[500] tracking-[0.04em]"
                              >
                                {item.last_price}
                              </span>
                            </div>

                            <div className="bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[24%] h-[50px]">
                              <span className="text-[#000] text-[11px]  font-[500] tracking-[0.04em]">
                                Change
                              </span>
                              <span
                                id={"Modal_change" + item.SymbolToken}
                                className="text-[#000] text-[12px] font-[500] tracking-[0.04em]"
                              >
                                {item.change}
                              </span>
                            </div>

                            <div className="bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[22%]   h-[50px]">
                              <span className="text-[#000] text-[11px] font-[500] tracking-[0.04em]">
                                Volume
                              </span>
                              <span
                                id={"Modal_volume" + item.SymbolToken}
                                className="text-[#000] text-[12px] font-[500] tracking-[0.04em]"
                              >
                                {item.volume}
                              </span>
                            </div>

                            <div className="bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[24%] h-[50px]">
                              <span className="text-[#000] text-[11px] font-[500] tracking-[0.04em]">
                                Lot Size
                              </span>
                              <span className="text-[#000] text-[12px] font-[500] tracking-[0.04em]">
                                {item.Lotsize}
                              </span>
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    );
                 // }
                })}
              </div>
            </div>
          </div>

          {/* trades */}
          <div
            className={` ${
              globalActiveTab === "trades" ? "flex" : "hidden"
            } flex-col h-[100vh] w-[100%] fixed top-0 bg-[#fff] pb-100  `}
          >
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(1, 28, 58, 0.8) 0%, #011C3A 41.38%)",
              }}
              className="h-[49px] flex justify-center pt-[3px] text-[24px] text-[#fff] font-[700] pb-[8px] "
            >
              Trades
            </div>
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(1, 28, 58, 0.8) 0%, #011C3A 41.38%)",
              }}
              className="flex justify-around pt-[6px] pb-[6px]"
            >
              <div
                className={`w-[33%] text-[16px] ${
                  activeTab === "Pending" ? "text-[#fff]" : "text-[#707e89]"
                }  md:text-[20px] flex items-center  flex-col font-[500] cursor-pointer`}
                onClick={() => setActiveTab("Pending")}
              >
                Pending
                <hr
                  className={`md:w-[100px] w-[70px] h-[4px] bg-[#fff]  ${
                    activeTab === "Pending" ? "show" : "hidden"
                  }`}
                />
              </div>
              <div
                className={`w-[33%] text-[16px]  ${
                  activeTab === "Active" ? "text-[#fff]" : "text-[#707e89]"
                } md:text-[20px] flex items-center  flex-col font-[500] cursor-pointer `}
                onClick={() => setActiveTab("Active")}
              >
                Active
                <hr
                  className={`md:w-[100px] w-[60px] h-[4px] bg-[#fff] ${
                    activeTab === "Active" ? "show" : "hidden"
                  }`}
                />
              </div>
              <div
                className={`w-[33%] text-[16px]  ${
                  activeTab === "Closed" ? "text-[#fff]" : "text-[#707e89]"
                }  md:text-[20px] flex items-center  flex-col font-[500] cursor-pointer`}
                onClick={() => setActiveTab("Closed")}
              >
                Closed
                <hr
                  className={`md:w-[100px] w-[60px] h-[4px] bg-[#fff]  ${
                    activeTab === "Closed" ? "show" : "hidden"
                  }`}
                />
              </div>
            </div>
            <div className="w-full h-[100%] flex mb-[65px]  ">
              <div
                className={` w-[100%]   h-[100%] pl-[13px] pr-[10px] ${
                  activeTab === "Pending" ? "block" : "hidden"
                }`}
              >
                <div className="flex mt-3 mb-4">
                  <div
                    className="w-1/2 mx-2 bg-[#bf3b49] text-[#fff] text-[12px] px-2 py-2 rounded flex justify-center flex-col items-center font-[500] cursor-pointer"
                    onClick={() => {
                      dispatch(getMarketTimeData("MCX", user?.refId));
                      if (!marketClose) {
                        setExchangeWord("MCX");
                      } else {
                        setMarketModalOpen(true);
                      }
                    }}
                  >
                    <span>Cancel Pending</span>
                    <span>Orders MCX</span>
                  </div>
                  <div
                    className="w-1/2 mx-2 bg-[#bf3b49] text-[#fff] text-[12px] px-2 py- rounded flex justify-center flex-col items-center font-[500] cursor-pointer"
                    onClick={() => {
                      dispatch(getMarketTimeData("NSE", user?.refId));
                      if (!marketClose) {
                        dispatch(getMarketTimeData("NSE", user?.refId));
                        setExchangeWord("NSE");
                      } else {
                        setMarketModalOpen(true);
                      }
                    }}
                  >
                    <span>Cancel Pending</span>
                    <span>Orders Equity</span>
                  </div>
                </div>
                <div className="h-[100%] pb-[235px] overflow-y-scroll pr-3 pl-3">
                  {tradeData?.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="pt-[15px] ">
                          <div className="flex justify-between">
                            <span>
                              <span className="bg-[#11983233] rounded-[5px] p-[5px] text-[#119832] text-[13px] font-[700] mr-[5px]">
                                {item.OrderCategory} X {item.Lot}
                              </span>
                              <span className="bg-[#BBDEFB] rounded-[5px] p-[5px] text-[#2196F3] text-[13px] font-[700]">
                                {item.OrderType}
                              </span>
                            </span>
                            <span className="bg-[#2196F433] rounded-[5px] p-[5px] text-[#2196F4] text-[13px] font-[700]">
                              PENDING
                            </span>
                          </div>

                          <div className="flex justify-between items-center mt-[7px]">
                            <span className="text-[#383838] text-[16px] font-[700]">
                              {item.ScriptName}
                            </span>
                            <span className="text-[#383838] text-[13px] font-[700]">
                              {item.OrderPrice}
                            </span>
                          </div>

                          <div className="flex justify-between mt-[7px]">
                            <span className="text-[#383838] text-[13px] font-[400]">
                              {item.ActionType}
                            </span>
                            <span className="text-[#383838] text-[13px] font-[500]">
                              {item.OrderDate}, {item.OrderTime}
                            </span>
                          </div>

                          <div className="flex justify-between mt-[7px] items-center">
                            <span className="text-[#383838] text-[13px] font-[400]">
                              Margin Req.{" "}
                              <span className="font-[700]">
                                {item.MarginUsed}
                              </span>
                            </span>
                            {item.OrderStatus === "Pending" && (
                              <span>
                                <EditOutlined
                                  className="text-[22px] mx-1  cursor-pointer"
                                  onClick={() => {
                                    dispatch(
                                      getMarketTimeData(
                                        item.SymbolType,
                                        user?.refId
                                      )
                                    );
                                    if (!marketClose) {
                                      dispatch(
                                        getMarketTimeData(
                                          item.SymbolType,
                                          user?.refId
                                        )
                                      );
                                      setRedirectFromPending(true);
                                      setPendingData({
                                        id: item.Id,
                                        symbolType: item.SymbolType,
                                        method: item.OrderCategory,
                                        price: item.OrderPrice,
                                        lots: item.Lot,
                                      });
                                      setModalId(item.TokenNo);
                                    } else {
                                      setMarketModalOpen(true);
                                    }
                                  }}
                                />
                                <DeleteOutlined
                                  className="text-[22px] mx-1  cursor-pointer"
                                  onClick={() => {
                                    if (!marketClose) {
                                      setDeleteTradeModalOpen(true);
                                      setDeleteId(item.Id);
                                    } else {
                                      setMarketModalOpen(true);
                                    }
                                  }}
                                />
                              </span>
                            )}
                            {item.OrderStatus === "Cancel" && (
                              <button
                                disabled={true}
                                className="bg-[#EE2D26] text-[#fff] text-[13px] font-[700] p-[5px] tracking-[0.04em] rounded-[5px]"
                              >
                                CANCELED
                              </button>
                            )}
                            {item.OrderStatus === "Active" && (
                              <button
                                disabled={true}
                                className="bg-[#119832] text-[#fff] text-[13px] font-[700] p-[5px] tracking-[0.04em] rounded-[5px]"
                              >
                                COMPLETE
                              </button>
                            )}
                          </div>
                        </div>
                        <hr className="w-full h-[1.5px] bg-[#D6DFE8] mt-[15px] " />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
              <hr className="w-[1px] h-[100%] bg-[#011C3A] hidden " />
              <div
                className={`w-[100%] pl-[13px] h-[100%] pr-[10px]  ${
                  activeTab === "Active" ? "block" : "hidden"
                }`}
              >
                <div className="flex mt-3 mb-4">
                  <div
                    className="w-1/2 mx-2 bg-[#bf3b49] text-[#fff] text-[12px] px-2 py-2 rounded flex flex-col justify-center items-center font-[500] cursor-pointer"
                    onClick={() => {
                      dispatch(getMarketTimeData("MCX", user?.refId));
                      if (!marketClose) {
                        handleFlag("MCX");
                        setExchangeWord("MCX");
                      } else {
                        setMarketModalOpen(true);
                      }
                    }}
                  >
                    <span>Close Active Trades</span>
                    <span>MCX</span>
                  </div>
                  <div
                    className="w-1/2 mx-2 bg-[#bf3b49] text-[#fff] text-[12px] px-2 py-2 rounded flex flex-col justify-center items-center font-[500] cursor-pointer"
                    onClick={() => {
                      dispatch(getMarketTimeData("NSE", user?.refId));
                      if (!marketClose) {
                        handleFlag("NSE");
                        setExchangeWord("NSE");
                      } else {
                        setMarketModalOpen(true);
                      }
                    }}
                  >
                    <span>Close Active Trades</span>
                    <span>Equity</span>
                  </div>
                </div>
                <div className="h-[100%] pb-[235px] overflow-y-scroll pr-3 pl-3">
                  {tradeData?.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <div className="pt-[15px] ">
                          <div className="flex justify-between items-center">
                            <span>
                              <span
                                className={` ${
                                  item.OrderCategory === "BUY"
                                    ? "bg-[#11983233] text-[#119832]"
                                    : "bg-[#ffcdd2] text-[#f44336]"
                                } rounded-[5px] p-[5px] text-[13px] font-[700] mr-[5px]`}
                              >
                                {item.OrderCategory} X {item.Lot}
                              </span>
                              {item.OrderType && (
                                <span className="bg-[#11983233] rounded-[5px] p-[5px] text-[#119832] text-[13px] font-[700]">
                                  {item.OrderType}
                                </span>
                              )}
                            </span>
                            <span className="text-[#383838] text-[13px] font-[500]">
                              {formatData(item.OrderDate)}, {item.OrderTime}
                            </span>
                          </div>

                          <div className="flex justify-between items-center mt-[7px]">
                            <div className="flex flex-col h-[45px] w-[56%]">
                              <span
                                className="font-[600] text-[15px] "
                                style={{ wordBreak: "break-all" }}
                              >
                                {splitforExchangeName(item.ScriptName)}
                              </span>
                              <span className="font-[500] text-[13px]">
                                {splitforDate(item.ScriptName)}
                              </span>
                            </div>
                            <div className="w-[44%] text-right">
                              <span className="text-[#383838] text-[13px] font-[700]">
                                {item.OrderPrice}
                              </span>
                            </div>
                          </div>

                          <div className="flex justify-between mt-[7px] items-center">
                            <span className="text-[#383838] text-[13px] font-[400]">
                              {item.ActionType}
                            </span>
                            <button
                              className="bg-[#EE2D26] text-[#fff] text-[13px] font-[700] p-[5px] tracking-[0.04em] rounded-[5px]"
                              onClick={() => {
                                dispatch(
                                  getMarketTimeData(
                                    item?.SymbolType,
                                    user?.refId
                                  )
                                );
                                if (!marketClose) {
                                  setPendingData({
                                    id: item.Id,
                                    symbolType: item.SymbolType,
                                    method: item.OrderCategory,
                                  });
                                  setModalId(item.TokenNo);
                                } else {
                                  setMarketModalOpen(true);
                                }
                              }}
                            >
                              Close Order
                            </button>
                          </div>

                          <div className="flex justify-between mt-[7px] items-center">
                            <span className="text-[#383838] text-[13px] font-[400]">
                              Margin Req.{" "}
                              <span className="font-[700]">
                                {parseInt(item.MarginUsed)}
                              </span>
                            </span>
                            <span className="text-[#383838] text-[13px] font-[400]">
                              Holding Margin Req.{" "}
                              <span className="font-[700]">
                                {parseInt(item.HoldingMarginReq)}
                              </span>
                            </span>
                          </div>
                        </div>
                        <hr className="w-full h-[1.5px] bg-[#D6DFE8] mt-[15px]" />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
              <CloseOrderModal
                closeOrderState={closeOrderState}
                closeOrderStateFun={closeOrderStateFun}
                modalId={modalId}
                pendingData={pendingData}
                mcxModalData={mcxModalData}
                nseModalData={nseModalData}
                optModalData={optModalData}
                setModalDataFun={setModalDataFun}
                setPendingDataFun={setPendingDataFun}
                isExpired={isExpired}
                activeTradeCloseData={activeTradeCloseData}
              />

              <div
                className={`w-[100%] pl-[13px] pr-[10px] h-[100%] pb-[165px] overflow-y-scroll ${
                  activeTab === "Closed" ? "block" : "hidden"
                }`}
              >
                {tradeData?.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="pt-[15px] ">
                        <div className="flex justify-between items-center">
                          <div className="flex flex-col h-[45px] w-[56%]">
                            <span
                              className="font-[600] text-[15px] "
                              style={{ wordBreak: "break-all" }}
                            >
                              {splitforExchangeName(item.ScriptName)}
                            </span>
                            <span className="font-[500] text-[13px]">
                              {splitforDate(item.ScriptName)}
                            </span>
                          </div>

                          <span className="flex flex-wrap justify-end">
                            <span className="bg-[#EE2D2633] rounded-[5px] p-[5px] mb-[2px] text-[#EE2D26] text-[13px] font-[700] mr-[5px]">
                              {parseInt(item.P_L)}/{parseInt(item.Brokerage)}
                            </span>
                            <span className="bg-[#EE2D2633] rounded-[5px] p-[5px] mb-[2px] text-[#EE2D26] text-[13px] font-[700]">
                              QTY: {item.Lot}
                            </span>
                          </span>
                        </div>

                        <div className="flex justify-between items-center mt-[7px]">
                          <span className="flex items-center  flex-wrap">
                            <span className="text-[#383838] text-[11px] font-[400] mr-[5px]">
                              {item.ActionType}
                            </span>
                            <span className="bg-[#EE2D2633] rounded-[5px] p-[5px] text-[#EE2D26] text-[13px] font-[700]">
                              {item.OrderPrice}
                            </span>
                          </span>

                          <span className="flex items-center  flex-wrap justify-end">
                            <span className="text-[#383838] text-[11px] font-[400]  mr-[5px]">
                              {item.ActionByClose}
                            </span>
                            <span className="bg-[#11983233] rounded-[5px] p-[5px] text-[#119832] text-[13px] font-[700]">
                              {item.BroughtBy}
                            </span>
                          </span>
                        </div>

                        <div className="flex justify-between mt-[7px] items-center">
                          <span className="flex items-center  flex-wrap">
                            <span className="text-[#383838] text-[13px] font-[500]  mr-[5px]">
                              {item.OrderDate}, {item.OrderTime}
                            </span>
                            <span className="bg-[#11983233] rounded-[5px] p-[5px] text-[#119832] text-[13px] font-[700]">
                              {item.OrderType}
                            </span>
                          </span>
                          <span className="flex flex-wrap justify-end">
                            <span className="text-[#383838] text-[13px] font-[500]  mr-[5px]">
                              {item.ClosedAt} {item.ClosedTime}
                            </span>
                            <button className="bg-[#2196F433] rounded-[5px] text-[#2196F4] text-[13px] font-[700] p-[5px] tracking-[0.04em] rounded-[5px]">
                              {item.OrderTypeClose}
                            </button>
                          </span>
                        </div>
                      </div>
                      <hr className="w-full h-[1.5px] bg-[#D6DFE8] mt-[15px]" />
                    </React.Fragment>
                  );
                })}
              </div>
              <PasswordModal
                passwordModalOpen={passwordModalOpen}
                passwordModalOpenFun={passwordModalOpenFun}
                passwordNotCorrectModalOpen={passwordNotCorrectModalOpen}
                passwordNotCorrectModalFun={passwordNotCorrectModalFun}
                checkUserPassword={checkUserPassword}
                word={exchangeWord}
                setDefaultExchangeWord={setDefaultExchangeWord}
                activeTab={activeTab}
              />
              <ConfirmModal
                confirmModalOpen={confirmModalOpen}
                confirmModalFun={confirmModalFun}
                user={user}
                exchangeWord={exchangeWord}
                setDefaultExchangeWord={setDefaultExchangeWord}
                activeTab={activeTab}
                mcxnseActiveTradePostData={mcxnseActiveTradePostData}
              />
              <TradeCloseFromAcitveTabModal
                activeTradeCloseModalOpen={activeTradeCloseModalOpen}
                closeActiveTradeModal={closeActiveTradeModal}
                activeTradeCloseDataState={activeTradeCloseDataState}
                makeActiveTradeCloseDataState={makeActiveTradeCloseDataState}
                user={user}
              />
            </div>
          </div>

          {/* portfolio Page */}
          <div
            className={` ${
              globalActiveTab === "portfolio" ? "flex" : "hidden"
            } flex-col fixed top-0 h-[100vh] fixed top-0 w-[100%] bg-[#fff]`}
          >
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(1, 28, 58, 0.8) 0%, #011C3A 41.38%)",
              }}
              className="h-[49px] flex justify-center pt-[3px] text-[24px] text-[#fff] font-[700] pb-[8px] "
            >
              Portfolio
            </div>
            <div
              style={{
                background:
                  "linear-gradient(180deg, rgba(1, 28, 58, 0.8) 0%, #011C3A 41.38%)",
              }}
              className="flex justify-around  pt-[6px] pb-[2px]"
            >
              <div
                className={`w-[50%] text-[16px ${
                  activeTab === "active" ? "text-[#fff]" : "text-[#707e89]"
                } flex items-center  flex-col font-[500] cursor-pointer`}
                onClick={() => setActiveTab("active")}
              >
                Active
                <hr
                  className={`md:w-[100px] w-[70px] h-[2px] bg-[#fff] ${
                    activeTab === "active" ? "show" : "hidden"
                  }`}
                />
              </div>
              <div
                className={`w-[50%] text-[16px] ${
                  activeTab === "closed" ? "text-[#fff]" : "text-[#707e89]"
                } flex items-center  flex-col font-[500] cursor-pointer`}
                onClick={() => setActiveTab("closed")}
              >
                Closed
                <hr
                  className={`md:w-[100px] w-[70px] h-[2px] bg-[#fff] ${
                    activeTab === "closed" ? "show" : "hidden"
                  }`}
                />
              </div>
            </div>
            <div className="w-full h-[100%] flex mb-[65px] ">
              <div
                className={` w-[100%] pl-[13px] h-[100%] pr-[10px] ${
                  activeTab === "active" ? "block" : "hidden"
                }`}
              >
                <div className="h-[154px] mt-[20px] w-full bg-[#fff] pt-[10px] pb-[15px] pl-[21px] pr-[21px] border-2 rounded-[15px] border-[#fff] shadow-lg">
                  <div className="h-[50%] flex justify-around">
                    <span className="flex flex-col items-center">
                      <span className="text-[#707E89] text-[16px] font-[500]">
                        Ledger Balance
                      </span>
                      <span className="text-[#383838] text-[16px] font-[700] ">
                        {!isExpired ? parseInt(ledgerBalance) : 0}
                      </span>
                    </span>
                    <span className="flex flex-col items-center">
                      <span className="text-[#707E89] text-[16px] font-[500]">
                        Margin Available
                      </span>
                      <span
                        className="text-[#383838] text-[16px] font-[700] "
                        id="marginBalance"
                      >
                        {!isExpired ? parseInt(ledgerBalance) : 0}
                      </span>
                    </span>
                  </div>
                  <hr />
                  <div className="h-[50%] flex justify-around pt-[10px]">
                    <span className="flex flex-col items-center">
                      <span className="text-[#707E89] text-[16px] font-[500]">
                        Active P/L
                      </span>
                      <span
                        className="text-[#383838] text-[16px] font-[700] "
                        id="activePL"
                      >
                        0
                      </span>
                    </span>
                    <span className="flex flex-col items-center">
                      <span className="text-[#707E89] text-[16px] font-[500]">
                        M2M
                      </span>
                      <span
                        className="text-[#383838] text-[16px] font-[700] "
                        id="m2mEquity"
                      >
                        {!isExpired ? parseInt(ledgerBalance) : 0}
                      </span>
                    </span>
                  </div>
                </div>
                <div className="mt-[20px] pr-[20px] ml-[10px] h-[100%] pb-[345px] mb-[10px] overflow-y-scroll">
                  {consolidatedTradeData.map((item) => {
                    return (
                      <React.Fragment key={item.TokenNo}>
                        <div className="mt-[10px] flex justify-between">
                          <span className="text-[#383838] text-[15px] font-[700]">
                            {item.ScriptName}
                          </span>
                          {item?.OrderCategory === "BUY" && (
                            <span className="text-[#31d260] font-[600] text-[13px] font-[400] ">
                              Buy:{" "}
                              <span className="text-[#31d260] font-[600]">
                                {item.Lot}
                              </span>{" "}
                              @{" "}
                              <span className="text-[#31d260] font-[600]">
                                {parseFloat(item.OrderPrice).toFixed(2)}
                              </span>
                            </span>
                          )}
                          {item?.OrderCategory === "SELL" && (
                            <span className="text-[#EE2D26] font-[600] text-[13px] font-[400] ">
                              Sold:{" "}
                              <span className="text-[#EE2D26] font-[600]">
                                {item.Lot}
                              </span>{" "}
                              @{" "}
                              <span className="text-[#EE2D26] font-[600]">
                                {parseFloat(item.OrderPrice).toFixed(2)}
                              </span>
                            </span>
                          )}
                        </div>
                        <div className="mt-[15px] flex justify-between">
                          <span className="text-[#383838] text-[13px] font-[400]">
                            Margin used{" "}
                            <span className="font-[700]">
                              {parseInt(item.MarginUsed)}
                            </span>
                          </span>
                          <span
                            className="text-[#119832] text-[13px] font-[700] "
                            id={`${"IndividualActivePl" + item.TokenNo}`}
                          >
                            0
                          </span>
                        </div>
                        <div className="mt-[15px] flex justify-between">
                          <span
                            className="text-[#fff] text-[13px] px-[7px] py-[4px] font-[700] bg-[#f00] rounded-[4px] cursor-pointer"
                            onClick={() => {
                              if (!marketClose) {
                                setCloseTradeData({
                                  userid: user?.userId,
                                  ordercat: item.OrderCategory,
                                  tokenno: item.TokenNo,
                                  cmpval: document.getElementById(
                                    `cmp${item.TokenNo}`
                                  ).innerHTML,
                                });
                                dispatch(
                                  getMarketTimeData(
                                    item?.SymbolType,
                                    user?.refId
                                  )
                                );
                              } else {
                                setMarketModalOpen(true);
                              }
                            }}
                          >
                            Close Trade
                          </span>
                          <span className="text-[#383838] text-[13px] font-[400]">
                            CMP{" "}
                            <span
                              className="font-[700]"
                              id={`${"cmp" + item.TokenNo}`}
                            >
                              0
                            </span>
                          </span>
                        </div>
                        <hr className="bg-[#d5ddd3] mt-[10px] h-[2px]" />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
              <div
                className={` w-[100%] pl-[13px] pr-[15px] ${
                  activeTab === "closed" ? "block" : "hidden"
                }`}
              >
                <div className="mr-[10px] ml-[10px] mt-[15px] flex justify-around mb-[20px] border-2 rounded-[15px] border-[#fff] shadow-lg pb-[8px] pt-[10px]">
                  <div className="flex flex-col justify-center items-center">
                    <span className="mb-[5px] text-[#707E89] font-[700] text-[16px]">
                      LedgerBalance
                    </span>
                    <span className="text-[#000] font-[700] text-[18px] items-center ">
                      {!isExpired ? parseInt(ledgerBalance) : 0}
                    </span>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <span className="mb-[5px] text-[#707E89] font-[700] text-[16px]">
                      Net Profit/Loss
                    </span>
                    <span className="text-[#EE2D26] font-[700] text-[18px] items-center">
                      {netplData.P_L === "" ? "0.00" : netplData.P_L}
                    </span>
                  </div>
                </div>
                <div className="h-[100%] overflow-y-scroll pb-[270px]">
                  {consolidatedOrderData?.map((item) => {
                    return (
                      <React.Fragment key={item.ScriptName}>
                        <div className="flex flex-col mt-[10px] mb-[10px] mx-3">
                          <div className="flex justify-between">
                            <span className="text-[#000] font-[700]">
                              {item.ScriptName}
                            </span>
                            <span>
                              <span>QTY: </span>
                              <span className="text-[#000] font-[600]">
                                {item.Lot}
                              </span>
                            </span>
                          </div>
                          <div></div>
                          <div className="flex justify-between mt-[3px]">
                            <span>
                              <span className="text-[000] font-[400] text-[16px]">
                                Avg Sell:{" "}
                              </span>
                              <span className="text-[000] font-[600] text-[16px]">
                                {item.OrderPrice}
                              </span>
                            </span>
                            <span>
                              <span className="text-[000] font-[400] text-[16px]">
                                Avg Buy:{" "}
                              </span>
                              <span className="text-[000] font-[600] text-[16px]">
                                {item.BroughtBy}
                              </span>
                            </span>
                          </div>
                          <div className="text-[16px] font-[400]  mt-[3px]">
                            Net Profilt/Loss:
                            <span
                              className={`${
                                item.P_L > 0
                                  ? "text-[#33D260]"
                                  : "text-[#ff0000]"
                              } text-[16px] font-[600]`}
                            >
                              {item.P_L > 0
                                ? "+" + parseInt(item.P_L)
                                : parseInt(item.P_L)}
                            </span>
                          </div>
                        </div>
                        <hr className="w-full h-[1.5px] bg-[#D6DFE8]" />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <CloseTradeModal
            closeTradeState={closeTradeState}
            closeTradeStateFun={closeTradeStateFun}
            closeTradeData={closeTradeData}
          />
          {/*  portfolio Page end */}

          {/* Account page */}
          {globalActiveTab === "account" && (
            <div className="bg-[#fff] h-[100vh] w-[100%]">
              <div className="flex flex-col h-[100%] w-[100%]">
                <div
                  style={{
                    background:
                      "linear-gradient(180deg, rgba(1, 28, 58, 0.8) 0%, #011C3A 41.38%)",
                  }}
                  className="h-[49px] flex justify-center pt-[3px] text-[24px] text-[#fff] font-[700]"
                >
                  ACCOUNT
                </div>
                <div className="flex h-[100%]">
                  <div className=" flex flex-col  w-[100%] h-[100%] pt-[15px] pl-[15px] pr-[15px]">
                    <div className=" w-full bg-[#fff] flex items-center pl-[15px] text-[20px] font-[700]">
                      Welcome, {user?.username}
                    </div>
                    <hr className="w-[95%] mt-[10px] mx-auto h-[1.5px] bg-[#D6DFE8] block" />
                    <div className="">
                      <div
                        className="h-[50px] flex items-center cursor-pointer "
                        onClick={() => {
                          dispatch(getUserLedgerBalance(user?.userId));
                          setFundsModalState(true);
                        }}
                      >
                        <img
                          src={Rupess}
                          alt=""
                          className="h-[24px] w-[24px]  mr-[14px] ml-[15px]"
                        />
                        <span className="text-[#383838] text-[16px] font-[500]">
                          Funds
                        </span>
                      </div>
                      <hr className=" w-[95%] mx-auto h-[1.5px] bg-[#D6DFE8]" />
                      <div
                        className="h-[50px] flex items-center cursor-pointer "
                        onClick={() => {
                          setBillInvoiceState(true);
                          dispatch(getUserBillData(user?.userId));
                        }}
                      >
                        <img
                          src={Printer}
                          alt="bill"
                          className="h-[24px] w-[24px]  mr-[14px] ml-[15px]"
                        />
                        <span className="text-[#383838] text-[16px] font-[500]">
                          Bills & Invoice
                        </span>
                      </div>
                      <hr className=" w-[95%] mx-auto h-[1.5px] bg-[#D6DFE8]" />
                      <div
                        className="h-[50px] flex items-center cursor-pointer "
                        onClick={() => {
                          dispatch(getProfileData(user?.userId));
                          setProfileState(true);
                        }}
                      >
                        <img
                          src={UserAcc}
                          alt=""
                          className="h-[24px] w-[24px]  mr-[14px] ml-[15px]"
                        />
                        <span className="text-[#383838] text-[16px] font-[500]">
                          Profile
                        </span>
                      </div>
                      <hr className=" w-[95%] mx-auto h-[1.5px] bg-[#D6DFE8]" />
                      <div
                        className="h-[50px] flex items-center cursor-pointer "
                        onClick={() => setRaiseComplaintState(true)}
                      >
                        <img
                          src={Headphone}
                          alt=""
                          className="h-[24px] w-[24px]  mr-[14px] ml-[15px]"
                        />
                        <span className="text-[#383838] text-[16px] font-[500]">
                          Raise Complaint
                        </span>
                      </div>
                      <hr className="w-[95%] mx-auto h-[1.5px] bg-[#D6DFE8]" />
                      <div
                        className="h-[50px] flex items-center cursor-pointer "
                        onClick={() => {
                          dispatch(
                            getNotificationData(user?.userId, user?.refId)
                          );
                          setNotificationstState(true);
                        }}
                      >
                        <img
                          src={Bell}
                          alt=""
                          className="h-[24px] w-[24px]  mr-[14px] ml-[15px]"
                        />
                        <span className="text-[#383838] text-[16px] font-[500]">
                          Notifications
                        </span>
                      </div>
                      <hr className="w-[95%]  mx-auto h-[1.5px] bg-[#D6DFE8]" />
                      <div
                        className="h-[50px] flex items-center cursor-pointer "
                        onClick={() => setChangePasswordState(true)}
                      >
                        <img
                          src={Lock}
                          alt=""
                          className="h-[24px] w-[24px]  mr-[14px] ml-[15px]"
                        />
                        <span className="text-[#383838] text-[16px] font-[500]">
                          Change Password
                        </span>
                      </div>
                      <hr className="w-[95%] h-[1.5px]  mx-auto bg-[#D6DFE8]" />
                      <div
                        className="h-[50px] flex items-center cursor-pointer"
                        onClick={userLogout}
                      >
                        <img
                          src={Out}
                          alt=""
                          className="h-[24px] w-[24px]  mr-[14px] ml-[15px]"
                        />
                        <span className="text-[#383838] text-[16px] font-[500]">
                          Logout
                        </span>
                      </div>
                      <hr className=" w-[95%] h-[1.5px]  mx-auto bg-[#D6DFE8]" />
                    </div>
                    <div className="flex justify-center items-center mt-[auto] text-[#383838] mb-[70px] text-[16px] font-[500] self-end w-[100%]">
                      Version 3.1.0
                    </div>
                  </div>
                </div>
              </div>
              {/* All modals used for Account Page */}
              <FundsModal
                fundsModalState={fundsModalState}
                fundsModalCloseFun={fundsModalCloseFun}
              />
              <BillModal
                billInvoiceState={billInvoiceState}
                billInvoiceStateFun={billInvoiceStateFun}
              />
              <ProfileModal
                profileState={profileState}
                profileStateFun={profileStateFun}
              />
              <NotificationModal
                notificationstState={notificationstState}
                notificationstStateFun={notificationstStateFun}
              />
              <ChangePasswordModal
                changePasswordState={changePasswordState}
                changePasswordStateFun={changePasswordStateFun}
                refreshData={refreshData}
                user={user}
              />
              <RaiseCompliantModal
                raiseComplaintState={raiseComplaintState}
                raiseComplaintStateFun={raiseComplaintStateFun}items-center
              />
            </div>
          )}
          {/* Account page end */}

          {/* side bar code, only showing once the main app opening screen is disable */}
          {!isShown && (
            <div className="flex items-center justify-around fixed bottom-0 left-0 w-full h-16 bg-[#01162e]">
              <div
                className="flex items-center flex-col cursor-pointer"
                onClick={() => {
                  setGlobalActiveTab("watchlist");
                  setActiveTab("mcx");
                }}
              >
                {/* setting highlighted images if tab is active */}
                {globalActiveTab === "watchlist" ? (
                  <img
                    src={WatchListIconActive}
                    alt="watchlist-icon"
                    className="w-[24px] h-[24px] mb-[2px]"
                  />
                ) : (
                  <img
                    src={WatchListIcon}
                    alt="watchlist-icon"
                    className="w-[24px] h-[24px] mb-[2px]"
                  />
                )}
                <span
                  className={`${
                    globalActiveTab === "watchlist"
                      ? "text-[#fff]"
                      : "text-[#707E89]"
                  } text-[14px] font-[500]`}
                >
                  Watchlist
                </span>
              </div>

              <div
                className="flex items-center flex-col cursor-pointer"
                onClick={() => {
                  dispatch({ type: GET_MARKET_TIME_RESET, data: "" });
                  setGlobalActiveTab("trades");
                  setActiveTab("Active");
                }}
              >
                {/* setting highlighted images if tab is active */}
                {globalActiveTab === "trades" ? (
                  <img
                    src={TradeArrowActive}
                    alt="trade-icon"
                    className="w-[24px] h-[24px] mb-[2px]"
                  />
                ) : (
                  <img
                    src={TradesIcon}
                    alt="trade-icon"
                    className="w-[24px] h-[24px] mb-[2px]"
                  />
                )}
                <span
                  className={`${
                    globalActiveTab === "trades"
                      ? "text-[#fff]"
                      : "text-[#707E89]"
                  } text-[14px] font-[500]`}
                >
                  Trades
                </span>
              </div>

              <div
                className="flex items-center flex-col cursor-pointer"
                onClick={() => {
                  dispatch({ type: GET_MARKET_TIME_RESET, data: "" });
                  setGlobalActiveTab("portfolio");
                  setActiveTab("active");
                }}
              >
                {/* setting highlighted images if tab is active */}
                {globalActiveTab === "portfolio" ? (
                  <img
                    src={PortfolioIconActive}
                    alt="portfolio-icon"
                    className="w-[24px] h-[24px] mb-[2px]"
                  />
                ) : (
                  <img
                    src={PortfolioIcon}
                    alt="portfolio-icon"
                    className="w-[24px] h-[24px] mb-[2px]"
                  />
                )}
                <span
                  className={`${
                    globalActiveTab === "portfolio"
                      ? "text-[#fff]"
                      : "text-[#707E89]"
                  } text-[14px] font-[500]`}
                >
                  Portfolio
                </span>
              </div>

              <div
                className="flex items-center flex-col cursor-pointer"
                onClick={() => {
                  dispatch({ type: GET_MARKET_TIME_RESET, data: "" });
                  setGlobalActiveTab("account");
                }}
              >
                {/* setting highlighted images if tab is active */}
                {globalActiveTab === "account" ? (
                  <img
                    src={AccountIconActive}
                    alt="account-icon"
                    className="w-[24px] h-[24px] mb-[2px]"
                  />
                ) : (
                  <img
                    src={AccountIcon}
                    alt="account-icon"
                    className="w-[24px] h-[24px] mb-[2px]"
                  />
                )}
                <span
                  className={`${
                    globalActiveTab === "account"
                      ? "text-[#fff]"
                      : "text-[#707E89]"
                  }  text-[14px] font-[500]`}
                >
                  Account
                </span>
              </div>
            </div>
          )}

          <MarketCloseModal
            marketModalOpen={marketModalOpen}
            marketModalFun={marketModalFun}
          />
        </>
      )}
    </>
  );
}

export default App;
