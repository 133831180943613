import {
    MCX_MODAL_DATA_REQUEST,
    MCX_MODAL_DATA_SUCCESS,
    MCX_MODAL_DATA_ERROR,
    NSE_MODAL_DATA_REQUEST,
    NSE_MODAL_DATA_SUCCESS,
    NSE_MODAL_DATA_ERROR,
    OPT_MODAL_DATA_REQUEST,
    OPT_MODAL_DATA_SUCCESS,
    OPT_MODAL_DATA_ERROR,
    MCX_TABLE_DATA_REQUEST,
    MCX_TABLE_DATA_SUCCESS,
    MCX_TABLE_DATA_ERROR,
    NSE_TABLE_DATA_REQUEST,
    NSE_TABLE_DATA_SUCCESS,
    NSE_TABLE_DATA_ERROR,
    OPT_TABLE_DATA_REQUEST,
    OPT_TABLE_DATA_SUCCESS,
    OPT_TABLE_DATA_ERROR,
    SAVE_EXCHANGE_USER_REQUEST,
    SAVE_EXCHANGE_USER_SUCCESS,
    SAVE_EXCHANGE_USER_ERROR,
    SAVE_EXCHANHE_USER_RESET,
    DELETE_EXCHANGE_USER_REQUEST,
    DELETE_EXCHANGE_USER_SUCCESS,
    DELETE_EXCHANGE_USER_ERROR,
    DELETE_EXCHANHE_USER_RESET,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGIN_RESET,
    USER_LOGOUT,
    UPDATE_TABLE_DATA,
    GET_USER_NOTIFICATION_REQUEST,
    GET_USER_NOTIFICATION_SUCCESS,
    GET_USER_NOTIFICATION_ERROR,
    GET_PROFILE_DATA_REQUEST,
    GET_PROFILE_DATA_SUCCESS,
    GET_PROFILE_DATA_ERROR,
    GET_LEDGER_BALANCE_REQUEST,
    GET_LEDGER_BALANCE_SUCCESS,
    GET_LEDGER_BALANCE_ERROR,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
    GET_TRADE_REQUEST,
    GET_TRADE_SUCCESS,
    GET_TRADE_ERROR,
    USER_BILL_REQUEST,
    USER_BILL_SUCCESS,
    USER_BILL_ERROR,
    GET_CONSOLIDATED_TRADE_REQUEST,
    GET_CONSOLIDATED_TRADE_SUCCESS,
    GET_CONSOLIDATED_TRADE_ERROR,
    GET_REFRESH_DATA_REQUEST,
    GET_REFRESH_DATA_SUCCESS,
    GET_REFRESH_DATA_ERROR,
    GET_USER_LEDGER_BALANCE_REQUEST,
    GET_USER_LEDGER_BALANCE_SUCCESS,
    GET_USER_LEDGER_BALANCE_ERROR,
    GET_CLOSED_ORDER_REQUEST,
    GET_CLOSED_ORDER_SUCCESS,
    GET_CLOSED_ORDER_ERROR,
    GET_NET_PL_REQUEST,
    GET_NET_PL_SUCCESS,
    GET_NET_PL_ERROR,
    GET_MARKET_TIME_REQUEST,
    GET_MARKET_TIME_SUCCESS,
    GET_MARKET_TIME_ERROR,
    GET_MARKET_TIME_RESET,
    SEND_DATA_REQUEST,
    SEND_DATA_SUCCESS,
    SEND_DATA_ERROR,
    CLOSE_TRADE_FROM_PORTFOLIO_REQUEST,
    CLOSE_TRADE_FROM_PORTFOLIO_SUCCESS,
    CLOSE_TRADE_FROM_PORTFOLIO_ERROR,
    CLOSE_TRADE_FROM_PORTFOLIO_RESET,
    CHECK_BEFORE_TRADE_MARKET_REQUEST,
    CHECK_BEFORE_TRADE_MARKET_SUCCESS,
    CHECK_BEFORE_TRADE_MARKET_ERROR,
    CHECK_BEFORE_TRADE_MARKET_RESET,
    SAVE_EXCHANGE_API_MARKET_REQUEST,
    SAVE_EXCHANGE_API_MARKET_SUCCESS,
    SAVE_EXCHANGE_API_MARKET_ERROR,
    SAVE_EXCHANGE_API_MARKET_RESET,
    CHECK_BEFORE_TRADE_LIMIT_REQUEST,
    CHECK_BEFORE_TRADE_LIMIT_SUCCESS,
    CHECK_BEFORE_TRADE_LIMIT_ERROR,
    CHECK_BEFORE_TRADE_LIMIT_RESET,
    SAVE_EXCHANGE_API_LIMIT_REQUEST,
    SAVE_EXCHANGE_API_LIMIT_SUCCESS,
    SAVE_EXCHANGE_API_LIMIT_ERROR,
    SAVE_EXCHANGE_API_LIMIT_RESET,
    RAISE_COMPLIANT_SUCCESS,
    RAISE_COMPLIANT_ERROR,
    DELETE_PENDING_ORDERS_REQUEST,
    DELETE_PENDING_ORDERS_SUCCESS,
    DELETE_PENDING_ORDERS_ERROR,
    DELETE_PENDING_ORDERS_RESET,
    TRADE_PENDING_CLOSE_REQUEST,
    TRADE_PENDING_CLOSE_SUCCESS,
    TRADE_PENDING_CLOSE_ERROR,
    TRADE_PENDING_CLOSE_RESET,
    TRADE_ALL_ACTIVE_CLOSE_REQUEST,
    TRADE_ALL_ACTIVE_CLOSE_SUCCESS,
    TRADE_ALL_ACTIVE_CLOSE_ERROR,
    TRADE_ALL_ACTIVE_CLOSE_RESET,
    TRADE_ACTIVE_CLOSE_REQUEST,
    TRADE_ACTIVE_CLOSE_SUCCESS,
    TRADE_ACTIVE_CLOSE_ERROR,
    TRADE_ACTIVE_CLOSE_RESET
} from "./constants";


export const mcxModalReducer = (state = { mcxModalData: [] }, action) => {
    switch (action.type) {
        case MCX_MODAL_DATA_REQUEST:
            return {
                loading: true,
                mcxModalData: [],
            };
        case MCX_MODAL_DATA_SUCCESS:
            return {
                loading: false,
                mcxModalData: action.payload
            };
        case MCX_MODAL_DATA_ERROR:
            return {
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};


export const nseModalReducer = (state = { nseModalData: [] }, action) => {
    switch (action.type) {
        case NSE_MODAL_DATA_REQUEST:
            return {
                loading: true,
                nseModalData: [],
            };
        case NSE_MODAL_DATA_SUCCESS:
            return {
                loading: false,
                nseModalData: action.payload
            };
        case NSE_MODAL_DATA_ERROR:
            return {
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};


export const optModalReducer = (state = { optModalData: [] }, action) => {
    switch (action.type) {
        case OPT_MODAL_DATA_REQUEST:
            return {
                loading: true,
                optModalData: [],
            };
        case OPT_MODAL_DATA_SUCCESS:
            return {
                loading: false,
                optModalData: action.payload
            };
        case OPT_MODAL_DATA_ERROR:
            return {
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const mcxTableReducer = (state = { mcxTableData: [] }, action) => {
    switch (action.type) {
        case MCX_TABLE_DATA_REQUEST:
            return {
                loading: true,
                mcxTableData: [],
            };
        case MCX_TABLE_DATA_SUCCESS:
            return {
                loading: false,
                mcxTableData: action.payload
            };
        case MCX_TABLE_DATA_ERROR:
            return {
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};


export const nseTableReducer = (state = { nseTableData: [] }, action) => {
    switch (action.type) {
        case NSE_TABLE_DATA_REQUEST:
            return {
                loading: true,
                nseTableData: [],
            };
        case NSE_TABLE_DATA_SUCCESS:
            return {
                loading: false,
                nseTableData: action.payload
            };
        case NSE_TABLE_DATA_ERROR:
            return {
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};


export const optTableReducer = (state = { optTableData: [] }, action) => {
    switch (action.type) {
        case OPT_TABLE_DATA_REQUEST:
            return {
                loading: true,
                optTableData: [],
            };
        case OPT_TABLE_DATA_SUCCESS:
            return {
                loading: false,
                optTableData: action.payload
            };
        case OPT_TABLE_DATA_ERROR:
            return {
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};


export const saveExchangeReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_EXCHANGE_USER_REQUEST:
        case SAVE_EXCHANGE_USER_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case SAVE_EXCHANGE_USER_SUCCESS:
        case DELETE_EXCHANGE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isExchangeSave: action.payload,
            };

        case SAVE_EXCHANGE_USER_ERROR:
        case DELETE_EXCHANGE_USER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case SAVE_EXCHANHE_USER_RESET:
        case DELETE_EXCHANHE_USER_RESET:
            return {
                ...state,
                isExchangeSave: {}
            }
        default:
            return state;
    }
};

export const deleteSingleTradeReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_PENDING_ORDERS_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case DELETE_PENDING_ORDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                isTokenUpdated: action.payload,
            };

        case DELETE_PENDING_ORDERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case DELETE_PENDING_ORDERS_RESET:
            return {
                ...state,
                isTokenUpdated: {}
            }
        default:
            return state;
    }
};


export const userReducer = (state = { user: {} }, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return {
                loading: true,
            };
        case USER_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload,
            };
        case USER_LOGIN_FAIL:
            return {
                ...state,
                user: null,
                error: action.payload,
            };
        case USER_LOGIN_RESET:
            return {
                ...state,
                user: '',
            }
        case USER_LOGOUT:
            return {
                ...state,
                user: {}
            }
        default:
            return state;
    }
};

export const tableDataReducer = (state = null, action) => {
    switch (action.type) {
        case UPDATE_TABLE_DATA:
            return action.payload;
        default:
            return state;
    }
};

export const getAllReducer = (
    state = {
        notificationLoading: false,
        notification: [],
        userBillLoading: false,
        userBillData: [],
        profileDataLoading: false,
        profileData: {},
        ledgerBalanceLoading: false,
        ledgerBalance: "",
        changePasswordResLoading: false,
        changePasswordRes: {},
        consolidatedTradeLoading: false,
        consolidatedTradeData: [],
        refreshDataLoading: false,
        refreshData: {},
        userLedgerBalanceData: [],
        userLedgerBalanceLoading: false,
        consolidatedOrderData: [],
        consolidatedOrderLoading: false,
        netplData: {},
        netplLoading: false,
        marketTimeData: "",
        marketTimeLoading: false,
        closeTradeFromPortfolioData: {},
        closeTradeFromPortfolioLoading: false,
        checkbeforeTradeLoading: false,
        checkbeforeTradeData: false,
        saveExchangeOrderLoading: false,
        saveExchangeOrderData: "",
        raiseCompliant: {},
        error: null,
    },
    action
) => {
    switch (action.type) {
        case GET_USER_NOTIFICATION_REQUEST:
            return {
                ...state,
                notificationLoading: true,
                error: null,
            };
        case USER_BILL_REQUEST:
            return {
                ...state,
                userBillLoading: true,
                error: null,
            };
        case GET_PROFILE_DATA_REQUEST:
            return {
                ...state,
                profileDataLoading: true,
                error: null,
            };
        case GET_LEDGER_BALANCE_REQUEST:
            return {
                ...state,
                ledgerBalanceLoading: true,
                error: null,
            };
        case CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                changePasswordResLoading: true,
                error: null,
            };
        case GET_CONSOLIDATED_TRADE_REQUEST:
            return {
                ...state,
                consolidatedTradeLoading: true,
                error: null,
            };

        case GET_REFRESH_DATA_REQUEST:
            return {
                ...state,
                refreshDataLoading: true,
                error: null,
            };
        case GET_USER_LEDGER_BALANCE_REQUEST:
            return {
                ...state,
                userLedgerBalanceLoading: true,
                error: null,
            };
        case GET_CLOSED_ORDER_REQUEST:
            return {
                ...state,
                consolidatedOrderLoading: true,
                error: null,
            };
        case GET_NET_PL_REQUEST:
            return {
                ...state,
                netplLoading: true,
                error: null,
            };
        case GET_MARKET_TIME_REQUEST:
            return {
                ...state,
                marketTimeLoading: true,
                error: null,
            };
        case CLOSE_TRADE_FROM_PORTFOLIO_REQUEST:
            return {
                ...state,
                closeTradeFromPortfolioLoading: true,
                error: null,
            };

        case CHECK_BEFORE_TRADE_MARKET_REQUEST:
            return {
                ...state,
                checkbeforeTradeLoading: true,
                error: null,
            };
        case CHECK_BEFORE_TRADE_LIMIT_REQUEST:
            return {
                ...state,
                checkbeforeTradeLoading: true,
                error: null,
            };
        case SAVE_EXCHANGE_API_MARKET_REQUEST:
            return {
                ...state,
                saveExchangeOrderLoading: true,
                error: null,
            };
        case SAVE_EXCHANGE_API_LIMIT_REQUEST:
            return {
                ...state,
                saveExchangeOrderLoading: true,
                error: null,
            };
        case GET_USER_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notificationLoading: false,
                notification: action.payload,
                error: null,
            };
        case USER_BILL_SUCCESS:
            return {
                ...state,
                userBillLoading: false,
                userBillData: action.payload,
                error: null,
            };
        case GET_PROFILE_DATA_SUCCESS:
            return {
                ...state,
                profileDataLoading: false,
                profileData: action.payload,
                error: null,
            };
        case GET_LEDGER_BALANCE_SUCCESS:
            return {
                ...state,
                ledgerBalanceLoading: false,
                ledgerBalance: action.payload,
                error: null,
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePasswordResLoading: false,
                changePasswordRes: action.payload,
                error: null,
            };
        case GET_CONSOLIDATED_TRADE_SUCCESS:
            return {
                ...state,
                consolidatedTradeLoading: false,
                consolidatedTradeData: action.payload,
                error: null,
            };
        case GET_REFRESH_DATA_SUCCESS:
            return {
                ...state,
                refreshDataLoading: false,
                refreshData: action.payload,
                error: null,
            };
        case GET_USER_LEDGER_BALANCE_SUCCESS:
            return {
                ...state,
                userLedgerBalanceLoading: false,
                userLedgerBalanceData: action.payload,
                error: null,
            };
        case GET_CLOSED_ORDER_SUCCESS:
            return {
                ...state,
                consolidatedOrderLoading: false,
                consolidatedOrderData: action.payload,
                error: null,
            };

        case GET_NET_PL_SUCCESS:
            return {
                ...state,
                netplLoading: false,
                netplData: action.payload,
                error: null,
            };
        case GET_MARKET_TIME_SUCCESS:
            return {
                ...state,
                marketTimeLoading: false,
                marketTimeData: action.payload,
                error: null,
            };
        case CLOSE_TRADE_FROM_PORTFOLIO_SUCCESS:
            return {
                ...state,
                closeTradeFromPortfolioLoading: false,
                closeTradeFromPortfolioData: action.payload,
                error: null,
            };
        case CHECK_BEFORE_TRADE_MARKET_SUCCESS:
            return {
                ...state,
                checkbeforeTradeLoading: false,
                checkbeforeTradeData: action.payload,
                error: null,
            };
        case SAVE_EXCHANGE_API_MARKET_SUCCESS:
            return {
                ...state,
                saveExchangeOrderLoading: false,
                saveExchangeOrderData: action.payload,
                error: null,
            };
        case CHECK_BEFORE_TRADE_LIMIT_SUCCESS:
            return {
                ...state,
                checkbeforeTradeLoading: false,
                checkbeforeTradeData: action.payload,
                error: null,
            };
        case SAVE_EXCHANGE_API_LIMIT_SUCCESS:
            return {
                ...state,
                saveExchangeOrderLoading: false,
                saveExchangeOrderData: action.payload,
                error: null,
            };
        case RAISE_COMPLIANT_SUCCESS:
            return {
                ...state,

                raiseCompliant: action.payload,
                error: null,
            };
        case GET_USER_NOTIFICATION_ERROR:
        case GET_PROFILE_DATA_ERROR:
        case GET_LEDGER_BALANCE_ERROR:
        case CHANGE_PASSWORD_ERROR:
        case USER_BILL_ERROR:
        case GET_CONSOLIDATED_TRADE_ERROR:
        case GET_REFRESH_DATA_ERROR:
        case GET_USER_LEDGER_BALANCE_ERROR:
        case GET_CLOSED_ORDER_ERROR:
        case GET_NET_PL_ERROR:
        case GET_MARKET_TIME_ERROR:
        case CLOSE_TRADE_FROM_PORTFOLIO_ERROR:
        case CHECK_BEFORE_TRADE_MARKET_ERROR:
        case SAVE_EXCHANGE_API_MARKET_ERROR:
        case CHECK_BEFORE_TRADE_LIMIT_ERROR:
        case SAVE_EXCHANGE_API_LIMIT_ERROR:
        case RAISE_COMPLIANT_ERROR:
            return {
                ...state,
                notificationLoading: false,
                profileDataLoading: false,
                ledgerBalanceLoading: false,
                changePasswordResLoading: false,
                consolidatedTradeLoading: false,
                refreshDataLoading: false,
                userLedgerBalanceLoading: false,
                consolidatedOrderLoading: false,
                netplLoading: false,
                marketTimeLoading: false,
                closeTradeFromPortfolioLoading: false,
                checkbeforeTradeLoading: false,
                saveExchangeOrderLoading: false,
                error: action.payload,
            };
        case CHECK_BEFORE_TRADE_MARKET_RESET:
        case CHECK_BEFORE_TRADE_LIMIT_RESET:
            return {
                ...state,
                checkbeforeTradeData: '',
            }
        case SAVE_EXCHANGE_API_MARKET_RESET:
        case SAVE_EXCHANGE_API_LIMIT_RESET:
            return {
                ...state,
                saveExchangeOrderData: '',
            }
        case GET_MARKET_TIME_RESET:
            return {
                ...state,
                marketTimeData: ''
            }
        case CLOSE_TRADE_FROM_PORTFOLIO_RESET:
            return {
                ...state,
                closeTradeFromPortfolioData: '',
            }

        default:
            return state;
    }
};

export const TradeReducer = (state = { tradeData: [] }, action) => {
    switch (action.type) {
        case GET_TRADE_REQUEST:
            return {
                tradeLoading: true,
            };
        case GET_TRADE_SUCCESS:
            return {
                tradeLoading: false,
                tradeData: action.payload
            };
        case GET_TRADE_ERROR:
            return {
                tradeLoading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const sendDataReducer = (state = { data: {}, sendingData: false, error: null }, action) => {
    switch (action.type) {
        case SEND_DATA_REQUEST:
            return {
                ...state,
                sendingData: true,
                error: null,
            };
        case SEND_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                sendingData: false,
            };
        case SEND_DATA_ERROR:
            return {
                ...state,
                sendingData: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export const TradeScreenExtraReducer = (state = { data: null, allActiveData: null, activeData: null }, action) => {
    
    switch (action.type) {
        
        case TRADE_PENDING_CLOSE_REQUEST:
            return {
                ...state,
                data: null,
                loading: true,
            };
        case TRADE_ALL_ACTIVE_CLOSE_REQUEST:
            return {
                ...state,
                allActiveData: null,
                loading: true
            }
        case TRADE_ACTIVE_CLOSE_REQUEST:
            return {
                ...state,
                activeData: null,
                loading: false
            }
        case TRADE_PENDING_CLOSE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case TRADE_ALL_ACTIVE_CLOSE_SUCCESS:
            return {
                
                ...state,
                loading: false,
                data: action.payload,
                allActiveData: action.payload
            }
        case TRADE_ACTIVE_CLOSE_SUCCESS:
            return {
                ...state,
                loading: false,
                activeData: action.payload
            }
        case TRADE_PENDING_CLOSE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case TRADE_ALL_ACTIVE_CLOSE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case TRADE_ACTIVE_CLOSE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case TRADE_PENDING_CLOSE_RESET:
            return {
                ...state,
                loading: false,
                error: null,
                data: null
            }
        case TRADE_ALL_ACTIVE_CLOSE_RESET:
            return {
                ...state,
                loading: false,
                allActiveData: null,
                error: null
            }
        case TRADE_ACTIVE_CLOSE_RESET:
            return {
                ...state,
                loading: false,
                activeData: null,
                error: null
            }
        default:
            return state;
    }
};
