import React from 'react';
import { Modal } from "antd";
import { useDispatch } from 'react-redux';
import { getTradeData } from '../../../redux/actions';
import { TRADE_PENDING_CLOSE_RESET } from "../../../redux/constants";
import { TRADE_ALL_ACTIVE_CLOSE_RESET } from "../../../redux/constants";

const TradeExtraModal = ({ tradeExtraModalOpen, data, tradeExtraModalOpenFun, user,allActiveData,activeTab}) => {
    debugger
    const dispatch = useDispatch();
    return (
        <Modal
            width={300}
            maskClosable={false}
            centered={true}
            closeIcon={false}
            closable={false}
            footer={false}
            open={tradeExtraModalOpen}
            className='modal'
            title={false}

        >
            <div className='pt-[15px] pl-[15px] pr-[15px] pb-[15px]'>
                {allActiveData !== null?
                    <div className='text-[#707E89] text-[20px] font-[500] text-center'>  !! All Trades successfully closed. !!</div>
                  
                :
                    <div className='text-[#707E89] text-[20px] font-[500] text-center'>{data?.ResponseMessage}</div>
                }
                
                <div className='pr-[10px] pl-[10px] flex justify-center pt-[10px]'>
                
                    <span
                        onClick={() => {
                            dispatch({ type: TRADE_PENDING_CLOSE_RESET, data: null });
                            dispatch({ type: TRADE_ALL_ACTIVE_CLOSE_RESET, allActiveData: null });
                            tradeExtraModalOpenFun(false);
                            dispatch(getTradeData(activeTab, user?.userId));
                        }}
                        className='cursor-pointer text-[#fff] text-[20px] rounded-[5px] bg-[#1D3557] w-[50px] h-[40px] flex items-center justify-center text-center'
                    >OK</span>
               
                </div>
            </div>
        </Modal>
    )
}

export default TradeExtraModal
