import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
    mcxModalReducer,
    nseModalReducer,
    optModalReducer,
    mcxTableReducer,
    nseTableReducer,
    optTableReducer,
    saveExchangeReducer,
    userReducer,
    tableDataReducer,
    getAllReducer,
    TradeReducer,
    sendDataReducer,
    deleteSingleTradeReducer,
    TradeScreenExtraReducer
} from "./redux/reducer";

const reducer = combineReducers({
    mcxModal: mcxModalReducer,
    nseModal: nseModalReducer,
    optModal: optModalReducer,
    mcxData: mcxTableReducer,
    nseData: nseTableReducer,
    optData: optTableReducer,
    saveExchange: saveExchangeReducer,
    user: userReducer,
    tableData: tableDataReducer,
    store: getAllReducer,
    trades: TradeReducer,
    data: sendDataReducer,
    delete: deleteSingleTradeReducer,
    tradeExtra: TradeScreenExtraReducer
});

let initialState = {};

const middleware = [thunk];

const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleware))
);

export default store;