import React, { useEffect, useState } from 'react';
import { Modal } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { closeTradefromActiveTab } from '../../../redux/actions';

const CloseOrderModal = ({ closeOrderState, closeOrderStateFun, modalId, pendingData, mcxModalData, nseModalData, optModalData, setModalDataFun, setPendingDataFun, isExpired, activeTradeCloseData }) => {
    const dispatch = useDispatch();
    const [modalData, setModalData] = useState(null)
    const { refreshData } = useSelector((state) => state.store);
    const { tradeData } = useSelector((state) => state.trades);

    useEffect(() => {
        let data;
        if (pendingData?.symbolType === "MCX") {
            data = [...mcxModalData]
        } else if (pendingData?.symbolType === "NSE") {
            data = [...nseModalData]
        } else if (pendingData?.symbolType === "OPT") {
            data = [...optModalData]
        }
        if (tradeData && data) {
            tradeData.forEach((item) => {
                data.forEach((value) => {
                    if (value.instrument_token === item.TokenNo) {
                        value.id = item.Id;
                        value.OrderNo = item.OrderNo;
                        value.selectedLot = item.Lot;
                        value.OrderPrice = item.OrderPrice;
                        value.OrderCategory = item.OrderCategory;
                    }
                })
            })
        }
        setModalData(data)
    }, [mcxModalData, nseModalData, optModalData, pendingData, modalId, tradeData])

    useEffect(() => {
        if (!isExpired) {
            // Create a WebSocket instance
            let activePL = 0;
            const socket = new WebSocket('wss://ws.tradewingss.com/api/webapiwebsoc');
            // Event listener for WebSocket open event
            socket.addEventListener('open', () => {
                // console.log('WebSocket connection established');
            });

            // Event listener for WebSocket message event
            socket.addEventListener('message', (event) => {
                const message = event.data;

                // Check if the message is not empty
                if (message) {
                    try {
                        const newData = JSON.parse(message);
                        // console.log("newData ", newData);

                        if (modalId === newData.instrument_token?.toString()) {
                            tradeData?.forEach((item) => {
                                if (item?.TokenNo === modalId) {
                                    if (item.OrderCategory === "SELL") {
                                        activePL = ((item.OrderPrice - newData.ask) * item.Lot) * item.selectedlotsize;
                                        let activePL_trades = document.getElementById(`button${modalId}`);
                                        if (activePL_trades) {
                                            if (activePL > 0) {
                                                activePL_trades.style.backgroundColor = 'green';
                                                activePL_trades.innerHTML = `Close Order in profit ${activePL?.toFixed(2)
                                                    } `;
                                            } else {
                                                activePL_trades.style.backgroundColor = 'red';
                                                activePL_trades.innerHTML = `Close Order in loss ${activePL?.toFixed(2)} `;
                                            }
                                        }
                                    } else if (item.OrderCategory === "BUY") {
                                        activePL = ((newData.bid - item.OrderPrice) * item.Lot) * item.selectedlotsize;
                                        let activePL_trades = document.getElementById(`button${modalId}`);
                                        if (activePL > 0) {
                                            activePL_trades.style.backgroundColor = 'green';
                                            activePL_trades.innerHTML = `Close Order in profit ${activePL?.toFixed(2)} `;
                                        } else {
                                            activePL_trades.style.backgroundColor = 'red';
                                            activePL_trades.innerHTML = `Close Order in loss ${activePL?.toFixed(2)} `;
                                        }
                                    }
                                }
                            })
                        }

                    } catch (error) {
                        console.warn('Error parsing JSON:', error);
                    }
                }
            });

            // Event listener for WebSocket close event
            socket.addEventListener('close', (event) => {
                // console.log('WebSocket connection closed', event.code, event.reason);
            });

            // Event listener for WebSocket error event
            socket.addEventListener('error', (error) => {
                // console.warn('WebSocket error:', error);
            });

            // Clean up the WebSocket connection on component unmount
            return () => {
                socket.close();
            };
        }
    }, [modalId, tradeData, isExpired]);


    function setsymbol(val) {
        if (val === "ALUMINI") { return "ALUMINIUM"; }
        else if (val === "ALUMINIUM") {
            return "ALUMINIUM";
        }
        if (val === "COPPER") {
            return "COPPER";
        }
        else if (val === "CRUDEOIL") {
            return "CRUDEOIL";
        }
        else if (val === "CRUDEOILM") {
            return "CRUDEOIL";
        }
        else if (val === "GOLD") {
            return "GOLD";
        }
        else if (val === "GOLDM") {
            return "GOLDM";
        }
        else if (val === "GOLDMINI") {
            return "GOLD";
        }
        else if (val === "LEAD") {
            return "LEAD";
        }

        else if (val === "LEADMINI") {
            return "LEAD";
        }
        else if (val === "MENTHAOIL") {
            return "MENTHAOIL";
        }
        else if (val === "NATURALGAS") {
            return "NATURALGAS";
        }
        else if (val === "NATGASMINI") {
            return "NATURALGAS";
        }
        else if (val === "NICKEL") {
            return "NICKEL";
        }
        else if (val === "SILVER") {
            return "SILVER";
        }
        else if (val === "SILVERM") {
            return "SILVERM";
        }
        else if (val === "SILVERMINI") {
            return "SILVER";
        }
        else if (val === "SILVERMIC") {
            return "SILVERMIC";
        }

        else if (val === "ZINC") {
            return "ZINC";
        }
        else if (val === "ZINCMINI") {
            return "ZINC";
        }

        return val;
    }

    function handleCalculations(each) {
        debugger
        let Brokrage = 0;
        let lp = 0;
        const bidval = document.getElementById(`bid_mobile${each.instrument_token}`).innerHTML;
        const askval = document.getElementById(`ask_mobile${each.instrument_token}`).innerHTML;
        const activePl = document.getElementById(`button${each.instrument_token}`).innerHTML;
        const regex = /-?\d+/;
        let valueCalculated = 0;
        if (activePl !== "Close Order") {
            valueCalculated = parseInt(activePl.match(regex)[0]);
        }

        if (each.OrderCategory === "BUY") {
            lp = bidval - each?.OrderPrice * each?.selectedLot * each?.lotsize
        } else if (each.OrderCategory === "SELL") {
            lp = each?.OrderPrice - askval * each?.selectedLot * each?.lotsize
        }
        let CMP = 0;
        if (each.OrderCategory === "BUY") {
            CMP = askval;
        } else {
            CMP = bidval;
        }
        if (each.Exchange === "MCX") {
            if (refreshData?.Mcx_Brokerage_Type === "per_lot") {
                const symbolname = each?.symbolname;//GOLD22AUGFUT
                const symarr = symbolname.split("_");
                const similersym = setsymbol(symarr[0] + "".toString().trim());
                const value = `${refreshData}?.${similersym} _brokerage`;
                Brokrage = value * each?.lotsize;
            }
            else {
                Brokrage = ((each?.OrderPrice + CMP) * each?.selectedLot * each?.lotsize * refreshData?.MCX_brokerage_per_crore) / 10000000;
            }
        } else if (each.Exchange === "NSE") {
            if (refreshData?.NSE_Brokerage_Type === "per_lot") {
                Brokrage = refreshData?.Equity_brokerage_per_crore * each?.lotsize;
            }
            else {
                Brokrage = ((each?.OrderPrice + CMP) * each?.selectedLot * each?.lotsize * refreshData?.Equity_brokerage_per_crore) / 10000000;
            }
        } else if (each.Exchange === "OPT") {
            if (refreshData?.CDS_Brokerage_Type === "per_lot") {
                Brokrage = refreshData?.CDS_brokerage_per_crore * each?.lotsize;
            }
            else {
                Brokrage = ((each?.OrderPrice + CMP) * each?.selectedLot * each?.lotsize * refreshData?.CDS_brokerage_per_crore) / 10000000;
            }
        }
        const currentDate = new Date();

        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // Month is 0-indexed
        const day = currentDate.getDate();

        const formattedDate = `${year} -${month} -${day} `;
        activeTradeCloseData(each?.symbolname, each?.selectedLot, valueCalculated)
        dispatch(closeTradefromActiveTab(valueCalculated, Brokrage, CMP, formattedDate, each.OrderNo, refreshData?.UserId, each?.SymbolType))
        closeOrderStateFun(false);
    }

    return (
        <Modal
            width={350}
            maskClosable={false}
            centered={true}
            closeIcon={false}
            closable={false}
            footer={false}
            open={closeOrderState}
            className='modal'
            title={
                <div className='flex justify-between items-center pt-[10px] pr-[31px] pl-[31px] pb-[10px] '>
                    <span className='text-[#011C3A] text-[20px] font-[500]'>Close Order</span>
                    <span
                        onClick={() => {
                            closeOrderStateFun(false);
                            setModalDataFun("")
                            setPendingDataFun(null)
                        }}
                        className='cursor-pointer text-[#707E89] text-[20px]'
                    >✕</span>
                </div>
            }
        >
            <div className='pt-[15px] pl-[15px] pr-[15px] pb-[15px]'>
                {
                    modalData?.map((each) => {
                        return each.instrument_token === modalId ?
                            (
                                <React.Fragment key={each.instrument_token}>
                                    <div className={`flex justify - around cursor - pointer my - 0`}>
                                        <span
                                            id={`button${each.instrument_token}`}
                                            className={`flex justify-center bg-[green] items-center tracking-[0.04em] text-[#fff] pt-[12px] pb-[12px] w-full h-full text-center text-[15px] font-[700] rounded-[10px]`}
                                            onClick={() => handleCalculations(each)}
                                        >
                                            Close Order
                                        </span>
                                    </div>
                                    <div className='flex justify-around mr-[31px] ml-[31px] pt-[3px] pb-[3px] mt-[5px] mb-[10px]  cursor-pointer rounded-[10px]'>
                                        <span className='text-[#383838] text-[17px] font-[700]'>{each.symbolname}</span>
                                    </div>

                                    <div className='flex justify-between mt-[5px]'>
                                        <div className='bg-[#EEEEEE] rounded-[5px] flex justify-center items-center w-[48%] h-[30px]'>
                                            <span className='text-[#000] text-[14px] font-[500] tracking-[0.04em]'>
                                                Bid
                                            </span>
                                            <span className="text-[#000] text-[15px] font-[500] tracking-[0.04em]" id={"Modal_bid" + each.instrument_token}> @ {each.sell}</span>
                                        </div>
                                        <div className='bg-[#EEEEEE] rounded-[5px] flex justify-center items-center w-[48%] h-[30px]'>
                                            <span className='text-[#000] text-[14px] font-[500] tracking-[0.04em]'>
                                                Ask
                                            </span>
                                            <span className="text-[#000] text-[15px] font-[500] tracking-[0.04em]" id={"Modal_ask" + each.instrument_token}> @ {each.buy}</span>
                                        </div>
                                    </div>

                                    <div className='flex justify-between mt-[5px] '>
                                        <div className='bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[22%] h-[75px]'>
                                            <span className='text-[#000] text-[14px] font-[500] tracking-[0.04em]'>Open</span>
                                            <span id={"Modal_open" + each.instrument_token} className='text-[#000] text-[15px] font-[500] tracking-[0.04em]'>{each.open_}</span>
                                        </div>

                                        <div className='bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[22%] h-[75px]'>
                                            <span className='text-[#000] text-[14px]  font-[500] tracking-[0.04em]'>Close</span>
                                            <span id={"Modal_close" + each.instrument_token} className='text-[#000] text-[15px] font-[500] tracking-[0.04em]'>{each.close_}</span>
                                        </div>

                                        <div className='bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[22%] h-[75px]'>
                                            <span className='text-[#000] text-[14px] font-[500] tracking-[0.04em]'>High</span>
                                            <span id={"Modal_high" + each.instrument_token} className='text-[#000] text-[15px]  font-[500] tracking-[0.04em]'>{each.high_}</span>
                                        </div>

                                        <div className='bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[22%]  h-[75px]'>
                                            <span className='text-[#000] text-[14px] font-[500] tracking-[0.04em]'>Low</span>
                                            <span id={"Modal_low" + each.instrument_token} className='text-[#000] text-[15px]  font-[500] tracking-[0.04em]'>{each.low_}</span>
                                        </div>
                                    </div>

                                    <div className='flex justify-between mt-[15px] pb-[20px]'>
                                        <div className='bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[22%]  h-[75px]'>
                                            <span className='text-[#000] text-[14px] font-[500] tracking-[0.04em]'>LTP</span>
                                            <span id={"Modal_last_price" + each.instrument_token} className='text-[#000] text-[15px] font-[500] tracking-[0.04em]'>{each.last_price}</span>
                                        </div>


                                        <div className='bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-auto  h-[75px]'>
                                            <span className='text-[#000] text-[14px]  font-[500] tracking-[0.04em]'>Change</span>
                                            <span id={"Modal_change" + each.instrument_token} className='text-[#000] text-[15px] font-[500] tracking-[0.04em]'>{each.change}</span>
                                        </div>


                                        <div className='bg-[#EEEEEE] rounded-[5px] flex flex-col justify-center items-center w-[22%]   h-[75px]'>
                                            <span className='text-[#000] text-[14px] font-[500] tracking-[0.04em]'>Volume</span>
                                            <span id={"Modal_volume" + each.instrument_token} className='text-[#000] text-[15px] font-[500] tracking-[0.04em]'>{each.volume}</span>
                                        </div>
                                    </div>

                                </React.Fragment>
                            )
                            : null
                    })
                }
            </div>
        </Modal>
    )
}

export default CloseOrderModal
