import React from 'react';
import { Modal } from "antd";
import { useSelector } from 'react-redux';

const ProfileModal = ({ profileState, profileStateFun }) => {
    const { profileData } = useSelector((state) => state.store);


    return (
        <Modal
            width={521}
            maskClosable={false}
            centered={true}
            closeIcon={false}
            closable={false}
            footer={false}
            open={profileState}
            className='modal'
            title={
                <div className='flex justify-between items-center pt-[18px] pr-[20px] pb-[16px] pl-[20px]'>
                    <span className='text-[#011C3A] text-[20px] font-[500]'>User Trade Profile</span>
                    <span
                        onClick={() => profileStateFun(false)}
                        className='cursor-pointer text-[#707E89] text-[20px]'
                    >✕</span>
                </div>
            }
        >
            {
                profileData?.MCX && (
                    <>
                        <div className='bg-[#C9E1FC] w-full mt-[10px] text-[#383838] text-[20px] font-[700] text-center'>MCX</div>
                        <div className='flex justify-around mt-[15px] pl-[10px] pr-[10px]'>
                            <div className='flex flex-col w-[50%] pl-[6px] pr-[6px] pt-[6px] pb-[6px] border border-gray-300 border-t-1.5 border-solid items-center'>
                                <span className='text-[#383838] text-[16px] font-[700]'>
                                    Brokerage Type :
                                </span>
                            </div>
                            <div className='flex flex-col w-[50%] pl-[6px] items-center  pr-[6px] pt-[6px] pb-[6px] border border-gray-300 border-t-1.5 border-solid justify-center text-left'>
                                <span className='text-[#383838] text-[13px] font-[400]'>
                                    {profileData?.MCX?.Mcx_Brokerage_Type}
                                </span>
                            </div>
                        </div>

                        <div className='flex justify-around pl-[10px] pr-[10px]'>
                            <div className='flex flex-col w-[50%] pl-[6px] pr-[6px] pt-[6px] pb-[6px] border border-gray-300 border-t-1.5 border-solid items-center'>
                                <span className='text-[#383838] text-[16px] font-[700]'>
                                    Brokerage Value :
                                </span>
                            </div>
                            <div className='block flex-col w-[50%] pl-[6px] items-center  pr-[6px] pt-[6px] pb-[6px] border border-gray-300 border-t-1.5 border-solid justify-center text-left'>
                                <span className='text-[#383838] text-[13px] font-[400]'>
                                    {JSON.stringify(profileData?.MCX?.MCX_brokerage_per_crore)}
                                </span>
                            </div>
                        </div>

                        <div className='flex justify-around pl-[10px] pr-[10px]'>
                            <div className='flex flex-col w-[50%] pl-[6px] pr-[6px] pt-[6px] pb-[6px] border border-gray-300 border-t-1.5 border-solid items-center'>
                                <span className='text-[#383838] text-[16px] font-[700]'>
                                    Exposure Type :
                                </span>
                            </div>
                            <div className='flex flex-col  w-[50%] pl-[6px] items-center pr-[6px] pt-[6px] pb-[6px] border border-gray-300 border-t-1.5 border-solid justify-center text-left'>
                                <span className='text-[#383838] text-[13px] font-[400]'>
                                    {profileData?.MCX?.Mcx_Exposure_Type}
                                </span>
                            </div>
                        </div>


                        <div className='flex justify-around pl-[10px] pr-[10px]'>
                            <div className='flex flex-col w-[50%] pl-[6px] pr-[6px] pt-[6px] pb-[6px] border border-gray-300 border-t-1.5 border-solid items-center'>
                                <span className='text-[#383838] text-[16px] font-[700]'>
                                    Margin Intraday :
                                </span>
                            </div>
                            <div className='flex flex-col w-[50%] pl-[6px] items-center pr-[6px] pt-[6px] pb-[6px] border border-gray-300 border-t-1.5 border-solid justify-center text-left'>
                                <span className='text-[#383838] text-[13px] font-[400]'>
                                    {profileData?.MCX?.Intraday_Exposure_Margin_MCX}
                                </span>
                            </div>
                        </div>

                        <div className='flex justify-around pl-[10px] pr-[10px]'>
                            <div className='flex flex-col w-[50%] pl-[6px] pr-[6px] pt-[6px] pb-[6px] border border-gray-300 border-t-1.5 border-solid items-center'>
                                <span className='text-[#383838] text-[16px] font-[700]'>
                                    Margin Holding :
                                </span>
                            </div>
                            <div className='flex flex-col w-[50%] pl-[6px] items-center pr-[6px] pt-[6px] pb-[6px] border border-gray-300 border-t-1.5 border-solid justify-center text-left'>
                                <span className='text-[#383838] text-[13px] font-[400]'>
                                    {profileData?.MCX?.Holding_Exposure_Margin_MCX}
                                </span>
                            </div>
                        </div>

                    </>
                )
            }
            {
                profileData?.NSE && (
                    <>
                        <div className='bg-[#C9E1FC] w-full mt-[10px] text-[#383838] text-[20px] font-[700] text-center'>NSE</div>
                        <div className='flex justify-around mt-[15px] pb-[15px] pl-[10px] pr-[10px] flex-col'>
                            <div className='flex w-[100%] flex-row pl-[13px] border border-gray-300 border-t-1.5 border-solid items-center'>
                                <span className='text-[#383838]  block w-[50%]  text-center  text-[16px] border-r border-solid  border-gray-300 font-[700] pt-[6px] pb-[6px]'>
                                    Brokerage
                                </span>
                                <span className='text-[#383838]  text-center block w-[50%] pt-[6px] pb-[6px] text-[13px] font-[400]'>
                                    {profileData?.NSE?.Equity_brokerage_per_crore}/ {profileData?.NSE?.NSE_Brokerage_Type}
                                </span>
                            </div>
                            <div className='flex w-[100%] flex-row  pl-[13px]  border border-gray-300 border-t-1.5 border-solid items-center'>
                                <span className='text-[#383838] block w-[50%]  text-center  text-[16px] border-r border-solid  border-gray-300  font-[700] pt-[6px] pb-[6px]'>
                                    Margin Intraday
                                </span>
                                <span className='text-[#383838]  text-center block w-[50%] pt-[6px] pb-[6px] text-[13px] font-[400]'>
                                    {profileData?.NSE?.Intraday_Exposure_Margin_Equity}/ {profileData?.NSE?.NSE_Exposure_Type}
                                </span>
                            </div>
                            <div className='flex w-[100%] flex-row  pl-[13px] border border-gray-300 border-t-1.5 border-solid items-center'>
                                <span className='text-[#383838] block w-[50%] text-center text-[16px] border-r border-solid  border-gray-300  font-[700] pt-[6px] pb-[6px]'>
                                    Margin Holding
                                </span>
                                <span className='text-[#383838] text-center block w-[50%] pt-[6px] pb-[6px] text-[13px] font-[400]'>
                                    {profileData?.NSE?.Holding_Exposure_Margin_Equity}/ {profileData?.NSE?.NSE_Exposure_Type}
                                </span>
                            </div>
                        </div>
                    </>
                )
            }
            {
                profileData?.CDS && (
                    <>
                        <div className='bg-[#C9E1FC] w-full mt-[10px] text-[#383838] text-[20px] font-[700] text-center'>NFO-OPT</div>
                        <div className='flex justify-around flex-col mt-[15px] pb-[15px] pl-[10px] pr-[10px]'>
                            <div className='flex flex-row w-[100%] text-center border border-gray-300 border-t-1.5 border-solid items-center'>
                                <span className='text-[#383838] text-[16px] font-[700] block w-[50%] border-r border-solid  border-gray-300 pt-[6px] pb-[6px]'>
                                    Brokerage
                                </span>
                                <span className='text-[#383838] text-center  block w-[50%] text-[13px] font-[400]'>
                                    {profileData?.CDS?.CDS_brokerage_per_crore}/ {profileData?.CDS?.CDS_Brokerage_Type}
                                </span>
                            </div>
                            <div className='flex flex-row w-[100%] text-center border border-gray-300 border-t-1.5 border-solid items-center'>
                                <span className='text-[#383838] text-[16px] font-[700] block  w-[50%]  border-r border-solid  border-gray-300  pt-[6px]  pb-[6px]'>
                                    Margin Intraday
                                </span>
                                <span className='text-[#383838] text-center block w-[50%]  text-[13px] font-[400]'>
                                    {profileData?.CDS?.Intraday_Exposure_Margin_CDS}/ {profileData?.CDS?.CDS_Exposure_Type}
                                </span>
                            </div>
                            <div className='flex flex-row w-[100%] text-center border border-gray-300 border-t-1.5 border-solid items-center'>
                                <span className='text-[#383838] text-[16px] font-[700] block  w-[50%]  border-r border-solid  border-gray-300  pt-[6px] pb-[6px]'>
                                    Margin Holding
                                </span>
                                <span className='text-[#383838] text-center block w-[50%] text-[13px] font-[400]'>
                                    {profileData?.CDS?.Holding_Exposure_Margin_CDS}/ {profileData?.CDS?.CDS_Exposure_Type}
                                </span>
                            </div>
                        </div>
                    </>
                )
            }
        </Modal>
    )
}

export default ProfileModal
