import React from 'react';
import { Modal } from "antd";
import { useSelector } from 'react-redux';

const NotificationModal = ({ notificationstState, notificationstStateFun }) => {
    
    // getting data from api using redux
    const { notification } = useSelector((state) => state.store);

    // modal for notification modal, for more information read antd modal documentation
    return (
        <Modal
            width={521}
            maskClosable={false}
            centered={true}
            closeIcon={false}
            closable={false}
            footer={false}
            open={notificationstState}
            className='modal'
            title={
                <div className='flex justify-between items-center pt-[18px] pr-[20px] pb-[16px] pl-[20px]'>
                    <span className='text-[#011C3A] text-[20px] font-[500]'>Notifications</span>
                    <span
                        onClick={() => notificationstStateFun(false)}
                        className='cursor-pointer text-[#707E89] text-[20px]'
                    >✕</span>
                </div>
            }
        >
            <div className='pt-[10px] pl-[34px] pr-[39px] pb-[20px]'>
                {notification.map((item) => {
                    return (
                        <React.Fragment key={item.CreatedDate}>
                            <div>
                                <div className='flex justify-between mb-[10px] items-center'>
                                    <span className='text-[#000000] text-[16px] font-[700]'>{item.Title}</span>
                                    <span className='text-[#000000] text-[16px] font-[400]'>{item.CreatedDate}</span>
                                </div>
                                <div className='text-[16px] text-[#000] font-[500]'>
                                    {item.Message}
                                </div>
                            </div>
                            <hr className='w-full h-[1.5px] bg-[#D6DFE8] mt-[15px] mb-[15px]' />
                        </React.Fragment>
                    )
                })}
            </div>
        </Modal>
    )
}

export default NotificationModal
