import React from 'react';
import { Modal } from "antd";
import { useDispatch } from 'react-redux';
import { getCloseTradeFromPortfolioData } from '../../../redux/actions';

const CloseTradeModal = ({ closeTradeState, closeTradeStateFun, closeTradeData }) => {
    const dispatch = useDispatch();

    return (
        <Modal
            width={300}
            maskClosable={false}
            centered={true}
            closeIcon={false}
            closable={false}
            footer={false}
            open={closeTradeState}
            className='modal'
            title={false}

        >
            <div className='pt-[15px] pl-[15px] pr-[15px] pb-[10px] text-[16px] font-[500]'>
                Do you want to close these trades?
            </div>
            <div className=' flex justify-end pb-[15px] pr-[15px]'>
                <span
                    onClick={() => closeTradeStateFun(false)}
                    className='cursor-pointer text-[#fff] text-[16px] rounded-[5px] bg-[#1D3557] px-2 mr-[5px] h-[35px] flex items-center justify-center text-center'
                >Cancel</span>
                <span
                    onClick={() => {
                        closeTradeStateFun(false);
                        dispatch(getCloseTradeFromPortfolioData(closeTradeData?.userid, closeTradeData?.ordercat, closeTradeData?.tokenno, closeTradeData?.cmpval))
                    }}
                    className='cursor-pointer text-[#fff] text-[16px] rounded-[5px] bg-[#1D3557] w-[50px] h-[35 px] flex items-center justify-center text-center'
                >OK</span>
            </div>
        </Modal>
    )
}

export default CloseTradeModal
