import React, { useEffect, useState } from 'react';
import { Modal } from 'antd';
import MainLogo from "../../assets/logo.png";
import { useDispatch, useSelector } from 'react-redux';
import { postLogin } from '../../redux/actions';
import { USER_LOGIN_RESET } from "../../redux/constants";

const Login = ({ settingGlobalTab, settinguser }) => {
    const dispatch = useDispatch();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginErrorModalState, setLoginErrorModalState] = useState(false);
    const { user } = useSelector((state) => state.user);

    useEffect(() => {
        if (user?.ResponseCode === '200') {
            const data = {
                userId: user.UserId,
                refId: user.RefId,
                username: user.UserName,
                isMcx: user.IsMCXTrade,
                isNse: user.IsNSETrade,
                creditLimit:user.creditLimit,
            }
            settinguser(data);
            if (user.FirstTimeLogin === "true") {
                settingGlobalTab('account');
            } else {
                if (user.IsMCXTrade === "true" || user.IsNSETrade === "true") {
                    settingGlobalTab('watchlist');
                } else {
                    settingGlobalTab('account');
                }
            }
            const dataString = JSON.stringify(data);
            localStorage.setItem("userData", dataString);
            localStorage.setItem("CreditLimit", data.CreditLimit);
            dispatch({ type: USER_LOGIN_RESET, data: '' })
        } else if (user?.ResponseCode === '201') {
            setLoginErrorModalState(true)
        }
    }, [dispatch, user, settingGlobalTab, settinguser])

    const loginErrorModal = (
        <Modal
            width={521}
            maskClosable={false}
            centered={true}
            closeIcon={false}
            closable={false}
            footer={false}
            open={loginErrorModalState}
            className='modal'
            title={false}
        >
            <div className="flex flex-col justify-between items-center pt-[10px] pb-[7px] bg-[#fff]">
                <span className="text-center text-[#595959] mb-[10px] font-[600] text-[1.875em]">Please Try Again</span>
                <span className="text-center text-[#545454] mb-[10px] text-[1.125em] font-[1.125em]">Invalid Login Details</span>
                <button
                    className="w-[70px] bg-[#1d3557] text-[16px] mb-[10px] text-[#fff] font-[600] pl-[20px] pr-[20px] pt-[10px] pb-[10px] rounded-[5px]"
                    onClick={() => setLoginErrorModalState(false)}
                >OK</button>
            </div>
        </Modal>
    )

    const loginSubmit = (e) => {
        e.preventDefault();
        dispatch(postLogin(username, password));
    };

    return (
        <div className="bg-[#F5F5F5] h-screen w-screen flex items-center justify-center">
            <div className="xl:w-[612px] lg:w-[612px] md:w-[612px] w-full flex flex-col items-center pl-[20px] pr-[20px]">
                <img
                    src={MainLogo}
                    alt="logo"
                    className="w-200 h-150 border border-[#D9D9D9] rounded-[25px] p-[5px] mb-[5px]"
                />
                <h4 className="text-[30px] font-[700] mb-[10px]">Detox</h4>
                <form
                    className="flex flex-col w-full mb-[15px] "
                    onSubmit={(e) => loginSubmit(e)}
                >
                    <input
                        placeholder="Name"
                        type="text"
                        required
                        onChange={(e) => setUsername(e.target.value)}
                        className="focus:border-788186 focus:outline-none h-[40px] font-[500]::placeholder placeholder-[#707E89] w-full rounded-[23.5px] mb-[20px] pt-[16px] pb-[17px] pr-[28px] pl-[28px] text-[16px] border border-[#788186] md:h-[48px]"
                    />
                    <input
                        placeholder="Password"
                        type="password"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                        className="focus:border-788186 focus:outline-none h-[40px] font-[500]::placeholder placeholder-[#707E89] w-full rounded-[23.5px] mb-[13px] pt-[16px] pb-[17px] pr-[28px] pl-[28px] text-[16px] border border-[#788186] md:h-[48px]"
                    />
                    <div className="flex items-center mb-[10px]">
                        <input type="radio" className="h-5 w-5 mr-[10px]" />
                        <label className="text-gray-600 font-bold text-[14px]" onClick={() => console.log("remeber me")}>
                            Remember me
                        </label>
                    </div>
                    <button
                        className="bg-[#011C3A] w-full text-[#F2F2F2] flex justify-center font-[700] text-[16px] pt-[8px] pb-[10px] rounded-[23.5px]"
                        type="submit"
                    >
                        Login
                    </button>
                </form>
                <p className="text-[#707E89] text-[12px] md:text-[16px] font-[500] w-full mb-[10px]">
                    No Real money involved. This is a virtual trading application which
                    has all the features to trade.
                </p>
                <p className="text-[#707E89] text-[12px] md:text-[16px] font-[500] w-full">
                    This Application is used for exchanging views on market for
                    individual students for training purpose only.
                </p>
            </div>
            {loginErrorModal}
        </div>
    )
}

export default Login