import React, { useState, useEffect } from 'react';
import { Modal } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { changePasswordApi, getRefreshData } from "../../../redux/actions";

const ChangePasswordModal = ({ changePasswordState, changePasswordStateFun, refreshData, user }) => {
    const dispatch = useDispatch();

    // useState var input field and modal opening if password doesn't message and the message
    const [currentPasswordNotMatchState, setCurrentPasswordNotMatchState] = useState(false);
    const [passwordCondition, setPasswordCondition] = useState("");
    const [changePasswordVar, setChangePasswordVar] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
    });
    
    // destructing input fields from useState var
    const { currentPassword, newPassword, confirmPassword } = changePasswordVar;
    
    // getting data from api using redux
    const { changePasswordRes } = useSelector((state) => state.store);

    // taking values from input field
    function changePasswordFun(e) {
        setChangePasswordVar({ ...changePasswordVar, [e.target.name]: e.target.value });
    }

    // function for submitting form and checking if password is correct or matched and calling api once it's matched
    function changePassword(e) {
        e.preventDefault();
        if (refreshData.Password_ === currentPassword) {
            if (newPassword === confirmPassword) {
                // calling api once it's matched
                dispatch(changePasswordApi(currentPassword, user?.userId, newPassword));
            } else {
                setPasswordCondition("new")
                setCurrentPasswordNotMatchState(true)
            }
        } else {
            setPasswordCondition("current")
            setCurrentPasswordNotMatchState(true)
        }
    }

    // checking if api is getting http 200 response, setting every variable to default value and calling refreshData api
    useEffect(() => {
        if (changePasswordRes.ResponseCode === "200") {
            setPasswordCondition("set")
            setCurrentPasswordNotMatchState(true)
            setChangePasswordVar({
                currentPassword: "",
                newPassword: "",
                confirmPassword: ""
            });
            dispatch(getRefreshData(user?.userId))
        }
    }, [changePasswordRes])

    // modal for password change, for more information read antd modal documentation
    const passwordNotCorrectModal = (
        <Modal
            width={400}
            maskClosable={false}
            centered={true}
            closeIcon={false}
            closable={false}
            footer={false}
            open={currentPasswordNotMatchState}
            className='modal'
            title={false}
        >
            <div className='pt-[15px] pl-[15px] pr-[15px] pb-[10px]'>
                {
                    passwordCondition === "current"
                        ? <div className='text-[#707E89] text-[20px] font-[500] '>Please Enter Old Password Correctly.</div>
                        : null
                }
                {
                    passwordCondition === "new"
                        ? <div className='text-[#707E89] text-[20px] font-[500] text-center'>New Password and Confirm Password Does not Match.</div>
                        : null
                }
                {
                    passwordCondition === "set"
                        ? <div className='text-[#707E89] text-[20px] font-[500] text-center'>Password Changed Successfully.</div>
                        : null
                }
            </div>
            <div className=' flex justify-center pb-[15px]'>
                <span
                    onClick={() => {
                        setCurrentPasswordNotMatchState(false)
                    }}
                    className='cursor-pointer text-[#fff] text-[20px] rounded-[5px] bg-[#1D3557] w-[50px] h-[40px] flex items-center justify-center text-center'
                >OK</span>
            </div>
        </Modal>
    )

    return (
        <Modal
            width={521}
            maskClosable={false}
            centered={true}
            closeIcon={false}
            closable={false}
            footer={false}
            open={changePasswordState}
            className='modal'
            title={
                <div className='flex justify-between items-center pt-[18px] pr-[20px] pb-[16px] pl-[20px]'>
                    <span className='text-[#011C3A] text-[20px] font-[500]'>Change Password</span>
                    <span
                        onClick={() => changePasswordStateFun(false)}
                        className='cursor-pointer text-[#707E89] text-[20px]'
                    >✕</span>
                </div>
            }
        >
            <form
                onSubmit={(e) => changePassword(e)}
                className='w-[100%] flex flex-col items-center'
            >
                <input
                    className='h-[50px] bg-[#ECF5FF] border-b-2 px-[15px] border-[#788186] mb-[10px] border-solid w-[90%]'
                    placeholder='Current password'
                    name="currentPassword"
                    value={currentPassword}
                    onChange={changePasswordFun}
                    type="password"
                    required />
                <input
                    className='h-[50px] bg-[#ECF5FF] border-b-2 px-[15px] border-[#788186] mb-[10px]  border-solid w-[90%]'
                    placeholder='New password'
                    name="newPassword"
                    value={newPassword}
                    onChange={changePasswordFun}
                    type="password"
                    required />
                <input
                    className='h-[50px] bg-[#ECF5FF] border-b-2 px-[15px] border-[#788186] mb-[10px]  border-solid  w-[90%]'
                    placeholder='Confirm password'
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={changePasswordFun}
                    type="password"
                    required />
                <button
                    type='submit'
                    className='bg-[#011c3a] text-[#fff] text-[16px] font-[600] h-[50px] w-[200px] mt-[15px] rounded-[40px] mb-[30px]'
                >Submit</button>
            </form>
            {passwordNotCorrectModal}
        </Modal>
    )
}

export default ChangePasswordModal
