import React from 'react';
import { Modal } from "antd";
import { useSelector } from 'react-redux';

const BillModal = ({ billInvoiceState, billInvoiceStateFun }) => {

    // getting api value from backend in redux
    const { userBillData } = useSelector((state) => state.store);

    // modal for bill and invoice, for more information read antd modal documentation
    return (
        <Modal
            width={521}
            maskClosable={false}
            centered={true}
            closeIcon={false}
            closable={false}
            footer={false}
            open={billInvoiceState}
            className='bill-modal'
            bodyStyle={{
                width: "800px"
            }}
            title={
                <div className='flex justify-between items-center pt-[18px] pr-[20px] pb-[16px] pl-[20px]'>
                    <span className='text-[#011C3A] text-[20px] font-[500]'>Bill & Invoice</span>
                    <span
                        onClick={() => billInvoiceStateFun(false)}
                        className='cursor-pointer text-[#707E89] text-[20px]'
                    >✕</span>
                </div>
            }
        >
            <table width="100%">
                <thead className='bg-[#212529] h-[65px] text-[#fff]'>

                    <tr>
                        <th>S.No</th>
                        <th>
                            <span className='flex flex-col'>

                                <span>Script Name</span>
                                <span>Order Category,Lot</span>
                            </span>
                        </th>
                        <th>

                            <span className='flex flex-col'>
                                <span>Order Price</span>
                                <span>Date & Time</span>
                            </span>
                        </th>
                        <th >
                            <span className='flex flex-col'>
                                <span>Close Price</span>
                                <span>Date & Time</span>
                            </span>
                        </th>
                        <th>Profit/Loss</th>
                        <th>Brokerage</th>
                    </tr>
                </thead>
                <tbody className="border-collapse border-spacing-2 border border-solid border-cee7e7">
                    {
                        userBillData.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className='border border-solid border-[#cee7e7] py-3 px-2'>
                                        {index + 1}
                                    </td>

                                    <td className='border border-solid border-[#cee7e7] py-3 px-2'>
                                        <span className='flex flex-col'>
                                            <span>{item.ScriptName}</span>
                                            <span>BUY({item.Lot})</span>
                                        </span>
                                    </td>

                                    <td className='border border-solid border-[#cee7e7] py-3 px-2'>
                                        <span className='flex flex-col items-center'>
                                            <span>{item.OrderPrice}</span>
                                            <span>{item.ClosedAt}</span>
                                            <span>{item.OrderTime}</span>
                                        </span>
                                    </td>

                                    <td className='border border-solid border-[#cee7e7] py-3 px-2'>
                                        <span className='flex flex-col items-center'>
                                            <span>{item.BroughtBy}</span>
                                            <span>{item.ClosedAt}</span>
                                            <span>{item.ClosedTime}</span>
                                        </span>
                                    </td>

                                    <td className='border border-solid border-[#cee7e7] py-3 px-2'>
                                        P_L
                                    </td>
                                    <td className='border border-solid border-[#cee7e7] py-3 px-2'>
                                        {item.Brokerage}
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </Modal>
    )
}

export default BillModal
