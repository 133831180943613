import React, { useEffect, useState } from 'react';
import { Modal } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import { raiseCompliantApi } from "../../../redux/actions";

const RaiseCompliantModal = ({ raiseComplaintState, raiseComplaintStateFun }) => {
    const dispatch = useDispatch();

    // useState var for succesfully update and input fields
    const [raiseCompliantSuccess, setRaiseCompliantSucess] = useState(false);
    const [raiseCompliantVar, setCaiseCompliantVar] = useState({
        userName: "",
        phoneNo: "",
        whatsAppNo: "",
        message: "",
    });
     
    // getting data from api using redux
    const { raiseCompliant } = useSelector((state) => state.store);

    // checking if we get correct http code from api and opening new modal that compliant is raised successfully.
    useEffect(() => {
        if (raiseCompliant?.ResponseCode === "200") {
            setRaiseCompliantSucess(true);
        }
    }, [raiseCompliant])

    // destructing input fields from useState var
    const { userName, phoneNo, whatsAppNo, message } = raiseCompliantVar;

    // taking values from input field
    function raiseCompliantFun(e) {
        setCaiseCompliantVar({ ...raiseCompliantVar, [e.target.name]: e.target.value });
    }

    // submitting form function
    function raiseCompliantForm(e) {
        e.preventDefault();
        dispatch(raiseCompliantApi(raiseCompliantVar));
    }

    // modal if compliant is raised successfully, for more information read antd modal documentation
    const SuccessModal = (
        <Modal
            width={400}
            maskClosable={false}
            centered={true}
            closeIcon={false}
            closable={false}
            footer={false}
            open={raiseCompliantSuccess}
            className='modal'
            title={false}
        >
            <div className='pt-[15px] pl-[15px] pr-[15px] pb-[10px]'>
                <div className='text-[#707E89] text-[20px] font-[500] text-center'>{raiseCompliant?.ResponseMessage}</div>
            </div>
            <div className=' flex justify-center pb-[15px]'>
                <span
                    onClick={() => {
                        setRaiseCompliantSucess(false);
                        setCaiseCompliantVar({
                            userName: "",
                            phoneNo: "",
                            whatsAppNo: "",
                            message: "",
                        });

                    }}
                    className='cursor-pointer text-[#fff] text-[20px] rounded-[5px] bg-[#1D3557] w-[50px] h-[40px] flex items-center justify-center text-center'
                >OK</span>
            </div>
        </Modal>
    )

    // modal for compliant raised, for more information read antd modal documentation
    return (
        <Modal
            width={521}
            maskClosable={false}
            centered={true}
            closeIcon={false}
            closable={false}
            footer={false}
            open={raiseComplaintState}
            className='modal'
            title={
                <div className='flex justify-between items-center pt-[18px] pr-[20px] pb-[16px] pl-[20px]'>
                    <span className='text-[#011C3A] text-[20px] font-[500]'>Raise Complaint</span>
                    <span
                        onClick={() => raiseComplaintStateFun(false)}
                        className='cursor-pointer text-[#707E89] text-[20px]'
                    >✕</span>
                </div>
            }
        >
            <form
                onSubmit={(e) => raiseCompliantForm(e)}
                className='w-[100%] flex flex-col items-center'
            >
                <input
                    className='h-[50px] bg-[#ECF5FF] border-b-2 px-[15px] border-[#788186] mb-[10px] border-solid w-[90%]'
                    placeholder='Name'
                    name="userName"
                    value={userName}
                    onChange={raiseCompliantFun}
                    type="text"
                    required />
                <input
                    className='h-[50px] bg-[#ECF5FF] border-b-2 px-[15px] border-[#788186] mb-[10px]  border-solid w-[90%]'
                    placeholder='Phone Number'
                    name="phoneNo"
                    value={phoneNo}
                    onChange={raiseCompliantFun}
                    type="number"
                    required />
                <input
                    className='h-[50px] bg-[#ECF5FF] border-b-2 px-[15px] border-[#788186] mb-[10px]  border-solid  w-[90%]'
                    placeholder='Whatsapp number'
                    name="whatsAppNo"
                    value={whatsAppNo}
                    onChange={raiseCompliantFun}
                    type="number"
                    required />
                <input
                    className='h-[50px] bg-[#ECF5FF] border-b-2 px-[15px] border-[#788186] mb-[10px]  border-solid  w-[90%]'
                    placeholder='Description'
                    name="message"
                    value={message}
                    onChange={raiseCompliantFun}
                    type="text"
                    required />
                <button
                    type='submit'
                    className='bg-[#011c3a] text-[#fff] text-[16px] font-[600] h-[50px]  w-[200px]  mt-[15px] rounded-[40px] mb-[30px]'
                >Submit</button>
            </form>
            {SuccessModal}
        </Modal>
    )
}

export default RaiseCompliantModal
