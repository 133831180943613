export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGIN_RESET = "USER_LOGIN_RESET";

export const USER_LOGOUT = "USER_LOGOUT";

export const MCX_MODAL_DATA_REQUEST = "MCX_MODAL_DATA_REQUEST";
export const MCX_MODAL_DATA_SUCCESS = "MCX_MODAL_DATA_SUCCESS";
export const MCX_MODAL_DATA_ERROR = "MCX_MODAL_DATA_ERROR";

export const NSE_MODAL_DATA_REQUEST = "NSE_MODAL_DATA_REQUEST";
export const NSE_MODAL_DATA_SUCCESS = "NSE_MODAL_DATA_SUCCESS";
export const NSE_MODAL_DATA_ERROR = "NSE_MODAL_DATA_ERROR";

export const OPT_MODAL_DATA_REQUEST = "OPT_MODAL_DATA_REQUEST";
export const OPT_MODAL_DATA_SUCCESS = "OPT_MODAL_DATA_SUCCESS";
export const OPT_MODAL_DATA_ERROR = "OPT_MODAL_DATA_ERROR"

export const MCX_TABLE_DATA_REQUEST = "MCX_TABLE_DATA_REQUEST";
export const MCX_TABLE_DATA_SUCCESS = "MCX_TABLE_DATA_SUCCESS";
export const MCX_TABLE_DATA_ERROR = "MCX_TABLE_DATA_ERROR";

export const NSE_TABLE_DATA_REQUEST = "NSE_TABLE_DATA_REQUEST";
export const NSE_TABLE_DATA_SUCCESS = "NSE_TABLE_DATA_SUCCESS";
export const NSE_TABLE_DATA_ERROR = "NSE_TABLE_DATA_ERROR";

export const OPT_TABLE_DATA_REQUEST = "OPT_TABLE_DATA_REQUEST";
export const OPT_TABLE_DATA_SUCCESS = "OPT_TABLE_DATA_SUCCESS";
export const OPT_TABLE_DATA_ERROR = "OPT_TABLE_DATA_ERROR";


export const GET_USER_NOTIFICATION_REQUEST = "GET_USER_NOTIFICATION_REQUEST";
export const GET_USER_NOTIFICATION_SUCCESS = "GET_USER_NOTIFICATION_SUCCESS";
export const GET_USER_NOTIFICATION_ERROR = "GET_USER_NOTIFICATION_ERROR";

export const GET_PROFILE_DATA_REQUEST = "GET_PROFILE_DATA_REQUEST";
export const GET_PROFILE_DATA_SUCCESS = "GET_PROFILE_DATA_SUCCESS";
export const GET_PROFILE_DATA_ERROR = "GET_PROFILE_DATA_ERROR";

export const GET_REFRESH_DATA_REQUEST = "GET_REFRESH_DATA_REQUEST";
export const GET_REFRESH_DATA_SUCCESS = "GET_REFRESH_DATA_SUCCESS";
export const GET_REFRESH_DATA_ERROR = "GET_REFRESH_DATA_ERROR";

export const GET_LEDGER_BALANCE_REQUEST = "GET_LEDGER_BALANCE_REQUEST";
export const GET_LEDGER_BALANCE_SUCCESS = "GET_LEDGER_BALANCE_SUCCESS";
export const GET_LEDGER_BALANCE_ERROR = "GET_LEDGER_BALANCE_ERROR";

export const GET_USER_LEDGER_BALANCE_REQUEST = "GET_USER_LEDGER_BALANCE_REQUEST";
export const GET_USER_LEDGER_BALANCE_SUCCESS = "GET_USER_LEDGER_BALANCE_SUCCESS";
export const GET_USER_LEDGER_BALANCE_ERROR = "GET_USER_LEDGER_BALANCE_ERROR";

export const SAVE_EXCHANGE_USER_REQUEST = "SAVE_EXCHANGE_USER_REQUEST";
export const SAVE_EXCHANGE_USER_SUCCESS = "SAVE_EXCHANGE_USER_SUCCESS";
export const SAVE_EXCHANGE_USER_ERROR = "SAVE_EXCHANGE_USER_ERROR";
export const SAVE_EXCHANHE_USER_RESET = "SAVE_EXCHANHE_USER_RESET";

export const DELETE_EXCHANGE_USER_REQUEST = "DELETE_EXCHANGE_USER_REQUEST";
export const DELETE_EXCHANGE_USER_SUCCESS = "DELETE_EXCHANGE_USER_SUCCESS";
export const DELETE_EXCHANGE_USER_ERROR = "DELETE_EXCHANGE_USER_ERROR";
export const DELETE_EXCHANHE_USER_RESET = "DELETE_EXCHANHE_USER_RESET";

export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const GET_TRADE_REQUEST = "GET_TRADE_REQUEST";
export const GET_TRADE_SUCCESS = "GET_TRADE_SUCCESS";
export const GET_TRADE_ERROR = "GET_TRADE_ERROR"

export const USER_BILL_REQUEST = "USER_BILL_REQUEST";
export const USER_BILL_SUCCESS = "USER_BILL_SUCCESS";
export const USER_BILL_ERROR = "USER_BILL_ERROR";

export const GET_CONSOLIDATED_TRADE_REQUEST = "GET_CONSOLIDATED_TRADE_REQUEST";
export const GET_CONSOLIDATED_TRADE_SUCCESS = "GET_CONSOLIDATED_TRADE_SUCCESS";
export const GET_CONSOLIDATED_TRADE_ERROR = "GET_CONSOLIDATED_TRADE_ERROR";

export const GET_CLOSED_ORDER_REQUEST = "GET_CLOSED_ORDER_REQUEST";
export const GET_CLOSED_ORDER_SUCCESS = "GET_CLOSED_ORDER_SUCCESS";
export const GET_CLOSED_ORDER_ERROR = "GET_CLOSED_ORDER_ERROR";

export const GET_NET_PL_REQUEST = "GET_NET_PL_REQUEST";
export const GET_NET_PL_SUCCESS = "GET_NET_PL_SUCCESS";
export const GET_NET_PL_ERROR = "GET_NET_PL_ERROR";

export const GET_MARKET_TIME_REQUEST = "GET_MARKET_TIME_REQUEST";
export const GET_MARKET_TIME_SUCCESS = "GET_MARKET_TIME_SUCCESS";
export const GET_MARKET_TIME_ERROR = "GET_MARKET_TIME_ERROR";
export const GET_MARKET_TIME_RESET = "GET_MARKET_TIME_RESET";

export const SEND_DATA_REQUEST = "SEND_DATA_REQUEST";
export const SEND_DATA_SUCCESS = "SEND_DATA_SUCCESS";
export const SEND_DATA_ERROR = "SEND_DATA_ERROR";

export const CLOSE_TRADE_FROM_PORTFOLIO_REQUEST = "CLOSE_TRADE_FROM_PORTFOLIO_REQUEST";
export const CLOSE_TRADE_FROM_PORTFOLIO_SUCCESS = "CLOSE_TRADE_FROM_PORTFOLIO_SUCCESS";
export const CLOSE_TRADE_FROM_PORTFOLIO_ERROR = "CLOSE_TRADE_FROM_PORTFOLIO_ERROR";
export const CLOSE_TRADE_FROM_PORTFOLIO_RESET = "CLOSE_TRADE_FROM_PORTFOLIO_RESET";

export const CHECK_BEFORE_TRADE_MARKET_REQUEST = "CHECK_BEFORE_TRADE_MARKET_REQUEST";
export const CHECK_BEFORE_TRADE_MARKET_SUCCESS = "CHECK_BEFORE_TRADE_MARKET_SUCCESS";
export const CHECK_BEFORE_TRADE_MARKET_ERROR = "CHECK_BEFORE_TRADE_MARKET_ERROR";
export const CHECK_BEFORE_TRADE_MARKET_RESET = "CHECK_BEFORE_TRADE_MARKET_RESET"

export const SAVE_EXCHANGE_API_MARKET_REQUEST = "SAVE_EXCHANGE_API_MARKET_REQUEST";
export const SAVE_EXCHANGE_API_MARKET_SUCCESS = "SAVE_EXCHANGE_API_MARKET_SUCCESS";
export const SAVE_EXCHANGE_API_MARKET_ERROR = "SAVE_EXCHANGE_API_MARKET_ERROR";
export const SAVE_EXCHANGE_API_MARKET_RESET = "SAVE_EXCHANGE_API_MARKET_RESET"

export const CHECK_BEFORE_TRADE_LIMIT_REQUEST = "CHECK_BEFORE_TRADE_LIMIT_REQUEST";
export const CHECK_BEFORE_TRADE_LIMIT_SUCCESS = "CHECK_BEFORE_TRADE_LIMIT_SUCCESS";
export const CHECK_BEFORE_TRADE_LIMIT_ERROR = "CHECK_BEFORE_TRADE_LIMIT_ERROR";
export const CHECK_BEFORE_TRADE_LIMIT_RESET = "CHECK_BEFORE_TRADE_LIMIT_RESET"

export const SAVE_EXCHANGE_API_LIMIT_REQUEST = "SAVE_EXCHANGE_API_LIMIT_REQUEST";
export const SAVE_EXCHANGE_API_LIMIT_SUCCESS = "SAVE_EXCHANGE_API_LIMIT_SUCCESS";
export const SAVE_EXCHANGE_API_LIMIT_ERROR = "SAVE_EXCHANGE_API_LIMIT_ERROR";
export const SAVE_EXCHANGE_API_LIMIT_RESET = "SAVE_EXCHANGE_API_LIMIT_RESET"

export const ACTIVE_USER_REQUEST = "ACTIVE_USER_REQUEST";
export const ACTIVE_USER_SUCCESS = "ACTIVE_USER_SUCCESS";
export const ACTIVE_USER_ERROR = "ACTIVE_USER_ERROR";

export const RAISE_COMPLIANT_SUCCESS = "RAISE_COMPLIANT_SUCCESS";
export const RAISE_COMPLIANT_ERROR = "RAISE_COMPLIANT_ERROR";

export const DELETE_PENDING_ORDERS_REQUEST = "DELETE_PENDING_ORDERS_REQUEST";
export const DELETE_PENDING_ORDERS_SUCCESS = "DELETE_PENDING_ORDERS_SUCCESS";
export const DELETE_PENDING_ORDERS_ERROR = "DELETE_PENDING_ORDERS_ERROR";
export const DELETE_PENDING_ORDERS_RESET = "DELETE_PENDING_ORDERS_RESET";

export const UPDATE_TABLE_DATA = 'UPDATE_TABLE_DATA';
export const UPDATE_TABLE_RESET = "UPDATE_TABLE_RESET";

export const TRADE_PENDING_CLOSE_REQUEST = "TRADE_PENDING_CLOSE_REQUEST";
export const TRADE_PENDING_CLOSE_SUCCESS = "TRADE_PENDING_CLOSE_SUCCESS";
export const TRADE_PENDING_CLOSE_ERROR = "TRADE_PENDING_CLOSE_ERROR";
export const TRADE_PENDING_CLOSE_RESET = "TRADE_PENDING_CLOSE_RESET";

export const TRADE_ALL_ACTIVE_CLOSE_REQUEST = "TRADE_ALL_ACTIVE_CLOSE_REQUEST";
export const TRADE_ALL_ACTIVE_CLOSE_SUCCESS = "TRADE_ALL_ACTIVE_CLOSE_SUCCESS";
export const TRADE_ALL_ACTIVE_CLOSE_ERROR = "TRADE_ALL_ACTIVE_CLOSE_ERROR";
export const TRADE_ALL_ACTIVE_CLOSE_RESET = "TRADE_ALL_ACTIVE_CLOSE_RESET";

export const TRADE_ACTIVE_CLOSE_REQUEST = "TRADE_ACTIVE_CLOSE_REQUEST";
export const TRADE_ACTIVE_CLOSE_SUCCESS = "TRADE_ACTIVE_CLOSE_SUCCESS";
export const TRADE_ACTIVE_CLOSE_ERROR = "TRADE_ACTIVE_CLOSE_ERROR";
export const TRADE_ACTIVE_CLOSE_RESET = "TRADE_ACTIVE_CLOSE_RESET";