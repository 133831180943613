import React, { useState } from 'react';
import { Modal } from "antd";
import Password from 'antd/es/input/Password';

const PasswordModal = ({ passwordModalOpen, passwordModalOpenFun, passwordNotCorrectModalOpen, passwordNotCorrectModalFun, checkUserPassword, word, setDefaultExchangeWord, activeTab }) => {
    const [input, setInput] = useState("");
    const passwordNotCorrectModal = (
        <Modal
            width={250}
            maskClosable={false}
            centered={true}
            closeIcon={false}
            closable={false}
            footer={false}
            open={passwordNotCorrectModalOpen}
            className='modal'
            title={false}
        >
            <div className='pt-[15px] pl-[15px] pr-[15px] pb-[15px]'>
                <div className='text-[#707E89] text-[20px] font-[500] text-center'>Invalid Password</div>
                <div className='pr-[10px] pl-[10px] flex justify-center pt-[10px]'>
                    <span
                        onClick={() => passwordNotCorrectModalFun(false)}
                        className='cursor-pointer text-[#fff] text-[20px] rounded-[5px] bg-[#1D3557] w-[50px] h-[40px] flex items-center justify-center text-center'
                    >OK</span>
                </div>
            </div>
        </Modal>
    )

    return (
        <Modal
            width={300}
            maskClosable={false}
            centered={true}
            closeIcon={false}
            closable={false}
            footer={false}
            open={passwordModalOpen}
            className='modal'
            title={false}
        >
            <div className='pt-[15px] pl-[15px] pr-[15px] pb-[15px]  flex justify-center items-center flex-col'>
                <div className='text-[#707E89] text-[20px] font-[500] text-center'>Please enter your password to close all {activeTab} trades {word}</div>
                <input placeholder='Enter password' type="Password" className='w-[100%] px-2 text-[16px] py-2 my-2' value={input} onChange={(e) => setInput(e.target.value)} />
                <button className='w-[130px] h-[35px] my-2 text-[#fff] bg-[#119832]' onClick={() => {
                    checkUserPassword(input)
                    setInput("")
                }}>Submit</button>
                <button className='bg-[#e53945]  h-[35px] text-[#fff] mt-2 font-[500] text-[16px] w-[100%]' onClick={() => {
                    passwordModalOpenFun(false);
                    setInput("");
                    setDefaultExchangeWord();
                }}>Don’t close my trades</button>
            </div>
            {passwordNotCorrectModal}
        </Modal>
    )
}

export default PasswordModal
