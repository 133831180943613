import React from 'react';
import { Modal } from "antd";
import { useDispatch } from 'react-redux';
import { tradePendingCloseApiCall } from '../../../redux/actions';
import { closeAllTradefromActiveTab } from '../../../redux/actions';


const ConfirmModal = ({ confirmModalOpen, confirmModalFun, user, exchangeWord, setDefaultExchangeWord, activeTab,mcxnseActiveTradePostData}) => {
    const dispatch = useDispatch();
    return (
        <Modal
            width={300}
            maskClosable={false}
            centered={true}
            closeIcon={false}
            closable={false}
            footer={false}
            open={confirmModalOpen}
            className='modal'
            title={false}

        >
            <div className='pt-[15px] pl-[15px] pr-[15px] pb-[10px] text-[16px] font-[500]'>
                Do you want to close all {exchangeWord} trades
            </div>
            <div className=' flex justify-end pb-[15px] pr-[15px]'>
                <span
                    onClick={() => confirmModalFun(false)}
                    className='cursor-pointer text-[#fff] text-[16px] rounded-[5px] bg-[#1D3557] px-2 mr-[5px] h-[35px] flex items-center justify-center text-center'
                >Cancel</span>
                <span
                    onClick={() => {
                        if (activeTab === "Pending") {
                            dispatch(tradePendingCloseApiCall(user?.userId, exchangeWord));
                        } else if (activeTab === "Active") {
                            dispatch(closeAllTradefromActiveTab(user?.userId, mcxnseActiveTradePostData));
                        }
                        confirmModalFun(false);
                        setDefaultExchangeWord();
                    }}
                    className='cursor-pointer text-[#fff] text-[16px] rounded-[5px] bg-[#1D3557] w-[50px] h-[35 px] flex items-center justify-center text-center'
                >OK</span>
            </div>
        </Modal>

    )
}

export default ConfirmModal
