import React from 'react';
import { Modal } from "antd";
import { CLOSE_TRADE_FROM_PORTFOLIO_RESET } from "../../../redux/constants";
import { useDispatch } from 'react-redux';

const TradeCloseModal = ({ tradeCloseState, tradeCloseFun, closeTradeFromPortfolioData }) => {
    const dispatch = useDispatch();

    return (
        <Modal
            width={350}
            maskClosable={false}
            centered={true}
            closeIcon={false}
            closable={false}
            footer={false}
            open={tradeCloseState}
            className='modal'
            title={false}
        >
            <div className='pt-[15px] pl-[15px] pr-[15px] pb-[15px]'>
                <div className='text-[#707E89] text-[20px] font-[500] text-center'>{closeTradeFromPortfolioData?.ResponseMessage}</div>
                <div className='pr-[10px] pl-[10px] flex justify-center pt-[10px]'>
                    <span
                        onClick={() => {
                            tradeCloseFun(false)
                            dispatch({ type: CLOSE_TRADE_FROM_PORTFOLIO_RESET, data: '' });
                        }}
                        className='cursor-pointer text-[#fff] text-[20px] rounded-[5px] bg-[#1D3557] w-[50px] h-[40px] flex items-center justify-center text-center'
                    >OK</span>
                </div>
            </div>
        </Modal>
    )
}

export default TradeCloseModal
