import React from 'react';
import { Modal } from "antd";

const MarketCloseModal = ({ marketModalOpen, marketModalFun }) => {

    return (
        <Modal
            width={250}
            maskClosable={false}
            centered={true}
            closeIcon={false}
            closable={false}
            footer={false}
            open={marketModalOpen}
            className='modal'
            title={false}
        >
            <div className='pt-[15px] pl-[15px] pr-[15px] pb-[15px]'>
                <div className='text-[#707E89] text-[20px] font-[500] text-center'>Market Not Opened.</div>
                <div className='pr-[10px] pl-[10px] flex justify-center pt-[10px]'>
                    <span
                        onClick={() => {
                            marketModalFun(false)
                        }}
                        className='cursor-pointer text-[#fff] text-[20px] rounded-[5px] bg-[#1D3557] w-[50px] h-[40px] flex items-center justify-center text-center'
                    >OK</span>
                </div>
            </div>
        </Modal>
    )
}

export default MarketCloseModal
