import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "antd";
import Search from "../../../assets/searchIcon.png";
import { saveTokenData, deleteTokenData } from "../../../redux/actions";

const GlobalModal = ({
  globalModalVisible,
  closeModal,
  activeTab,
  mcxSocketData,
  nseSocketData,
  optSocketData,
  user,
  setUpdateTableFun,
}) => {
  const dispatch = useDispatch();
  const [idData, setIdData] = useState(null);
  const [selectedExchange, setSelectedExchange] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [data, setData] = useState([]);
  const { mcxModalData } = useSelector((state) => state.mcxModal);
  const { nseModalData } = useSelector((state) => state.nseModal);
  const { optModalData } = useSelector((state) => state.optModal);
  const { isExchangeSave } = useSelector((state) => state.saveExchange);

  useEffect(() => {
    let table;
    if (activeTab === "mcx") {
      table = mcxModalData ? [...mcxModalData] : [];
      setSearchInput("");
    } else if (activeTab === "nse") {
      table = nseModalData ? [...nseModalData] : [];
      setSearchInput("");
    } else if (activeTab === "opt") {
      table = optModalData ? [...optModalData] : [];
      setSearchInput("");
    }

    // const socket = new WebSocket("wss://ws1.tradewingss.com/api/webapiwebsoc");
    // // Event listener for WebSocket open event
    // socket.addEventListener("open", () => {
    //   // console.log("WebSocket connection established");
    // });

    // // Event listener for WebSocket message event
    // socket.addEventListener("message", (event) => {
    //   const message = event.data;
    //   if (message) {
    //     const newData = JSON.parse(message);
    //     for (let item of table) {
    //       if (newData.instrument_token === parseInt(item.TokenNo)) {
    //         item.buy = newData.buy;
    //         item.sell = newData.sell;
    //         console.log("table",table);
    //       }
    //     }
    //   }
    // });
   
    setData(table);
  }, [mcxModalData, nseModalData, optModalData, activeTab]);

  useEffect(() => {
    if (isExchangeSave?.ResponseCode === "200") {
      const data = [...selectedExchange];
      if (idData?.method === "Add") {
        data.push(idData?.id);
        setSelectedExchange(data);
      } else if (idData?.method === "Remove") {
        let arrayIndex = data.indexOf(idData?.id);
        data.splice(arrayIndex, 1);
        setSelectedExchange(data);
      }
      setUpdateTableFun(idData);
      setIdData(null);
    }
  }, [dispatch, isExchangeSave, idData, selectedExchange, setUpdateTableFun]);

  useEffect(() => {
    let data = [];
    if (activeTab === "mcx") {
      data = mcxSocketData?.map((item) => {
        return item.SymbolToken;
      });
    } else if (activeTab === "nse") {
      data = nseSocketData?.map((item) => {
        return item.SymbolToken;
      });
    } else if (activeTab === "opt") {
      data = optSocketData?.map((item) => {
        return item.SymbolToken;
      });
    }
    
    setSelectedExchange(data);
  }, [mcxSocketData, nseSocketData, optSocketData, activeTab]);

  const isChecked = (item) => (selectedExchange?.includes(item) ? true : false);

  const handleCheckboxChange = (index, exchange, name, lotsize) => {
    let data = [...selectedExchange];
    if (!data.includes(index)) {
      const params = {
        symbolname: name,
        Token: index,
        UserId: user?.userId,
        exchangetype: exchange,
        lotsize: lotsize,
      };
      dispatch(saveTokenData(params));
      const actions = {
        id: index,
        method: "Add",
        exchange: exchange,
      };
      setIdData(actions);
    } else if (data.includes(index)) {
      const params = {
        Token: index,
        UserId: user?.userId,
      };
      dispatch(deleteTokenData(params));
      const actions = {
        id: index,
        method: "Remove",
        exchange: exchange,
      };
      setIdData(actions);
    }
  };

  function splitforExchangeName(inputString) {
    const parts = inputString.split("_");
    return parts[0];
  }

  function formatDate(inputDate) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const [day, month, year] = inputDate.split("/").map(Number);

    const formattedDate = `${day} ${months[month - 1]} ${year}`;
    return formattedDate;
  }

  return (
    <Modal
      width={521}
      maskClosable={false}
      centered={true}
      closeIcon={false}
      closable={false}
      footer={false}
      open={globalModalVisible}
      className="global-modal"
      title={
        <div className="flex justify-between items-center bg-[#fff]">
          <img
            src={Search}
            alt="add-button"
            className="h-[24px] w-[24px]  ml-[7px] cursor-pointer"
          />
          <input
            className="w-full h-[48px] pl-[12px] outline-none"
            placeholder="Search & Add"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <span
            onClick={() => closeModal()}
            className="cursor-pointer text-[#707E89] text-[20px] relative w-[0px] left-[-30px]"
          >
            ✕
          </span>
        </div>
      }
    >
      {data
        ?.filter((item) =>
          item?.symbolname?.toLowerCase()?.includes(searchInput.toLowerCase())
        )
        ?.map((item) => {
          return (
            <React.Fragment key={item.instrument_token}>
              <div className="pl-[5%] pr-[5%] flex justify-between text-[16px] font-[500] tracking-[0.04em]">
                <div className="flex justify-between w-full">
                  <div className="flex flex-col w-[56%]">
                    <span className="text-[14px]  text-[#383838]">
                      {splitforExchangeName(item.symbolname)}
                    </span>
                  </div>

                  <div className="flex w-[44%]">
                    <span
                      id={"global_Modal_bid" + item.instrument_token}
                      className="w-[50%] text-[14px] text-right inline-block text-[#006400]"
                    >
                      {item.sell !== "" ? item.sell : 0}
                    </span>
                    <span
                      id={"global_Modal_ask" + item.instrument_token}
                      className="w-[50%] text-[14px] text-right inline-block text-[#006400]"
                    >
                      {item.buy !== "" ? item.buy : 0}
                    </span>
                  </div>
                </div>
              </div>
              <div className="pt-[3px] pl-[5%] pr-[5%] flex justify-between tracking-[0.04em] text-[#707E89] text-[13px] font-[500]">
                <span>{formatDate(item.expirydate)}</span>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox text-indigo-600 h-5 w-5"
                    value={item.instrument_token}
                    onChange={() =>
                      handleCheckboxChange(
                        item.instrument_token.toString(),
                        item.Exchange,
                        item.symbolname,
                        item.lotsize
                      )
                    }
                    checked={isChecked(item.instrument_token.toString())}
                  />
                </label>
              </div>
              <div className="pt-[3px] pl-[5%] pr-[5%] flex justify-between tracking-[0.04em] text-[#707E89] text-[13px] font-[500]">
                <span>Lot Size: {item.lotsize}</span>
              </div>
              <hr className="h-[1.5px] bg-[#D6DFE8] w-[90%] mt-[15px] mb-[15px] mx-auto " />
            </React.Fragment>
          );
        })}
    </Modal>
  );
};

export default GlobalModal;
