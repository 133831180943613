import {
    MCX_MODAL_DATA_REQUEST,
    MCX_MODAL_DATA_SUCCESS,
    MCX_MODAL_DATA_ERROR,
    NSE_MODAL_DATA_REQUEST,
    NSE_MODAL_DATA_SUCCESS,
    NSE_MODAL_DATA_ERROR,
    OPT_MODAL_DATA_REQUEST,
    OPT_MODAL_DATA_SUCCESS,
    OPT_MODAL_DATA_ERROR,
    MCX_TABLE_DATA_REQUEST,
    MCX_TABLE_DATA_SUCCESS,
    MCX_TABLE_DATA_ERROR,
    NSE_TABLE_DATA_REQUEST,
    NSE_TABLE_DATA_SUCCESS,
    NSE_TABLE_DATA_ERROR,
    OPT_TABLE_DATA_REQUEST,
    OPT_TABLE_DATA_SUCCESS,
    OPT_TABLE_DATA_ERROR,
    SAVE_EXCHANGE_USER_REQUEST,
    SAVE_EXCHANGE_USER_SUCCESS,
    SAVE_EXCHANGE_USER_ERROR,
    DELETE_EXCHANGE_USER_REQUEST,
    DELETE_EXCHANGE_USER_SUCCESS,
    DELETE_EXCHANGE_USER_ERROR,
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,
    USER_LOGOUT,
    UPDATE_TABLE_DATA,
    GET_USER_NOTIFICATION_REQUEST,
    GET_USER_NOTIFICATION_SUCCESS,
    GET_USER_NOTIFICATION_ERROR,
    GET_PROFILE_DATA_REQUEST,
    GET_PROFILE_DATA_SUCCESS,
    GET_PROFILE_DATA_ERROR,
    GET_LEDGER_BALANCE_REQUEST,
    GET_LEDGER_BALANCE_SUCCESS,
    GET_LEDGER_BALANCE_ERROR,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_ERROR,
    GET_TRADE_REQUEST,
    GET_TRADE_SUCCESS,
    GET_TRADE_ERROR,
    USER_BILL_REQUEST,
    USER_BILL_SUCCESS,
    USER_BILL_ERROR,
    GET_CONSOLIDATED_TRADE_REQUEST,
    GET_CONSOLIDATED_TRADE_SUCCESS,
    GET_CONSOLIDATED_TRADE_ERROR,
    GET_REFRESH_DATA_REQUEST,
    GET_REFRESH_DATA_SUCCESS,
    GET_REFRESH_DATA_ERROR,
    GET_USER_LEDGER_BALANCE_REQUEST,
    GET_USER_LEDGER_BALANCE_SUCCESS,
    GET_USER_LEDGER_BALANCE_ERROR,
    GET_CLOSED_ORDER_REQUEST,
    GET_CLOSED_ORDER_SUCCESS,
    GET_CLOSED_ORDER_ERROR,
    GET_NET_PL_REQUEST,
    GET_NET_PL_SUCCESS,
    GET_NET_PL_ERROR,
    GET_MARKET_TIME_REQUEST,
    GET_MARKET_TIME_SUCCESS,
    GET_MARKET_TIME_ERROR,
    CLOSE_TRADE_FROM_PORTFOLIO_REQUEST,
    CLOSE_TRADE_FROM_PORTFOLIO_SUCCESS,
    CLOSE_TRADE_FROM_PORTFOLIO_ERROR,
    CHECK_BEFORE_TRADE_MARKET_REQUEST,
    CHECK_BEFORE_TRADE_MARKET_SUCCESS,
    CHECK_BEFORE_TRADE_MARKET_ERROR,
    SAVE_EXCHANGE_API_MARKET_REQUEST,
    SAVE_EXCHANGE_API_MARKET_SUCCESS,
    SAVE_EXCHANGE_API_MARKET_ERROR,
    CHECK_BEFORE_TRADE_LIMIT_REQUEST,
    CHECK_BEFORE_TRADE_LIMIT_SUCCESS,
    CHECK_BEFORE_TRADE_LIMIT_ERROR,
    SAVE_EXCHANGE_API_LIMIT_REQUEST,
    SAVE_EXCHANGE_API_LIMIT_SUCCESS,
    SAVE_EXCHANGE_API_LIMIT_ERROR,
    ACTIVE_USER_REQUEST,
    ACTIVE_USER_SUCCESS,
    ACTIVE_USER_ERROR,
    RAISE_COMPLIANT_SUCCESS,
    RAISE_COMPLIANT_ERROR,
    DELETE_PENDING_ORDERS_REQUEST,
    DELETE_PENDING_ORDERS_SUCCESS,
    DELETE_PENDING_ORDERS_ERROR,
    TRADE_PENDING_CLOSE_REQUEST,
    TRADE_PENDING_CLOSE_SUCCESS,
    TRADE_PENDING_CLOSE_ERROR,
    TRADE_ALL_ACTIVE_CLOSE_REQUEST,
    TRADE_ALL_ACTIVE_CLOSE_SUCCESS,
    TRADE_ALL_ACTIVE_CLOSE_ERROR,
    TRADE_ACTIVE_CLOSE_REQUEST,
    TRADE_ACTIVE_CLOSE_SUCCESS,
    TRADE_ACTIVE_CLOSE_ERROR
} from "./constants";
import axios from "axios";

// get all shops
export const getMcxPlusData = (type, search, userId) => async (dispatch) => {
    try {
        dispatch({
            type: MCX_MODAL_DATA_REQUEST,
        });

        const config = { headers: { "Content-Type": "multipart/form-data" } };

        const { data } = await axios.post(`https://services.tradewingss.com/api/tradingclient/gettradesymbols?extype=${type}&searchkey=${search}&UserId=${userId}`, config);

        const parsedData = JSON.parse(data);

        dispatch({
            type: MCX_MODAL_DATA_SUCCESS,
            payload: parsedData,
        });
    } catch (error) {
        dispatch({
            type: MCX_MODAL_DATA_ERROR,
            payload: error
        });
    }
};

// get all shops
export const getNsePlusData = (type, search, userId) => async (dispatch) => {
    try {
        dispatch({
            type: NSE_MODAL_DATA_REQUEST,
        });

        const config = { headers: { "Content-Type": "multipart/form-data" } };

        const { data } = await axios.post(`https://services.tradewingss.com/api/tradingclient/gettradesymbols?extype=${type}&searchkey=${search}&UserId=${userId}`, config);

        const parsedData = JSON.parse(data);

        dispatch({
            type: NSE_MODAL_DATA_SUCCESS,
            payload: parsedData,
        });
    } catch (error) {
        dispatch({
            type: NSE_MODAL_DATA_ERROR,
            payload: error
        });
    }
};


// get all shops
export const getOtpPlusData = (type, search, userId) => async (dispatch) => {
    try {
        dispatch({
            type: OPT_MODAL_DATA_REQUEST,
        });

        const config = { headers: { "Content-Type": "multipart/form-data" } };

        const { data } = await axios.post(`https://services.tradewingss.com/api/tradingclient/gettradesymbols?extype=${type}&searchkey=${search}&UserId=${userId}`, config);

        const parsedData = JSON.parse(data);

        dispatch({
            type: OPT_MODAL_DATA_SUCCESS,
            payload: parsedData,
        });
    } catch (error) {
        dispatch({
            type: OPT_MODAL_DATA_ERROR,
            payload: error
        });
    }
};

export const getMcxTableData = (id, exchange) => async (dispatch) => {
    try {
        dispatch({ type: MCX_TABLE_DATA_REQUEST });

        const { data } = await axios.get(`https://services.tradewingss.com/api/tradingclient/getselectedtoken/?cid=${id}&exch=${exchange}`);

        const parsedData = JSON.parse(data);

        dispatch({ type: MCX_TABLE_DATA_SUCCESS, payload: parsedData });
    } catch (error) {
        dispatch({ type: MCX_TABLE_DATA_ERROR, payload: error });
    }
};

export const getNseTableData = (id, exchange) => async (dispatch) => {
    try {
        dispatch({ type: NSE_TABLE_DATA_REQUEST });

        const { data } = await axios.get(`https://services.tradewingss.com/api/tradingclient/getselectedtoken/?cid=${id}&exch=${exchange}`);

        const parsedData = JSON.parse(data);

        dispatch({ type: NSE_TABLE_DATA_SUCCESS, payload: parsedData });
    } catch (error) {
        dispatch({ type: NSE_TABLE_DATA_ERROR, payload: error });
    }
};


export const getOptTableData = (id, exchange) => async (dispatch) => {
    try {
        dispatch({ type: OPT_TABLE_DATA_REQUEST });

        const { data } = await axios.get(`https://services.tradewingss.com/api/tradingclient/getselectedtoken/?cid=${id}&exch=${exchange}`);

        const parsedData = JSON.parse(data);

        dispatch({ type: OPT_TABLE_DATA_SUCCESS, payload: parsedData });
    } catch (error) {
        dispatch({ type: OPT_TABLE_DATA_ERROR, payload: error });
    }
};



// get all shops
export const saveTokenData = (params) => async (dispatch) => {
    try {
        dispatch({
            type: SAVE_EXCHANGE_USER_REQUEST,
        });

        const config = { headers: { "Content-Type": 'application/json' } };

        const { data } = await axios.post(`https://services.tradewingss.com/api/tradingclient/savetokenbyuser?Token=${params.Token}&UserId=${params.UserId}&exchangetype=${params.exchangetype}&lotsize=${params.lotsize}&symbolname=${params.symbolname}`,
            params,
            config
        );

        const parsedData = JSON.parse(data);

        dispatch({
            type: SAVE_EXCHANGE_USER_SUCCESS,
            payload: parsedData,
        });
    } catch (error) {
        dispatch({
            type: SAVE_EXCHANGE_USER_ERROR,
            payload: error
        });
    }
};

// get all shops
export const deleteTokenData = (params) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_EXCHANGE_USER_REQUEST,
        });

        const config = { headers: { "Content-Type": 'application/json' } }

        const { data } = await axios.post(`https://services.tradewingss.com/api/tradingclient/removetoken?Token=${params.Token}&UserId=${params.UserId}`,
            params,
            config
        );

        const parsedData = JSON.parse(data);

        dispatch({
            type: DELETE_EXCHANGE_USER_SUCCESS,
            payload: parsedData,
        });
    } catch (error) {
        dispatch({
            type: DELETE_EXCHANGE_USER_ERROR,
            payload: error
        });
    }
};


export const postLogin = (email, password) => async (dispatch) => {
    try {
        dispatch({ type: USER_LOGIN_REQUEST });

        const config = { headers: { "Content-Type": "application/json" } };

        const { data } = await axios.post(`https://services.tradewingss.com/api/tradingclient/authlogin?username=${email}&password=${password}`, config);

        const parsedData = JSON.parse(data);

        dispatch({ type: USER_LOGIN_SUCCESS, payload: parsedData });
    } catch (error) {
        dispatch({ type: USER_LOGIN_FAIL, payload: error });
    }
};

export const updateTableData = (data) => ({
    type: UPDATE_TABLE_DATA,
    payload: data,
});

export const logout = () => ({
    type: USER_LOGOUT,
});

export const getNotificationData = (userid, RefId) => async (dispatch) => {
    try {
        dispatch({ type: GET_USER_NOTIFICATION_REQUEST });

        const { data } = await axios.get(`https://services.tradewingss.com/api/tradingclient/getnotification/?userid=${userid}&refid=${RefId}`);

        const parsedData = JSON.parse(data);

        dispatch({ type: GET_USER_NOTIFICATION_SUCCESS, payload: parsedData });
    } catch (error) {
        dispatch({ type: GET_USER_NOTIFICATION_ERROR, payload: error });
    }
};

export const getProfileData = (userid) => async (dispatch) => {
    try {
        dispatch({ type: GET_PROFILE_DATA_REQUEST });

        const { data } = await axios.get(`https://services.tradewingss.com/api/tradingclient/profiledata/?userid=${userid}`);

        const parsedData = JSON.parse(data);

        dispatch({ type: GET_PROFILE_DATA_SUCCESS, payload: parsedData });
    } catch (error) {
        dispatch({ type: GET_PROFILE_DATA_ERROR, payload: error });
    }
};

export const getLedgerBalance = (userid) => async (dispatch) => {
    try {
        dispatch({ type: GET_LEDGER_BALANCE_REQUEST });

        const { data } = await axios.get(`https://services.tradewingss.com/api/tradingclient/getledgerbalance/?uid=${userid}`);

        dispatch({ type: GET_LEDGER_BALANCE_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: GET_LEDGER_BALANCE_ERROR, payload: error });
    }
};

export const changePasswordApi = (oldPass, userId, newPass) => async (dispatch) => {
    try {
        dispatch({ type: CHANGE_PASSWORD_REQUEST });

        const config = { headers: { "Content-Type": "application/json" } };

        const { data } = await axios.post(`https://services.tradewingss.com/api/tradingclient/chgpwd?Oldpass=${oldPass}&UserId=${userId}&newpass=${newPass}`, config);

        const parsedData = JSON.parse(data);

        dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: parsedData });
    } catch (error) {
        dispatch({ type: CHANGE_PASSWORD_ERROR, payload: error });
    }
};


export const getTradeData = (method, userid) => async (dispatch) => {
    try {
        dispatch({ type: GET_TRADE_REQUEST });

        const { data } = await axios.get(`https://services.tradewingss.com/api/tradingclient/getorders/?orderstatus=${method}&uid=${userid}`);

        const parsedData = JSON.parse(data);

        dispatch({ type: GET_TRADE_SUCCESS, payload: parsedData });
    } catch (error) {
        dispatch({ type: GET_TRADE_ERROR, payload: error });
    }
};

export const getUserBillData = (userid) => async (dispatch) => {
    try {
        dispatch({ type: USER_BILL_REQUEST });

        const { data } = await axios.get(`https://services.tradewingss.com/api/tradingclient/getuserbill/?UserId=${userid}`);

        const parsedData = JSON.parse(data);

        dispatch({ type: USER_BILL_SUCCESS, payload: parsedData });
    } catch (error) {
        dispatch({ type: USER_BILL_ERROR, payload: error });
    }
};

export const getConsolidatedTrade = (userid) => async (dispatch) => {
    try {
        dispatch({ type: GET_CONSOLIDATED_TRADE_REQUEST });

        const { data } = await axios.get(`https://services.tradewingss.com/api/tradingclient/getconsolidatedtrade/?uid=${userid}`);

        const parsedData = JSON.parse(data);

        dispatch({ type: GET_CONSOLIDATED_TRADE_SUCCESS, payload: parsedData });
    } catch (error) {
        dispatch({ type: GET_CONSOLIDATED_TRADE_ERROR, payload: error });
    }
};

export const getRefreshData = (userid) => async (dispatch) => {
    try {
        dispatch({ type: GET_REFRESH_DATA_REQUEST });

        const { data } = await axios.get(`https://services.tradewingss.com/api/tradingclient/refreshdata/?userid=${userid}`);

        const parsedData = JSON.parse(data);

        dispatch({ type: GET_REFRESH_DATA_SUCCESS, payload: parsedData });
    } catch (error) {
        dispatch({ type: GET_REFRESH_DATA_ERROR, payload: error });
    }
};

export const getUserLedgerBalance = (userid) => async (dispatch) => {
    try {
        dispatch({ type: GET_USER_LEDGER_BALANCE_REQUEST });

        const { data } = await axios.get(`https://services.tradewingss.com/api/tradingclient/getuserbalanceledger/?uid=${userid}`);

        const parsedData = JSON.parse(data);

        dispatch({ type: GET_USER_LEDGER_BALANCE_SUCCESS, payload: parsedData });
    } catch (error) {
        dispatch({ type: GET_USER_LEDGER_BALANCE_ERROR, payload: error });
    }
};

export const getUserClosedOrders = (params) => async (dispatch) => {
    try {
        dispatch({
            type: GET_CLOSED_ORDER_REQUEST,
        });

        const config = { headers: { "Content-Type": 'application/json' } };

        const { data } = await axios.post(`https://services.tradewingss.com/api/tradingclient/getuserclosedorders?UserId=${params}`,
            config
        );

        const parsedData = JSON.parse(data);

        dispatch({
            type: GET_CLOSED_ORDER_SUCCESS,
            payload: parsedData,
        });
    } catch (error) {
        dispatch({
            type: GET_CLOSED_ORDER_ERROR,
            payload: error
        });
    }
};

export const getUserNetPLData = (params) => async (dispatch) => {
    try {
        dispatch({
            type: GET_NET_PL_REQUEST,
        });

        const config = { headers: { "Content-Type": 'application/json' } };

        const { data } = await axios.post(`https://services.tradewingss.com/api/tradingclient/getnetpl?UserId=${params}`,
            config
        );

        const parsedData = JSON.parse(data);

        dispatch({
            type: GET_NET_PL_SUCCESS,
            payload: parsedData,
        });
    } catch (error) {
        dispatch({
            type: GET_NET_PL_ERROR,
            payload: error
        });
    }
};

export const getMarketTimeData = (exchange, refid) => async (dispatch) => {
    try {
        dispatch({ type: GET_MARKET_TIME_REQUEST });
        const { data } = await axios.get(`https://services.tradewingss.com/api/tradingclient/getmarkettime/?Exchange=${exchange}&refid=${refid}`);

        dispatch({ type: GET_MARKET_TIME_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: GET_MARKET_TIME_ERROR, payload: error });
    }
};

export const getCloseTradeFromPortfolioData = (userid, ordercat, tokenno, cmpval) => async (dispatch) => {
    try {
        dispatch({ type: CLOSE_TRADE_FROM_PORTFOLIO_REQUEST });

        const { data } = await axios.get(`https://services.tradewingss.com/api/tradingclient/closetrade_from_account_portfolio_app/?userid=${userid}&ordercat=${ordercat}&tokenno=${tokenno}&cmpval=${cmpval}`);

        const parsedData = JSON.parse(data);

        dispatch({ type: CLOSE_TRADE_FROM_PORTFOLIO_SUCCESS, payload: parsedData });
    } catch (error) {
        dispatch({ type: CLOSE_TRADE_FROM_PORTFOLIO_ERROR, payload: error });
    }
};

export const getCheckBeforeTradeMarketData = (params) => async (dispatch) => {
    try {
       
        dispatch({ type: CHECK_BEFORE_TRADE_MARKET_REQUEST });

        const { data } = await axios.get(`https://services.tradewingss.com/api/tradingclient/checkbeforetrade_app/?Id=${params.Id}&OrderDate=${params.OrderDate}&OrderTime=${params.OrderTime}&actualLot=${params.actualLot}&selectedlotsize=${params.selectedlotsize}&OrderNo=${params.OrderNo}&UserId=${params.UserId}&UserName=${params.UserName}&OrderCategory=${params.OrderCategory}&OrderType=${params.OrderType}&ScriptName=${params.ScriptName}&TokenNo=${params.TokenNo}&ActionType=${params.ActionType}&OrderPrice=${params.OrderPrice}&Lot=${params.Lot}&MarginUsed=${params.MarginUsed}&HoldingMarginReq=${params.HoldingMarginReq}&OrderStatus=${params.OrderStatus}&SymbolType=${params.SymbolType}`);

        dispatch({ type: CHECK_BEFORE_TRADE_MARKET_SUCCESS, payload: data });

    } catch (error) {
        dispatch({ type: CHECK_BEFORE_TRADE_MARKET_ERROR, payload: error });
    }
};

export const getCheckBeforeTradeLimitData = (params) => async (dispatch) => {

    try {
        dispatch({ type: CHECK_BEFORE_TRADE_LIMIT_REQUEST });

        const { data } = await axios.get(`https://services.tradewingss.com/api/tradingclient/checkbeforetradeForPending_api/?Id=${params.Id}&OrderDate=${params.OrderDate}&OrderTime=${params.OrderTime}&actualLot=${params.actualLot}&selectedlotsize=${params.selectedlotsize}&OrderNo=${params.OrderNo}&UserId=${params.UserId}&UserName=${params.UserName}&OrderCategory=${params.OrderCategory}&OrderType=${params.OrderType}&isstoplossorder=${params.isstoplossorder}&ScriptName=${params.ScriptName}&TokenNo=${params.TokenNo}&ActionType=${params.ActionType}&OrderPrice=${params.OrderPrice}&Lot=${params.Lot}&MarginUsed=${params.MarginUsed}&HoldingMarginReq=${params.HoldingMarginReq}&OrderStatus=${params.OrderStatus}&SymbolType=${params.SymbolType}&isedit=${params.isedit}`);

        dispatch({ type: CHECK_BEFORE_TRADE_LIMIT_SUCCESS, payload: data });


    } catch (error) {
        dispatch({ type: CHECK_BEFORE_TRADE_LIMIT_ERROR, payload: error });
    }
};


export const saveExchangeOrderMarketModal = (params) => async (dispatch) => {
 

    try {
        dispatch({ type: SAVE_EXCHANGE_API_MARKET_REQUEST });


        const { data } = await axios.get(`https://services.tradewingss.com/api/tradingclient/saveorders_app/?Id=${params.Id}&OrderDate=${params.OrderDate}&OrderTime=${params.OrderTime}&actualLot=${params.actualLot}&selectedlotsize=${params.selectedlotsize}&OrderNo=${params.OrderNo}&UserId=${params.UserId}&UserName=${params.UserName}&OrderCategory=${params.OrderCategory}&OrderType=${params.OrderType}&ScriptName=${params.ScriptName}&TokenNo=${params.TokenNo}&ActionType=${params.ActionType}&OrderPrice=${params.OrderPrice}&Lot=${params.Lot}&MarginUsed=${params.MarginUsed}&HoldingMarginReq=${params.HoldingMarginReq}&OrderStatus=${params.OrderStatus}&SymbolType=${params.SymbolType}`);

        dispatch({ type: SAVE_EXCHANGE_API_MARKET_SUCCESS, payload: data });

    } catch (error) {
        dispatch({ type: SAVE_EXCHANGE_API_MARKET_ERROR, payload: error });
    }
};


export const saveExchangeOrderLimitModal = (params) => async (dispatch) => {
   
    try {
        dispatch({ type: SAVE_EXCHANGE_API_LIMIT_REQUEST });


        const { data } = await axios.get(`https://services.tradewingss.com/api/tradingclient/saveorders_app/?Id=${params.Id}&OrderDate=${params.OrderDate}&OrderTime=${params.OrderTime}&actualLot=${params.actualLot}&selectedlotsize=${params.selectedlotsize}&OrderNo=${params.OrderNo}&UserId=${params.UserId}&UserName=${params.UserName}&OrderCategory=${params.OrderCategory}&OrderType=${params.OrderType}&isstoplossorder=${params.isstoplossorder}&ScriptName=${params.ScriptName}&TokenNo=${params.TokenNo}&ActionType=${params.ActionType}&OrderPrice=${params.OrderPrice}&Lot=${params.Lot}&MarginUsed=${params.MarginUsed}&HoldingMarginReq=${params.HoldingMarginReq}&OrderStatus=${params.OrderStatus}&SymbolType=${params.SymbolType}&isedit=${params.isedit}`);

        dispatch({ type: SAVE_EXCHANGE_API_LIMIT_SUCCESS, payload: data });

    } catch (error) {
        dispatch({ type: SAVE_EXCHANGE_API_LIMIT_ERROR, payload: error });
    }
};


export const activeStatusApi = (id) => async (dispatch) => {
    try {
        dispatch({ type: ACTIVE_USER_REQUEST });

        const { data } = await axios.get(`https://services.tradewingss.com/api/tradingclient/changeactivestatus/?userid=${id}`);

        dispatch({ type: ACTIVE_USER_SUCCESS, data });
    } catch (error) {
        dispatch({ type: ACTIVE_USER_ERROR, payload: error });
    }
};

export const raiseCompliantApi = (params) => async (dispatch) => {
    try {

        const config = { headers: { "Content-Type": "application/json" } };

        const { data } = await axios.post(`https://services.tradewingss.com/api/tradingclient/contactus?Name=${params.userName}&callno=${params.phoneNo}&whatsappno=${params.whatsAppNo}&rentsoftware=null&sharesoftware=null&complain=null&whitelevel=null&others=null&message=${params.message}`, config);

        const parsedData = JSON.parse(data);

        dispatch({ type: RAISE_COMPLIANT_SUCCESS, payload: parsedData });
    } catch (error) {
        dispatch({ type: RAISE_COMPLIANT_ERROR, payload: error });
    }
};


export const deletePendigOrderApi = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PENDING_ORDERS_REQUEST });

        const { data } = await axios.get(`https://services.tradewingss.com/api/tradingclient/deleteorder/?orderid=${id}`);

        dispatch({ type: DELETE_PENDING_ORDERS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: DELETE_PENDING_ORDERS_ERROR, payload: error });
    }
};

export const tradePendingCloseApiCall = (id, type) => async (dispatch) => {
    try {

        dispatch({ type: TRADE_PENDING_CLOSE_REQUEST });

        const { data } = await axios.get(`https://services.tradewingss.com/api/tradingclient/canceleallorderfromapp?UserId=${id}&SymbolType=${type}`);

        const parsedData = JSON.parse(data);

        dispatch({ type: TRADE_PENDING_CLOSE_SUCCESS, payload: parsedData });
    } catch (error) {
        dispatch({ type: TRADE_PENDING_CLOSE_ERROR, payload: error });
    }
};

export const closeAllTradefromActiveTab = (id, params) => async (dispatch) => {
    let responseData;
    // try {
        dispatch({ type: TRADE_ALL_ACTIVE_CLOSE_REQUEST })
 
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("userid", id);
        urlencoded.append("idcmp", JSON.stringify(params));
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };
         await fetch("https://services.tradewingss.com/api/tradingclient/closealltradesbyapp", requestOptions)
        .then(response => response.text())
        .then((result) =>{
             responseData=result;
           })
           
        .catch((error) => {
            console.log('error', error);
        });
        dispatch({ type: TRADE_ALL_ACTIVE_CLOSE_SUCCESS, payload: responseData})
    }
        

export const closeTradefromActiveTab = (lp, brokrage, broughtBy, closedAt, orderNo, id, type) => async (dispatch) => {
    debugger
    try {
        dispatch({ type: TRADE_ACTIVE_CLOSE_REQUEST })

        const config = { headers: { "Content-Type": "application/json" } };

        const { data } = await axios.get(`http://services.tradewingss.com/api/tradingclient/updateorder/?lp=${lp}&brokerage=${brokrage}&BroughtBy=${broughtBy}&ClosedAt=${closedAt}&orderno=${orderNo}&uid=${id}&ordertype=${type}`, config);

        const parsedData = JSON.parse(data);

        dispatch({ type: TRADE_ACTIVE_CLOSE_SUCCESS, payload: parsedData });
    } catch (error) {
        dispatch({ type: TRADE_ACTIVE_CLOSE_ERROR, payload: error });
    }
};

