import React from 'react';
import { Modal } from "antd";

const InsufficientMarginModal   = ({ onClose,insufficientMarginModalOpen }) =>  {

    return (
        <Modal
            width={450}
            maskClosable={false}
            centered={true}
            closeIcon={false}
            closable={false}
            footer={false}
            open={insufficientMarginModalOpen}
            className='modal  z-50'
            title={false}
        >
            <div className='pt-[15px] pl-[15px] pr-[15px] pb-[15px]'>
            <div className='text-[#707E89] text-[20px] font-[500] text-center'>Error</div>
            <div className='text-[#707E89] text-[15px] font-[500] text-center'>Sorry you cant place the order !Due To Insufficient Margin</div>
                
                <div className='pr-[10px] pl-[10px] flex justify-center pt-[10px]'>
                <button onClick={onClose}
                        className='cursor-pointer text-[#fff] text-[20px] rounded-[5px] bg-[#1D3557] w-[50px] h-[40px] flex items-center justify-center text-center'
                        >OK</button>
                </div>
            </div>
        </Modal>
    )
}

export default InsufficientMarginModal
