import React from 'react';
import { Modal } from "antd";
import { useSelector } from 'react-redux';

const FundsModal = ({ fundsModalState, fundsModalCloseFun }) => {
    
    // getting data from api using redux
    const { ledgerBalance, userLedgerBalanceData } = useSelector((state) => state.store);

    // modal for funds, that is ledger balance, for more information read antd modal documentation
    return (
        <Modal
            width={521}
            maskClosable={false}
            centered={true}
            closeIcon={false}
            closable={false}
            footer={false}
            open={fundsModalState}
            className='modal'
            title={
                <div className='flex justify-between items-center pt-[18px] pr-[20px] pb-[16px] pl-[20px]'>
                    <span className='text-[#011C3A] text-[20px] font-[500]'>Transaction History</span>
                    <span
                        onClick={() => fundsModalCloseFun(false)}
                        className='cursor-pointer text-[#707E89] text-[20px]'
                    >✕</span>
                </div>
            }
        >
            <div className='pt-[10px] pl-[25px] pb-[10px]'>
                <div className='text-[#707E89] text-[20px] font-[500] mb-[10px]'>Current Balance</div>
                <div className='text-[#119832] text-[24px] font-[500]'>{parseInt(ledgerBalance)}</div>
            </div>
            {
                userLedgerBalanceData &&
                userLedgerBalanceData.length > 0 &&
                userLedgerBalanceData.map((item) => {
                    return (
                        <div key={item.CreatedDate} className='pt-[10px] pl-[25px] pr-[25px] pb-[15px] flex justify-between items-center'>
                            <span className='text-[14px] font-[500]'>{item.CreatedDate}</span>
                            <span className='text-[14px] font-[500]'>{item.TransactionType}</span>
                            <span className='text-[14px] font-[500] bg-[#c8e6c9] px-[4px] py-[3px]'>{parseInt(item.Amount)}</span>
                        </div>
                    )
                })
            }
        </Modal>
    )
}

export default FundsModal
