import React from 'react';
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { TRADE_ACTIVE_CLOSE_RESET } from '../../../redux/constants';
import { getTradeData } from '../../../redux/actions';

const TradeCloseFromAcitveTabModal = ({ activeTradeCloseModalOpen, closeActiveTradeModal, activeTradeCloseDataState, makeActiveTradeCloseDataState, user }) => {
    const dispatch = useDispatch();

    function splitforExchangeName(inputString) {
        const parts = inputString?.split('_');
        return parts[0];
    }


    return (
        <Modal
            width={350}
            maskClosable={false}
            centered={true}
            closeIcon={false}
            closable={false}
            footer={false}
            open={activeTradeCloseModalOpen}
            className='modal'
            title={false}
        >
            <div className='pt-[15px] pl-[15px] pr-[15px] pb-[15px]'>
                <div className='text-[#707E89] text-[20px] font-[500] text-center'>Trade Closed!</div>
                <div className='text-[#707E89] text-[15px] font-[500] text-center'>{activeTradeCloseDataState?.name && splitforExchangeName(activeTradeCloseDataState?.name)} trades of {activeTradeCloseDataState?.lot} lots has been closed successfully at P/L {activeTradeCloseDataState?.pl > 0 ? "+" + activeTradeCloseDataState?.pl : "-" + activeTradeCloseDataState?.pl}</div>
                <div className='pr-[10px] pl-[10px] flex justify-center pt-[10px]'>
                    <span
                        onClick={() => {
                            closeActiveTradeModal(false);
                            makeActiveTradeCloseDataState(null)
                            dispatch({ type: TRADE_ACTIVE_CLOSE_RESET, data: '' });
                            dispatch(getTradeData("Active", user?.userId));
                        }}
                        className='cursor-pointer text-[#fff] text-[20px] rounded-[5px] bg-[#1D3557] w-[50px] h-[40px] flex items-center justify-center text-center'
                    >OK</span>
                </div>
            </div>
        </Modal>
    )
}

export default TradeCloseFromAcitveTabModal
